import PropTypes from 'prop-types'
// @mui
import { styled, useTheme } from '@mui/material/styles'
import { Card, CardHeader } from '@mui/material'
// utils
import { fNumber } from '../../../utils/formatNumber'
// components
import Chart, { useChart } from '../../../components/chart'

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400

const LEGEND_HEIGHT = 72

const StyledChart = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    //position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}))

// ----------------------------------------------------------------------

EcommerceSaleByGender.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
  subheader: PropTypes.string,
}

export default function EcommerceSaleByGender({
  title,
  subheader,
  total,
  chart,
  ...other
}) {
  const theme = useTheme()

  const { colors, series, options } = chart

  const chartSeries = series.map(i => i.value)

  const chartColors = colors || [
    [theme.palette.info.light, theme.palette.info.main],
    [theme.palette.error.light, theme.palette.error.main],
  ]

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    labels: series.map(i => i.label),
    legend: {
      markers: {
        fillColors: chartColors.map(colr => colr[0]),
      },
      floating: true,
      horizontalAlign: 'center',
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: chartColors.map(colr => [
          { offset: 0, color: colr[0] },
          { offset: 100, color: colr[1] },
        ]),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '68%' },
        dataLabels: {
          value: { offsetY: 16 },
          total: {
            formatter: () => fNumber(total),
          },
        },
      },
    },
    ...options,
  })

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChart dir="ltr">
        <Chart
          type="radialBar"
          series={chartSeries}
          options={chartOptions}
          height={300}
        />
      </StyledChart>
    </Card>
  )
}
