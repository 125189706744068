import {
  Grid,
} from '@mui/material'
import style from './GridContainerStyle'

export default function GridContainer({
  children,
} : {
  children: React.ReactNode,
}) {

  return (
    <Grid container sx={style.container}>
      {children}
    </Grid>
  )
}
