import Chart from 'react-apexcharts'

export default function ColumnChart({
  chartHeight = 300,
  categories,
  data,
  showColumnLabels,
}: {
  chartHeight?: number
  categories: string[][] | undefined
  data: any
  showColumnLabels: boolean
}) {
  const options = {
    chart: {
      id: 'column-chart',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories || [],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      fontSize: 16,
      itemMargin: {
        horizontal: 12,
      },
      markers: {
        size: 5,
        shape: 'circle',
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: showColumnLabels,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: 'last',
        rangeBarGroupRows: true,
      },
    },
  }

  return (
    <>
      {/* @ts-ignore */}
      <Chart type="bar" options={options} series={data} height={chartHeight} />
    </>
  )
}
