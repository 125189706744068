import { Card, Stack, TextField } from "@mui/material"
import SecondaryButton from "../../../../components/button/SecondaryButton/SecondaryButton"
import PrimaryButton from "../../../../components/button/PrimaryButton/PrimaryButton"
import { useNavigate } from "react-router"
import { useLocales } from "../../../../locales"
import style from './TaxonomyFormStyle'
import TaxonomyTagModel from "../../../../services/taxonomies/model/TaxonomyTagModel"
import TagManager from "../../../../components/form/TagManager/TagManager"

export default function TaxonomyForm({
  form,
  submitButtonLabel
} : {
  form: any,
  submitButtonLabel: string
}): JSX.Element {
  const navigate = useNavigate()
  const { translate } = useLocales()

  const handleChangeTagsValue = (tag: string): void => {
    form.addTagValue(tag)
  }
  const handleRemoveTag = (tag: TaxonomyTagModel) => {
    form.removeTagValue(tag)
  }

  return (
    <Stack sx={style.container}>
      <Card sx={style.card}>
        <Stack sx={style.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            label={`${translate('create:taxonomy:form:name')}`}
            value={form.values.name}
            onChange={form.changeFieldValue}
            onBlur={form.validateFieldValue}
            error={form.errors.name !== ''}
            helperText={form.errors.name}
          />
          <TagManager
            tags={form.values?.tags}
            error={form.errors.tags}
            addTag={handleChangeTagsValue}
            removeTag={handleRemoveTag}
          />
        </Stack>
      </Card>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SecondaryButton
          onClick={() => navigate(-1)}
        >
          {`${translate('action:cancel')}`}
        </SecondaryButton>
        <PrimaryButton onClick={form.submit}>
          {submitButtonLabel}
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}
