import { Box, Card, Typography } from '@mui/material'
import style from './TotalVisitsGraphStyle'
import Spacer from '../Spacer/Spacer'
import ColumnChart from '../chart/ColumnChart/ColumnChart'
import InfoTooltip from '../infoTooltip/InfoTooltip'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import { useLocales } from '../../locales'
import { ZonesInterface } from '../../services/anonymous/interfaces/AnonymousKPIsInterface'
import { ZONES } from '../../utils/zones'
import { calculateIncrementPercentage } from '../../utils/mathHelper'
import AnonymousTraficZonesModel from '../../services/anonymous/model/AnonymousTraficZonesModel'
import { AnonymousTraficZonesVisitInterface } from '../../services/anonymous/interfaces/AnonymousTraficZonesInterface'
import DatePeriod from '../../services/common/enum/DatePeriod'
import { fDate, fDayOfWeek } from '../../utils/formatTime'
import AnonymousTrafficZonesModel from '../../services/anonymous/model/AnonymousTraficZonesModel'
import { useTheme } from '@emotion/react'

export default function TotalVisitsGraph({
  anonymousTrafficZonesData,
  detectionsSum,
  selectedPeriod,
}: {
  anonymousTrafficZonesData: AnonymousTraficZonesModel[]
  detectionsSum: ZonesInterface
  selectedPeriod: string
}) {
  const { translate } = useLocales()
  const title = `${translate('anonymous:kpis:chart:total:visits')}`
  const tooltipText = `${translate(
    'anonymous:kpis:chart:total:visits:tooltip',
  )}`
  const theme = useTheme() as any

  const SHOW_COLUMN_LABELS_IN_DAYS_THRESHOLD = 20

  const categories = anonymousTrafficZonesData[0]?.previousZoneVisits.map(
    (trafficZone: AnonymousTraficZonesVisitInterface) => {
      let mainLabel = trafficZone.date
      const isLabelTranslatable = mainLabel.match(/^[\d]+$/g) === null

      if (isLabelTranslatable) {
        mainLabel = `${translate(
          `date:calendar:${trafficZone.date.toLowerCase()}`,
        )}`
      }

      if (!isLabelTranslatable) {
        const dayWeek = `${translate(
          `date:calendar:initials:${fDayOfWeek(
            trafficZone.fullDate,
          ).toLocaleLowerCase()}`,
        )}`
        const date = trafficZone.date

        return [dayWeek, date]
      }

      if (selectedPeriod === DatePeriod.CURRENT_YEAR) {
        return [mainLabel]
      }

      const date = fDate(new Date(trafficZone.fullDate ?? null))
      return [mainLabel, date]
    },
  )

  const lightColors = [
    theme.palette.chart.light.cashRegisters,
    theme.palette.chart.light.firstZone,
    theme.palette.chart.light.secondZone,
  ]
  const darkColors = [
    theme.palette.chart.dark.cashRegisters,
    theme.palette.chart.dark.firstZone,
    theme.palette.chart.dark.secondZone,
  ]

  const previousTrafficZones = anonymousTrafficZonesData
    .map((trafficZone: AnonymousTrafficZonesModel) => {
      return {
        name: `${trafficZone.zoneName} prev`,
        group: 'previous',
        color: darkColors[trafficZone.zoneIndex],
        zoneIndex: trafficZone.zoneIndex,
        data: trafficZone.previousZoneVisits.map(
          (previousData: AnonymousTraficZonesVisitInterface) =>
            previousData.count,
        ),
      }
    })
    .sort(
      (currentZoneIndex, comparisonZoneIndex) =>
        currentZoneIndex.zoneIndex - comparisonZoneIndex.zoneIndex,
    )

  const currentTrafficZones = anonymousTrafficZonesData
    .map((trafficZone: AnonymousTrafficZonesModel) => {
      return {
        name: trafficZone.zoneName,
        group: 'current',
        color: lightColors[trafficZone.zoneIndex],
        zoneIndex: trafficZone.zoneIndex,
        data: trafficZone.currentZoneVisits.map(
          (previousData: AnonymousTraficZonesVisitInterface) =>
            previousData.count,
        ),
      }
    })
    .sort(
      (currentZoneIndex, comparisonZoneIndex) =>
        currentZoneIndex.zoneIndex - comparisonZoneIndex.zoneIndex,
    )

  const trafficZonesData = previousTrafficZones.concat(currentTrafficZones)

  if (trafficZonesData.length === 0 || !trafficZonesData || !detectionsSum) {
    return <EmptyCard title={title} height={500} tooltip={tooltipText} />
  }

  const showColumnLabels =
    anonymousTrafficZonesData[0]?.previousZoneVisits.length <
    SHOW_COLUMN_LABELS_IN_DAYS_THRESHOLD

  const ZONE_CHECKOUT = detectionsSum[ZONES.ZONE_CHECKOUT.value]
  const ZONE_2 = detectionsSum[ZONES.ZONE_2.value]
  const ZONE_3 = detectionsSum[ZONES.ZONE_3.value]

  const CURRENT_TOTAL =
    (ZONE_CHECKOUT?.current || 0) +
    (ZONE_2?.current || 0) +
    (ZONE_3?.current || 0)
  const PREVIOUS_TOTAL =
    (ZONE_CHECKOUT?.previous || 0) +
    (ZONE_2?.previous || 0) +
    (ZONE_3?.previous || 0)

  return (
    <Card sx={style.container}>
      <Box sx={style.titleContainer}>
        <Typography variant="h5">{title}</Typography>
        <InfoTooltip info={tooltipText} />
      </Box>
      <Box>
        <Typography color={'#828d99'} variant="subtitle2">
          {`(${calculateIncrementPercentage(CURRENT_TOTAL, PREVIOUS_TOTAL)})`}
          {` ${translate(
            `anonymous:kpis:chart:total:visits:variation:${selectedPeriod.toLocaleLowerCase()}`,
          )}`}
        </Typography>
      </Box>
      <Spacer size={3} />
      <ColumnChart
        data={trafficZonesData}
        categories={categories}
        showColumnLabels={showColumnLabels}
      />
    </Card>
  )
}
