import { AnonymousTraficZonesInterface, AnonymousTraficZonesVisitInterface } from "../interfaces/AnonymousTraficZonesInterface"

export default class AnonymousTraficZonesModel implements AnonymousTraficZonesInterface {
  constructor(
    readonly zoneIndex: number,
    readonly zoneName: string,
    readonly currentZoneVisits: AnonymousTraficZonesVisitInterface[],
    readonly previousZoneVisits: AnonymousTraficZonesVisitInterface[],
  ) {}
}
