import Skeleton from "../Skeleton";

export default function CardSkeleton() {
  const card =     {
    height: 245,
    xs: 12,
    sm: 6,
    md: 4,
  }

  const DATA = [
    card,
    card,
  ]

  return (
    <Skeleton loadingData={DATA} />
  )
}
