import { Box } from '@mui/material'
import { useSettingsContext } from '../../settings'
import useResponsive from '../../../hooks/useResponsive'
import MainContainerStyle from './MainContainerStyle'

export default function MainContainer({
  children,
} : {
  children: React.ReactNode,
}): JSX.Element {
  const { themeLayout } = useSettingsContext()
  const isNavMini = themeLayout === 'mini'
  const isDesktop = useResponsive('up', 'lg')
  const style = MainContainerStyle(isNavMini, isDesktop)

  return (
    <Box
      component="main"
      sx={style.container}
    >
      {children}
    </Box>
  )
}
