import { useState } from 'react'
import { useNavigate } from 'react-router'
import { PATH_ACCOUNTS } from '../../routes/paths'
import { createTagGroup, updateTagGroup } from './tagGroupService'
import { adaptTagGroupFormValuesToCreatePayload, adaptTagGroupFormValuesToUpdatePayload } from './tagGroupAdapter'

function useTagFormMutation(): object {
  const navigate = useNavigate()

  const [state, setState] = useState({
    success: false,
    loading: false,
    error: null,
  })

  const create =(organizationUUID: string) => async (formValues: any): Promise<void> => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {
      await createTagGroup(organizationUUID, adaptTagGroupFormValuesToCreatePayload(formValues))

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.tagGroups(organizationUUID)}?created=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const update = (organizationUUID: string, tagGroupUUID: string) => async (formValues: any): Promise<void> => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {
      await updateTagGroup(
        organizationUUID,
        tagGroupUUID,
        adaptTagGroupFormValuesToUpdatePayload(formValues),
      )

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.tagGroups(organizationUUID)}?updated=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const clearError = (): void => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    create,
    update,
    clearError,
  }
}

export default useTagFormMutation
