import { useEffect, useState, useCallback } from 'react'

import { fetchOrganization } from './service'

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: null,
}

function useOrganization(organizationId: string): object {
  const [state, setState] = useState(INITIAL_STATE)

  const fetchData = useCallback(async () => {
    if (organizationId === undefined) {
      setState(INITIAL_STATE)
      return
    }

    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchOrganization(organizationId)

      setState({
        data: response.data,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    refetch: fetchData,
  }
}

export default useOrganization
