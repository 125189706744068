const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularized in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  avatar: {
    home: `Home`,
    logout: `Logout`,
    profile: `Profile`,
    settings: `Settings`,
  },
  'list-dashboards': 'Lits accounts by users',
  accounts: `accounts`,
  app: `app`,
  clients: `Organizations`,
  'table:rows-per-page': 'Rows per page:',
  users: `users`,
  'users:create': 'New user',
  'users:edit': 'Edit user',
  'users:table:no-users': 'There are no users',
  'users:table:heading:name': 'Name',
  'users:table:heading:role': 'Role',
  'users:table:heading:organization': 'Organization',
  'users:table:heading:permissions': 'Permissions',
  'users:delete:title': 'Are you sure you want to delete this user?',
  'users:delete:text': 'The user you selected will be permanently deleted',
  'users:permissions-updated':
    'The user permissions have been updated successfully',
  'users:created': 'The user has been created successfully',
  'users:updated': 'The user has been updated successfully',
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  campaigns: 'campaigns',
  stores: 'Stores',
  delete: 'Delete',
  'locations:title': 'Locations',
  'campaigns:status:live': 'Live',
  'campaigns:status:paused': 'Paused',
  'campaigns:status:programmed': 'Programmed',
  'campaigns:status:completed': 'Completed',
  'campaigns:table:name': 'Name',
  'campaigns:table:description': 'Description',
  'campaigns:table:start-date': 'Start date',
  'campaigns:table:end-date': 'End date',
  'campaigns:table:registered-users': 'Registered Users',
  'campaigns:table:status': 'Status',
  'campaigns:successful-creation': 'The campaign has been created successfully',
  'campaign:status:paused': 'Paused',
  'campaign:status:live': 'Active',
  'campaign:status:programmed': 'Scheduled',
  'campaign:status:completed': 'Completed',
  'campaign:pause': 'Pause campaign',
  'campaign:activate': 'Activate campaign',
  'campaigns:title': 'Captation campaigns',
  'campaigns:create-button': 'New Campaign',
  'campaigns:delete:title': 'Delete Campaign',
  'campaigns:delete:text': 'Are you sure want to delete this campaign?',
  'campaigns:created': 'The campaign has been created successfully',
  'campaigns:updated': 'The campaign has been updated successfully',
  'campaigns:ended': "The campaign couldn't be updated because ended",
  'campaigns:create:caption':
    'Only one campaign can be scheduled for the same dates.',
  'edit-campaign:back-to-campaigns': 'Back to campaigns',
  'edit-campaign:save-changes': 'Save changes',
  'edit-campaign:title': 'Edit campaign',
  'new-campaign:title': 'Create Campaign',
  'new-campaign:confirm': 'Create Campaign',
  'new-campaign:details-title': 'Campaign Details',
  'new-campaign:details-description': 'Essential campaign properties',
  'new-campaign:content-title': 'Campaign Content',
  'new-campaign:content-description': 'Message composition',
  'new-campaign:form-name-label': 'Campaign name',
  'new-campaign:form-description-label': 'Campaign description',
  'new-campaign:form-start-label': 'Start date',
  'new-campaign:form-end-label': 'Finish date',
  'new-campaign:form-type-label': 'Campaign Type',
  'new-campaign:form-channel-label': 'Campaign Channel',
  'new-campaign:form-text-label': 'Message',
  'new-campaign:form-text-placeholder': 'Write something awesome...',
  'new-campaign:form-image-label': 'Image',
  'new-campaign:form-image-drop-text': 'Drag and drop here or',
  'new-campaign:form-image-drop-button': 'choose image',
  'new-campaign:form-image-drop-restrictions':
    'Format supported: JPEG, PNG. Maximum size: 5MB',
  'new-campaign:form-error-missing-image': 'Please, upload an image',
  'new-campaign:form-error-image-failed':
    'Image upload failed. Please ensure the format and size are correct.',
  'new-campaign:form-error-invalid-past-date':
    'It is not possible to assign a past date',
  'new-campaign:form-error-invalid-date-range': 'Invalid date range',
  'new-campaign:form-error-name-too-long':
    'The name must not exceed 50 characters',
  'new-campaign:form-error-description-too-long':
    'The description must not exceed 50 characters',
  'new-campaign:error-dates-already-active':
    'Dates already in use for a scheduled campaign. Please select different ones',
  'new-campaign:submitting-form-info-message': 'Creating campaign...',
  'new-campaign:submit-error-message':
    'Campaign creation failed. Try again later.',
  'campaign-type:registration': 'Registration Campaign',
  'new-user:title': 'Create user',
  'new-user:submitting-form-info-message': 'Creating user...',
  'new-user:form:organization-label': 'Organization',
  'new-user:form:organization-label:loading': 'Loading organizations...',
  'new-user:form:name-label': 'Name',
  'new-user:form:lastname-label': 'Surname',
  'new-user:form:email-label': 'Email address',
  'new-user:form:password-label': 'Password',
  'new-user:form:password-helper':
    'Make sure you write down this password before continuing',
  'new-user:form:role-label': 'Role',
  'new-user:form:submit-label': 'Create user',
  'new-user:form:error-missing-field':
    'Please, provide the required information',
  'new-user:form:permission:anonymous-module': 'Anonymous module',
  'new-user:form:permission:captation-permission': 'Captation campaigns',
  'new-user:form:permission:registered-module': 'Registered module',
  'new-user:groups-label': 'Groups',
  'edit-user:title': 'Edit user',
  'edit-user:loading': 'Loading data...',
  'edit-user:updating': 'Updating user...',
  'edit-user:form:organization-label': 'Organization',
  'edit-user:form:organization-label:loading': 'Loading organizations...',
  'edit-user:form:error-missing-field':
    'Please, provide the required information',
  'edit-user:form:error-stores-not-informed':
    'Please, make sure that all the brands has at least one store assigned',
  'edit-user:form:error-invalid-email': 'Please, enter a valid email',
  'edit-user:form:error-invalid-permissions':
    'You must select at least one permission',
  'edit-user:form:submit-label': 'Save changes',
  'campaign-channel:whatsapp': 'WhatsApp',
  'action:apply': 'Apply',
  'action:cancel': 'Cancel',
  'action:delete': 'Delete',
  'action:edit': 'Edit',
  'action:view': 'View',
  'analytics:title': 'Analytics',
  'in-store:granularity': 'Users splitted by {{granularity}}',
  granularity: {
    day: 'day',
    week: 'week',
    month: 'month',
    quarter: 'semester',
    year: 'year',
  },
  'login:title': 'Sign in to Summer of 69',
  'login:form:forgot-password': 'Forgot your password?',
  'login:form:submit-label': 'Login',
  'login:form:email:label': 'Email',
  'login:form:email:error:required': 'Email is required',
  'login:form:email:error:invalid': 'Email must be a valid email address',
  'login:form:password:label': 'Password',
  'login:form:password:error:required': 'Password is required',
  'reset-password:page:title': 'Reset password',
  'reset-password:title': 'Forgot your password?',
  'reset-password:description':
    "Please enter the email address associated with your account. We'll send you an email with instructions to reset your password.",
  'reset-password:input:label': 'Email address',
  'reset-password:confirmation:title': 'Please check your email',
  'reset-password:confirmation:description':
    "We have sent an email to {{email}} with instructions to reset your password. If you don't see the email in your inbox, please check your spam folder",
  'reset-password:action:send': 'Send request',
  'reset-password:action:return': 'Return to Login',
  'reset-password:error:email-required': 'Email is required',
  'confirm-password:page:title': 'Confirm password',
  'confirm-password:title': 'Set your new password',
  'confirm-password:description':
    'We are almost there! Create your new password with a minimum of 8 characters',
  'confirm-password:input:label': 'New password',
  'confirm-password:confirmed-input:label': 'Enter your password again',
  'confirm-password:confirmation:title': 'Your new password is ready',
  'confirm-password:confirmation:description':
    'Your password has been successfully reset. Please click below to continue your access',
  'confirm-password:action:send': 'Reset Password',
  'confirm-password:action:return': 'Return to Login',
  'navigation-welcome': 'Welcome back, {{user}} ',
  'navigation-button': 'Backoffice',
  'navigation-button-brands': 'Brands',
  'confirm-password:error:password-required': 'New password is required',
  'confirm-password:error:password-length':
    'Your password must contain at least 8 characters',
  'confirm-password:error:confirmed-password-required':
    'Confirmed password is required',
  'confirm-password:error:password-match': 'Passwords must match',
  'account-permissions:page:title': 'Permissions',
  'account-permissions': 'Account permissions',
  'permission-analytics.read': 'View Analytics section',
  'permission-connect.read': 'View Connect section',
  'permission-stores.read': 'View Stores section',
  'permission-registers.read': 'View Registers section',
  'permission-campaign.read': 'View Campaigns section',
  'permission-realtime.read': 'View Realtime section',
  'permission-application.read-economic-data': 'View economic data',
  'permission-campaign.write': 'Campaign management',
  'new-organization:title': 'Create organization',
  'new-organization:title:edit': 'Edit organization',
  'new-organization:details-title': 'Organization Details',
  'new-organization:details-description': 'Information about the organization.',
  'new-organization:submitting-form-info-message': 'Creating organization...',
  'new-organization:submit-error-message':
    'Organization creation failed. Try again later.',
  'new-organization:form:name-label': 'Name',
  'new-organization:form:description-label': 'Description',
  'new-organization:form:industry-label': 'Industry',
  'new-organization:content-title': 'Organization Contact',
  'new-organization:content-description':
    'Contact person for the organization.',
  'new-organization:contact-form:name-label': 'Name',
  'new-organization:contact-form:surname-label': 'Surname',
  'new-organization:contact-form:prefix-label': 'Prefix',
  'new-organization:contact-form:phone-label': 'Phone number',
  'new-organization:contact-form:email-label': 'Email',
  'new-organization:submit-label': 'Create Organization',
  'new-organization:submit-label:edit': 'Save changes',
  'organizations:create': 'New organization',
  'organizations:created': 'The organization has been created successfully',
  'organizations:updated': 'The organization has been updated successfully',
  'organizations:empty-content:title': 'No organizations yet',
  'organizations:empty-content:description':
    'Get started by creating an organization. All of the organizations will be displayed on this page.',
  'edit-organization:submitting-form-info-message': 'Updating organization...',
  'edit-organization:submit-error-message':
    'Organization update failed. Try again later.',
  'new-brand:form:error-missing-field':
    'Please, provide the required information',
  'edit-brand:successful-creation': 'The brand has been updated successfully',
  'brands:empty-content:title': 'No brands yet',
  'brands:empty-content:description':
    'Get started by creating a brand for this organization. All of the brands will be displayed on this page.',
  'brands:create': 'New brand',
  'brands:created': 'The brand has been created successfully',
  'brands:updated': 'The brand has been updated successfully',
  //Locations
  'new-location-form:title': 'Create location',
  'new-location:title:edit': 'Edit location',
  'new-location:form:error-missing-field':
    'Please, provide the required information',
  'new-location:submitting-form-info-message': 'Creating location...',
  'new-location:submit-error-message':
    'Location creation failed. Try again later.',
  'new-location:form:address-label': 'Address',
  'new-store:form:postal-code-label': 'Postal code',
  'new-location:form:contact-name-label': 'Contact name',
  'new-location:form:contact-last-name-label': 'Contact lastname',
  'new-location:form:contact-phone-prefix-label': 'Prefix',
  'new-location:form:contact-phone-number-label': 'Phone number',
  'new-location:form:contact-email-label': 'Email',
  'new-location:content-title': 'Location',
  'new-location:content-description': 'Location information',
  'new-location:timezone-label': 'Timezone',
  'new-location:name-label': 'Location name',
  'new-location:tag-group-title': 'Taxonomies',
  'new-location:tag-group-description': 'Assign a taxonomy to this location',
  'edit-location:submit-error-message':
    'Location edition failed. Try again later.',
  'edit-location:taxonomy-not-exist-message': 'Taxonomy not exist.',
  'locations:create': 'New Location',
  'locations:created': 'The location has been created successfully',
  'locations:updated': 'The location has been updated successfully',
  'no-locations:title': 'No locations yet',
  'no-location:description':
    'Get started by creating a location for this organization. All of the locations will be displayed on this page.',
  'location:remove:success': 'Location removed.',
  'location:remove:error': 'Error removing location.',
  'location:remove:confirm': 'Will be permanently deleted',
  'location:remove:confirm:title':
    'Are you sure you want to delete this location?',
  'location:taxonomies:empty':
    'There are no taxonomies, you must create at least one in order to create a location.',
  'form-label': 'Save changes',
  'client:remove:success': 'Organization removed.',
  'client:remove:error': 'Error removing organization.',
  'client:remove:confirm':
    'All associated brands, stores, campaigns, users and events will also be permanently deleted',
  'client:remove:confirm:title':
    'Are you sure you want to delete this organization?',
  'brand:remove:success': 'Brand removed.',
  'brand:remove:error': 'Error removing brand.',
  'brand:remove:confirm':
    'All associated stores, campaigns, users and events will also be permanently deleted',
  'brand:remove:confirm:title': 'Are you sure you want to delete this brand?',
  'nav:accounts:subheader': 'admin',
  'nav:accounts:organizations': 'organizations',
  'nav:accounts:users': 'users',
  'nav:accounts:nodes': 'nodes',
  // nav dashboard
  'nav:dashboard:subheader': 'dashboard',
  'nav:dashboard:subheader:reports': 'reports',
  'nav:dashboard:brands': 'brands',
  'nav:dashboard:locations': 'locations',
  'nav:dashboard:users': 'users',
  'nav:dashboard:anonymous': 'Anonymous flux',
  'nav:dashboard:registered': 'Registered module',
  'nav:dashboard:analytics': 'analytics',
  'nav:dashboard:connect': 'connect',
  'nav:dashboard:registers': 'registers',
  'nav:dashboard:campaign': 'campaign',
  'nav:dashboard:campaign:engagement': 'captation campaigns',
  'nav:dashboard:realtime': 'realtime',
  'kpis:out-door': 'Out-Door',
  'kpis:in-store': 'In-Store',
  'kpis:checkout': 'At Checkout',
  'kpis:sell-out': 'Sell-Out',
  'graph:in-store-traffic': 'In-Store Traffic',
  'graph:top-stores-traffic': 'Top Stores By Traffic',
  'graph:users-visits-in-store-traffic': 'Users and Visits In-Store Traffic',
  'graph:from-last-year': 'from last year',
  'graph:from-last-period': 'than last period',
  'graph:users': 'Users',
  'graph:visits': 'Visits',
  'graph:frequency': 'Frequency',
  'graph:frequency:one-visit': 'One Visit',
  'graph:frequency:two-visits': 'Two Visits',
  'graph:frequency:more-than-three-visits': '+Three Visits',
  'graph:visits-by-gender': 'Visits by gender',
  'graph:visits-by-age': 'Visits By Age',
  'graph:visits-by-age-over-65': 'Over 65',
  'graph:visits-by-age-55-64': 'From 55-64',
  'graph:visits-by-age-45-54': 'From 45-54',
  'graph:visits-by-age-30-44': 'From 30-44',
  'graph:visits-by-age-18-29': 'From 18-29',
  'graph:visits-by-age-0-179': 'From 0-17',
  'users:registered': 'Registered',
  'users:registered-users': 'Registered Users',
  'users:new-users': 'New Users',
  'users:recurrent': 'Recurrent',
  'users:men': 'Men',
  'users:women': 'Women',
  'dates-bar:select-date': 'Select date',
  'dates-bar:YESTERDAY': 'Yesterday',
  'dates-bar:LAST_7_DAYS': 'Last 7 days',
  'dates-bar:week': 'Current week',
  'dates-bar:CURRENT_MONTH': 'Current month',
  'dates-bar:CURRENT_YEAR': 'Current year',
  'connect:title': 'Connect',
  'connect:percentages': '% Of Users Registered',
  'connect:sales-to-registered': 'Sales To Users Registered',
  'connect:visits-needed-to-register': 'Visits Needed To Register',
  'connect:accumulated-registrations': 'Accumulated Registrations',
  'connect:campaign-performance': 'Campaigns Performance:',
  'connect:number-people-store': 'Number Of People By Store:',
  'connect:number-people-store-opportunity': 'Opportunity',
  'connect:registered-by-minutes': 'Registered By Minutes',
  '404:title:page': '404 Page Not Found',
  '404:title': 'Sorry, page not found!',
  '404:description':
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  '404:go-home': 'Go to home',
  '403:title:page': '403 Permission Denied',
  '403:title': 'Permission Denied',
  '403:description': 'You do not have permission to access this page',
  '403:go-home': 'Go to Home',
  'tabs:all': 'All',
  'tabs:detections': 'Detections',
  'tabs:registers': 'Registers',
  'tabs:sales': 'Sales',
  'tabs:revenue': 'Revenue',
  'store:zones': 'Store Zones',
  'store:table:kpi': 'KPI',
  'store:table:zone': 'Zone',
  'store:table:new-users': 'New Users',
  'store:table:recurrent-users': 'Recurrent users',
  'store:table:registered-users': 'Registered users',
  'store:table:no-stats': 'There are no stats',
  'locations:table:name': 'Name',
  'locations:table:address': 'Address',
  'registers:title:page': 'Registers',
  'registers:table:user': 'User',
  'registers:table:event': 'Event',
  'registers:table:store-name': 'Store name',
  'registers:table:date-time': 'Date and Time',
  'registers:table:store-id': 'Store ID',
  'registers:table:account-id': 'Account ID',
  'registers:table:campaign-name': 'Campaign Name',
  'registers:table:no-data': 'There are no events',
  'realtime:title:page': 'Realtime',
  'realtime:table:user': 'User',
  'realtime:table:event': 'Event',
  'realtime:table:store': 'Store',
  'realtime:table:date-time': 'Date and Time',
  'realtime:table:zone': 'Zone',
  'realtime:table:revenue': 'Revenue',
  'realtime:table:campaign': 'Campaign',
  'realtime:no-data': 'No data',
  'contact-info': 'Información de contacto',
  'date-picker:title': 'Select date range',
  'date-picker:from': 'From',
  'date-picker:to': 'To',
  'date-picker:error:start-date': 'Please select first an starting date',
  'date-picker:error:end-date': 'End date must be later than start date',
  'date-picker:error:start-date-today':
    "Start date can't be greater than today",
  'date-picker:error:end-date-today': "End date can't be greater than today",
  // Common
  'common:form:error-missing-field': 'Please, provide the required information',
  'common:error-invalid-email': 'Please, enter a valid email',
  'common:error-invalid-phone': 'Please, enter a valid phone',
  loading: 'Loading...',
  saving: 'Saving...',
  updating: 'Updating...',
  'common:button:accept': 'Accept',
  'common:button:cancel': 'Cancel',
  'common:no:data': 'not available',
  // Taxonomy
  'nav:accounts:taxonomies': 'Taxonomies',
  'view:taxonomies:title': 'Taxonomies',
  'view:taxonomies:add:button': 'New taxonomy',
  'create:taxonomy:title': 'Create taxonomy',
  'create:taxonomy:button': 'Create taxonomy',
  'create:taxonomy:form:name': 'Name',
  'create:taxonomy:form:tag': 'Tag',
  'create:taxonomy:add:tag:button': 'Add tag',
  'create:taxonomy:error:generic': 'Taxonomy creation failed. Try again later.',
  'create:taxonomy:error:already:exist':
    'Duplicity error. The taxonomy already exist.',
  'edit:taxonomy:title': 'Edit taxonomy',
  'edit:taxonomy:button': 'Save changes',
  'taxonomy:created': 'The taxonomy has been created successfully',
  'taxonomy:updated': 'The taxonomy has been updated successfully',
  'taxonomies:empty-content:title': 'No taxonomies yet',
  'taxonomies:empty-content:description':
    'Get started by creating a taxonomy. All of the taxonomies will be displayed on this page.',
  'taxonomy:remove:success': 'Taxonomy removed.',
  'taxonomy:remove:error': 'Error removing taxonomy.',
  'taxonomy:remove:confirm:title':
    'Are you sure you want to delete this taxonomy?',
  // Tag Group
  'nav:accounts:tag:group': 'Group',
  'view:tag:group:title': 'Groups',
  'view:tag:group:add:button': 'New group',
  'create:tag:group:title': 'Create group',
  'create:tag:group:button': 'Create group',
  'create:tag:group:error:generic': 'Group creation failed. Try again later.',
  'create:tag:group:error:already:exist':
    'Duplicity error. The group already exist.',
  'edit:tag:group:title': 'Edit group',
  'edit:tag:group:button': 'Save group',
  'tag:group:created': 'The group has been created successfully',
  'tag:group:updated': 'The group has been updated successfully',
  'tag:group:empty-content:title': 'No groups yet',
  'tag:group:empty-content:description':
    'Get started by creating a group. All of the groups will be displayed on this page.',
  'tag:group:remove:success': 'Group removed.',
  'tag:group:remove:error': 'Error removing group.',
  'tag:group:remove:confirm:title':
    'Are you sure you want to delete this group?',
  'tag:group:empty':
    'There are no taxonomies, you must create at least one in order to create a grouping.',
  //Nodes
  'nodes:title': 'Nodes',
  'nodes:empty-content:title': 'No nodes yet',
  'nodes:empty-content:description':
    'When a node becomes available, it will appear here',
  'nodes:table:organization': 'Organization',
  'nodes:table:location': 'Location',
  'nodes:table:zone': 'Zone',
  'nodes:table:status': 'Status',
  'create:node:title': 'Create nodo',
  'create:node:button': 'Create nodo',
  'create:node:form:description': 'Description',
  'create:node:form:organization': 'Organization',
  'create:node:form:location': 'Location',
  'create:node:form:zone': 'Zone',
  'create:node:form:description:not:pos:error':
    'The description is required if the zone corresponds to the point of sale.',
  'create:node:form:empty:locations:error':
    'The selected organization does not have assigned locations.',
  'create:node:form:empty:zones:error':
    'The selected organization does not have assigned zones.',
  'Missing or incorrect required params: organizationId':
    'Param organizationId incorrect',
  'Missing or incorrect required params: locationId':
    'Param locationId incorrect',
  'Missing or incorrect required params: zoneId': 'Param zoneId incorrect',
  'create:node:error:generic': 'Node creation failed. Try again later.',
  'nodes:no:data': 'currently there are no nodes.',
  'edit:node:title': 'Edit node',
  'edit:node:button': 'Save changes',
  'edit:node:error:generic': 'Node update failed. Try again later.',
  // Organization
  'new:organization:zones:title': 'Zones',
  'new:organization:zones:description': 'The first zone is the point of sale',
  'new:organization:zones:form:label': 'Zone',
  'new:organization:zones:form:point:of:sales': 'Zona point of sales',
  'empty:organization:selector': 'No organization',
  'new:organization:modules:title': 'Modules',
  'new:organization:modules:description':
    'Choose the modules that the organization will have available',
  'new:organization:logo:title': 'Logo',
  'new:organization:logo:description': "Choose the organization's logo",
  'new:organization:logo:empty': 'Select a logo for the organization',
  'new:organization:logo:button': 'Upload a logo',
  'organization:fetch:generic:error':
    'There was an error in organization reques.',
  'organization:unsuscribe:generic:error':
    'There was an error in organization unsuscription.',
  // Roles
  'role.SUPER_ADMIN': 'Super admin',
  'role.ORGANIZATION_ADMIN': 'Organization admin',
  'role.BASE': 'Employee',
  // User
  'create:user:error': 'User creation failed. Try again later.',
  'edit:user:error': 'User creation failed. Please try again.',
  'edit:user:invalid:role:with:assigned:organization:error':
    'Cannot assign an organization to a Super Administrator user.',
  // Permissions
  'permission:PERM_ANONYMOUS': 'Enable anonymous module',
  'permission:PERM_REGISTERED': 'Enable registered module',
  // Anonymous
  'anonymous:kpis:no:data': 'There is no available data',
  'anonymous:kpis:location:filter': 'Location',
  'anonymous:kpis:location:filter:title': 'Locations filter',
  'anonymous:kpis:location:selector': 'Location',
  'anonymous:kpis:location:selector:empty:result':
    'There is not locations selected',
  'anonymous:kpis:location:selector:empty:tag': 'There is not tags selected',
  'anonymous:kpis:label:detections_sum': 'Sum of visitors in',
  'anonymous:kpis:label:detections_average': 'Average of visitors in',
  'anonymous:kpis:label:average_time_stay': 'Average time stay in',
  'anonymous:kpis:label:new_vs_loyal': 'Rate of loyals to',
  'anonymous:kpis:label:average_time_comeback': 'Average time for comeback to',
  'anonymous:kpis:zone:zone_checkout': 'Checkout',
  'anonymous:kpis:zone:zone_2': 'Zone 2',
  'anonymous:kpis:zone:zone_3': 'Zone 3',
  'anonymous:empty:filter:title': 'There are no filter criteria selected.',
  'anonymous:empty:filter:text':
    'Select a location or date range to see results.',
  'anonymous:request:generic:error':
    'There was an error requesting the data. Please try again late.',
  'anonymous:ratio-attraction:graph:title': 'Attraction ratio',
  'anonymous:ratio-attraction:graph:tooltip':
    'Number of devices detected in the vicinity of the location, for a time longer than the indicated time ratio (+2 minutes), and which have subsequently been visitors to the location.',
  'anonymous:ratio-attraction:graph:label': 'Exposure +2 Minutes',
  'anonymous:kpis:chart:total:visits': 'Total Visits',
  'anonymous:kpis:chart:total:visits:tooltip':
    'Temporal distribution of all visitors in the different areas, for the selected locations and dates.',
  'anonymous:kpis:chart:total:visits:variation:yesterday': 'versus yesterday',
  'anonymous:kpis:chart:total:visits:variation:last_7_days': 'versus last week',
  'anonymous:kpis:chart:total:visits:variation:current_month':
    'versus last month',
  'anonymous:kpis:chart:total:visits:variation:current_year':
    'versus last year',
  'anonymous:kpis:chart:frequency:title': 'Total Visits Frequency',
  'anonymous:kpis:chart:frequency:tooltip':
    'Total visitors, distributed by number of visits made, for the selected locations and dates.',
  'anonymous:kpis:chart:frequency:one-visit': 'One visit',
  'anonymous:kpis:chart:frequency:two-visits': 'Two visits',
  'anonymous:kpis:chart:frequency:three-or-more-visits': 'Three or more visits',
  'anonymous:kpis:chart:total:detection:flow': 'Total visitors flow',
  'anonymous:kpis:chart:total:detection:flow:tooltip':
    'Funnel with the flow of visitors in the different areas of the locations, for selected dates.',
  'anonymous:heatmap:detections:label': 'Visitors Heatmap',
  'anonymous:heatmap:detections:tooltip':
    'Distribution in the form of a heat map, reflecting the number of visitors per day and time, in the selected locations and dates.',
  'anonymous:kpis:chart:average-visits-comeback': 'Average Time Between Visits',
  'anonymous:kpis:chart:average-visits-comeback:tooltip':
    'Average time in days between visits from the same device, for the selected locations and dates.',
  'anonymous:kpis:chart:average-visits-comeback:yAxisDays': 'days',
  'anonymous:kpis:visits-average': 'Average Visits',
  'anonymous:kpis:visits-average:tooltip':
    'Average of total visits detected, in the selected locations and dates.',
  'anonymous:kpis:frequency-visits-average': 'Average Visit Frequency',
  'anonymous:kpis:frequency-visits-average:tooltip':
    'Average number of frequent visitors, on more than one occasion, for the selected locations and dates.',
  'anonymous:retention-ratio:graph:title': 'Dwell time',
  'anonymous:retention-ratio:graph:tooltip':
    'Visitors who remain in the selected location for a time longer than the indicated time ratio (+2 minutes).',
  'anonymous:retention-ratio:graph:info': 'Visits +2 Min',
  'anonymous:no-locations:error:message': 'There are no associated locations',
  'anonymous:kpis:visits': 'Visits',
  'anonymous:kpis:visitors': 'Visitors',
  'anonymous:kpis:avg:time': 'avg time',
  // Breadcrumbs
  'breadcrumbs:locations': 'Locations',
  'breadcrumbs:taxonomies': 'Taxonomies',
  'breadcrumbs:tag-groups': 'Groups',
  'breadcrumbs:users': 'Users',
  'breadcrumbs:anonymous': 'Anonymous',
  'breadcrumbs:backoffice': 'Backoffice',
  'breadcrumbs:campaigns': 'Captation campaigns',
  // Date
  'date:calendar:monday': 'Monday',
  'date:calendar:tuesday': 'Tuesday',
  'date:calendar:wednesday': 'Wednesday',
  'date:calendar:thursday': 'Thursday',
  'date:calendar:friday': 'Friday',
  'date:calendar:saturday': 'Saturday',
  'date:calendar:sunday': 'Sunday',
  'date:calendar:january': 'January',
  'date:calendar:february': 'February',
  'date:calendar:march': 'March',
  'date:calendar:april': 'April',
  'date:calendar:may': 'May',
  'date:calendar:june': 'June',
  'date:calendar:july': 'July',
  'date:calendar:august': 'August',
  'date:calendar:september': 'September',
  'date:calendar:october': 'October',
  'date:calendar:november': 'November',
  'date:calendar:december': 'December',
  'date:calendar:full-day': '24 hours',
  'date:calendar:half-day': '12 hours',
  'date:calendar:interval': 'Interval',
  'date:calendar:initials:monday': 'M',
  'date:calendar:initials:tuesday': 'Tu',
  'date:calendar:initials:wednesday': 'W',
  'date:calendar:initials:thursday': 'Th',
  'date:calendar:initials:friday': 'F',
  'date:calendar:initials:saturday': 'S',
  'date:calendar:initials:sunday': 'Su',
  // Ususcribe
  'unsuscribe:title': 'Unsubscribe from ',
  'unsuscribe:text':
    'If you are sure you want to unsubscribe, click the button.',
  'unsuscribe:button': 'Unsubscribe',
  'unsuscribe:success:title': 'Done!',
  'unsuscribe:success:text': 'Unsuscribe done.',
  'unsuscribe:generic:error': 'There was an unsuscribin error.',
}

export default en
