import OrganizationPermissionsType from "../types/OrganizationPermissionsType";
import OrganizationContactPayloadModel from "./OrganizationContactPayloadModel";
import OrganizationZonePayloadModel from "./OrganizationZonePayloadModel";

export default class OrganizationPayloadModel {
  constructor(
    private readonly name: string,
    private readonly description: string,
    private readonly sector: string,
    private readonly contact: OrganizationContactPayloadModel,
    private readonly zones: OrganizationZonePayloadModel,
    private readonly permissions: OrganizationPermissionsType,
    private readonly logo: string,
    private readonly uuid?: string,
  ) {}
}
