import i18n from "../../../locales/i18n"


const TABLE_HEADER = [
  { id: 'name', label: i18n.t('locations:table:name'), align: 'left' },
  { id: 'address', label: i18n.t('locations:table:address'), align: 'left' },
  { id: 'actions', label: '', align: 'center', width: '2.5rem' },
]

export default TABLE_HEADER;
