import CampaignsNodeModel from "./campaignsNodeModel";

export class campaignsModel {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly description: string,
    readonly node: CampaignsNodeModel,
    readonly start: string,
    readonly end: string,
    readonly text: string,
    readonly imageUrl: string | null,
    readonly status: string,
    readonly paused: boolean,
    readonly registrations: number
  ) {}
}
