import { LONG_CARD_MIN_HEIGHT } from '../../style/components/GridStyle'
import HorizontalBarGraph from '../HorizontalBarGraph/HorizontalBarGraph'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import { useLocales } from '../../locales'
import ChartCard from '../card/ChartCard/ChartCard'

export default function TotalVisitsFrequencyGraph({
  maxValue,
  series,
  axisYTooltipFormatter,
}: {
  maxValue?: number
  series: { name: string; data: number, color?: string }[]
  axisYTooltipFormatter?: (value: string) => string
}) {
  const { translate } = useLocales()

  const title = `${translate('anonymous:kpis:chart:frequency:title')}`
  const tooltip = `${translate('anonymous:kpis:chart:frequency:tooltip')}`
  const isDataEmpty = series.every(item => item.data === 0)

  if (isDataEmpty) {
    return <EmptyCard title={title} tooltip={tooltip} height={LONG_CARD_MIN_HEIGHT} />
  }

  return (
    <ChartCard
      title={title}
      tooltip={tooltip}
      height={LONG_CARD_MIN_HEIGHT}
    >
      <HorizontalBarGraph series={series} maxValue={maxValue} />
    </ChartCard>
  )
}
