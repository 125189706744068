import PropTypes from 'prop-types'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Card, CardHeader } from '@mui/material'
// utils
import { fNumber } from '../../../utils/formatNumber'
// components
import Chart, { useChart } from '../../../components/chart'

// ----------------------------------------------------------------------

AnalyticsConversionRates.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

const StyledChart = styled('div')(() => ({
  '& .apexcharts-tooltip': {
    color: 'black',
  },
}))

export default function AnalyticsConversionRates({
  title,
  subheader,
  chart,
  ...other
}) {
  const { colors, series, options } = chart

  const chartSeries = series.map(i => i.value)

  const chartOptions = useChart({
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: value => fNumber(value) + ' visits',
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map(i => i.label),
    },
    ...options,
  })

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ mx: 3 }} dir="ltr">
        <StyledChart dir="ltr">
          <Chart
            type="bar"
            series={[{ data: chartSeries }]}
            options={chartOptions}
            height={364}
          />
        </StyledChart>
      </Box>
    </Card>
  )
}
