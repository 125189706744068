const LanguagePopoverStyle = {
  iconSize: 40,
  menu: {
    width: 180,
  },
  menuIcon: {
    width: 28,
    mr: 2
  }
}

export default LanguagePopoverStyle
