import { Card, CardActionArea, CardHeader, Stack, Typography } from "@mui/material";
import ActionPopover from "../../../sections/dashboard/users/table/ActionsPopover";
import TaxonomyTagModel from "../../../services/taxonomies/model/TaxonomyTagModel";
import style from './TagCardStyle'

export default function TagCard({
  name,
  tags,
  onClickEdit,
  onClickDelete,
  onClickCard,
} : {
  name: string,
  tags: TaxonomyTagModel[],
  onClickEdit: () => void,
  onClickDelete: () => void,
  onClickCard?: () => void,
}) {
  const getTags = (tags: any): JSX.Element => {
    if (tags.length === 0) {
      return <></>
    }

    const joinTags = tags.map((tag: { value: any }) => (
      tag.value
    )).join(', ')

    return (
      <Typography variant="body2" color="textSecondary" noWrap>
        {joinTags}
      </Typography>
    )
  }

  return (
    <Card
      sx={style.container}
    >
      <Stack sx={style.headerContainer}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <CardHeader
            title={name}
            sx={[style.cardHeader, onClickCard ? style.clickable :{} ]}
            onClick={onClickCard}
          />
          <ActionPopover
            handleEdit={onClickEdit}
            handleDelete={onClickDelete}
          />
        </Stack>
        <CardActionArea
          sx={[onClickCard ? style.clickable :{} ]}
          onClick={onClickCard}
        >
          {getTags(tags)}
        </CardActionArea>
      </Stack>
    </Card>
  )
}
