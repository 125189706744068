import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useLocales } from '../../locales'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import Stack from '@mui/material/Stack'
import useCountries from '../../hooks/useCountries'
import style from './OrganizationSectionFormStyle'

export default function OrganizationContactForm({
  form,
 } : {
  form: any,
 }) {
  const { translate } = useLocales()
  const { listPhonePrefixes } = useCountries()
  const phonePrefixes = listPhonePrefixes()

  const getAutocomplete = (): JSX.Element => {
    return (
      <Autocomplete
        sx={style.autocompleteSelector}
        options={phonePrefixes}
        value={
          form.values.contactPhonePreffix
        }
        onChange={(_event, value) => {
          form.changeFieldValue({
            target: {
              name: 'contactPhonePreffix',
              value,
            },
          })
        }}
        onBlur={form.validateFieldValue}
        getOptionLabel={phonePrefix => {
          return phonePrefix
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={`${translate(
              'new-organization:contact-form:prefix-label',
            )}`}
            name="contactPhonePreffix"
            value={
              form.values.contactPhonePreffix
            }
            error={
              form.errors.contactPhonePreffix !==
              ''
            }
            helperText={
              form.errors.contactPhonePreffix
            }
          />
        )}
      />
    )
  }

  return (
    <Grid container spacing={2}>
    <Grid item xs={12} md={3}>
      <FormInfoSection
        title={`${translate('new-organization:content-title')}`}
        subtitle={`${translate('new-organization:content-description')}`}
      />
    </Grid>
    <Grid item xs={12} md={8}>
      <Grid item xs={12}>
        <Card sx={style.card}>
          <form>
            <FormControl sx={style.form}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  name="contactName"
                  label={`${translate(
                    'new-organization:contact-form:name-label',
                  )}`}
                  value={form.values.contactName}
                  onChange={form.changeFieldValue}
                  onBlur={form.validateFieldValue}
                  error={form.errors.contactName !== ''}
                  helperText={form.errors.contactName}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  name="contactSurname"
                  label={`${translate(
                    'new-organization:contact-form:surname-label',
                  )}`}
                  value={form.values.contactSurname}
                  onChange={form.changeFieldValue}
                  onBlur={form.validateFieldValue}
                  error={form.errors.contactSurname !== ''}
                  helperText={form.errors.contactSurname}
                />
              </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  {getAutocomplete()}
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="contactPhoneNumber"

                    label={`${translate(
                      'new-organization:contact-form:phone-label',
                    )}`}
                    value={form.values.contactPhoneNumber}
                    onChange={form.changeFieldValue}
                    onBlur={form.validateFieldValue}
                    error={
                      form.errors.contactPhoneNumber !== ''
                    }
                    helperText={
                      form.errors.contactPhoneNumber
                    }
                  />
                </Stack>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="contactEmail"
                  label={`${translate(
                    'new-organization:contact-form:email-label',
                  )}`}
                  value={form.values.contactEmail}
                  onChange={form.changeFieldValue}
                  onBlur={form.validateFieldValue}
                  error={form.errors.contactEmail !== ''}
                  helperText={form.errors.contactEmail}
                />
            </FormControl>
          </form>
        </Card>
      </Grid>
    </Grid>
  </Grid>
  )
}
