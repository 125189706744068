import axios from '../../utils/axios'
import {
  ClientDoesNotExist,
  ForbiddenOrganization,
  HTTPFailureStatusCodes,
} from '../Errors'

import {
  InvalidNewOrganizationPayloadError,
  InvalidUpdateOrganizationPayloadError,
} from './Error'
import OrganizationPayloadModel from './model/OrganizationPayloadModel'

export async function fetchOrganizations() {
  try {
    const response = await axios.get('/api/v1/organizations')
    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      default:
        throw error
    }
  }
}

export async function createOrganization(payload: OrganizationPayloadModel) {
  const endpoint = '/api/v1/organizations'

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.status === 400) {
      throw new InvalidNewOrganizationPayloadError(endpoint)
    }

    throw new Error('Unknown error')
  }
}

export async function updateOrganization(id: string, payload: OrganizationPayloadModel) {
  const endpoint = `/api/v1/organizations/${id}`

  try {
    await axios.put(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.status === 400) {
      throw new InvalidUpdateOrganizationPayloadError(endpoint)
    }

    throw new Error('Unknown error')
  }
}

export async function fetchOrganization(organizationId: string) {
  try {
    const response = await axios.get(`/api/v1/organizations/${organizationId}`)

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      default:
        throw error
    }
  }
}

export async function removeOrganization(organizationId: string) {
  try {
    const response = await axios.delete(
      `/api/v1/organizations/${organizationId}`,
    )

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      default:
        throw error
    }
  }
}

export async function unsuscribeOrganization(organizationUuid: string, customerUuid: string): Promise<void> {
  const url = `/api/v1/organiztions/${organizationUuid}/customer/${customerUuid}/unsuscribe`

  try {
    await axios.put(url)
  } catch (error) {
    throw new Error(error)
  }
}

export async function fetchPublicOrganization(organizationUuid: string): Promise<string> {
  const url = `/api/v1/organiztions/${organizationUuid}/name`

  try {
    return axios.get(url)
  } catch (error) {
    throw new Error(error)
  }
}
