const PrimaryButtonStyle = {
  button: {
    backgroundColor: 'grey.800',
    minWidth: 'max-content',
  },
  iconButton: {
    p: 1,
    "& .MuiButton-startIcon": { margin: 0 }
  }
}

export default PrimaryButtonStyle
