import TaxonomyTagUpdateModel from "../../tagGroups/model/TaxonomyTagUpdateModel";

export default class LocationPayloadModel {
  constructor(
    readonly organizationUUID: string,
    readonly uuid: string,
    readonly name: string,
    readonly address: string,
    readonly timezone: string,
    readonly contact: {
      name: string,
      lastName: string,
      phone: string,
      email: string,
    },
    readonly taxonomyTags: TaxonomyTagUpdateModel[],
  ){}
}
