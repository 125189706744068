import { Card } from '@mui/material'
import style from './TableCardStyle'

export default function TableCard({
  children,
} : {
  children: React.ReactNode,
}) {
  return (
    <Card sx={style.container}>
      {children}
    </Card>
  )
}
