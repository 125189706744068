import { Autocomplete, Card, FormControl, Grid, Stack, TextField, Typography } from "@mui/material"
import style from './LocationFormStyle'
import { useLocales } from "../../../locales"
import useCountries from "../../../hooks/useCountries"

export default function LocationContactInfoForm({form}: {form: any}) {
  const { translate } = useLocales()
  const { listPhonePrefixes } = useCountries()
  const phonePrefixes = listPhonePrefixes()


  return (
    <Grid item container>
    <Grid item xs={4} sx={style.sectionTitleContainer}>
      <Typography variant="h5" sx={style.sectionTitle}>
        {`${translate('new-location:contact-title')}`}
      </Typography>

      <Typography sx={style.sectionSubtitle}>
        {`${translate('new-location:contact-description')}`}
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Card sx={style.formContainer}>
        <form>
          <FormControl sx={style.formWrapper}>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                sx={style.inputField}
                name="address"
                label={`${translate('new-location:form:address-label')}`}
                value={form.values.address}
                onChange={form.changeFieldValue}
                onBlur={form.validateFieldValue}
                error={form.errors.address !== ''}
                helperText={form.errors.address}
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                sx={style.inputField}
                name="contactName"
                label={`${translate('new-location:form:contact-name-label')}`}
                value={form.values.contactName ?? ''}
                onChange={form.changeFieldValue}
                error={form.errors.contactName !== ''}
                onBlur={form.validateFieldValue}
                helperText={form.errors.contactName}
              />

              <TextField
                fullWidth
                variant="outlined"
                sx={style.inputField}
                name="contactLastName"
                label={`${translate('new-location:form:contact-last-name-label')}`}
                value={form.values.contactLastName ?? ''}
                onChange={form.changeFieldValue}
                error={form.errors.contactLastName !== ''}
                onBlur={form.validateFieldValue}
                helperText={form.errors.contactLastName}
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <Autocomplete
                options={phonePrefixes}
                sx={style.autocompleteSelector}
                value={form.values.contactPhonePreffix || null}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.contactPhonePreffix === value.contactPhonePreffix
                }
                onChange={(_event, value) => {
                  form.changeFieldValue({
                    target: {
                      name: 'contactPhonePreffix',
                      value,
                    },
                  })
                }}
                onBlur={form.validateFieldValue}
                getOptionLabel={phonePrefix => phonePrefix}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={`${translate(
                      'new-location:form:contact-phone-prefix-label',
                    )}`}
                    name="contactPhonePreffix"
                    value={form.values.contactPhonePreffix}
                    error={form.errors.contactPhonePreffix !== ''}
                    helperText={form.errors.contactPhonePreffix}
                  />
                )}
              />
              <TextField
                fullWidth
                variant="outlined"
                name="contactPhoneNumber"
                label={`${translate(
                  'new-location:form:contact-phone-number-label',
                )}`}
                value={form.values.contactPhoneNumber}
                onChange={form.changeFieldValue}
                onBlur={form.validateFieldValue}
                error={form.errors.contactPhoneNumber !== ''}
                helperText={form.errors.contactPhoneNumber}
              />
            </Stack>
            <Stack>
              <TextField
                fullWidth
                variant="outlined"
                sx={style.inputField}
                name="contactEmail"
                label={`${translate('new-location:form:contact-email-label')}`}
                value={form.values.contactEmail}
                onChange={form.changeFieldValue}
                onBlur={form.validateFieldValue}
                error={form.errors.contactEmail !== ''}
                helperText={form.errors.contactEmail}
              />
            </Stack>
          </FormControl>
        </form>
      </Card>
    </Grid>
  </Grid>
  )
}
