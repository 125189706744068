export default class AnonymousFrequencyModel {
  constructor(
    readonly one: number,
    readonly two: number,
    readonly three_plus: number
  ) {}

  getTotalVisits(): number {
    return this.one + this.two + this.three_plus;
  }
}
