const SEPARATION = 3

const TaxonomyFormStyle = {
  tagContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: SEPARATION,
    width: "100%"
  },
  tagChipContainer: {
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap"
  }
}

export default TaxonomyFormStyle
