import { Card, Chip, FormControl, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useLocales } from "../../../locales";
import style from './LocationFormStyle'
import useTaxonomies from "../../../services/taxonomies/useTaxonomies";
import TaxonomyModel from "../../../services/taxonomies/model/TaxonomyModel";
import TaxonomyTagModel from "../../../services/taxonomies/model/TaxonomyTagModel";
import TaxonomyTagGroupModel from "../../../services/tagGroups/model/TaxonomyTagGroupModel";

export default function LocationTaxonomiesForm({form, organizationUUID}: {
  form: any,
  organizationUUID: string
  }) {
  const { translate } = useLocales()
  const taxonomies = useTaxonomies(organizationUUID || '') as any

  const changeField = (taxonomy: TaxonomyModel, tag: TaxonomyTagModel): void => {

    form.addTagValue(new TaxonomyTagGroupModel(
      taxonomy.uuid,
      taxonomy.name,
      tag.uuid,
      tag.value,
      [],
    ))
  }

  const getTags = (): JSX.Element => {
    if (form.values?.tags?.length === 0) {
      return <>
        {translate('location:taxonomies:empty')}
      </>
    }

    return (
      <Stack sx={style.tagChipContainer}>
        {form.values.tags.map((
          tag: TaxonomyTagGroupModel,
          index: number,
        ) =>
          <Chip
            key={`${index}-${tag.tagName}`}
            variant="outlined"
            label={tag.tagName}
            onDelete={() => form.removeTagValue(tag)}
          />
        )}
      </Stack>
    )
  }

  const getTaxonomyMenuItems = (taxonomy: TaxonomyModel): JSX.Element => {
    return (
      <Stack>
        {taxonomy.tags.map((
          tag: TaxonomyTagModel,
          index: number,
        ) =>
          <MenuItem
            key={`${index}-${tag.value}`}
            onClick={() => changeField(taxonomy, tag)}
            onBlur={form.validateTagValue}
          >
            {tag.value}
          </MenuItem>
        )}
      </Stack>
    )
  }

  const getTaxonomySelectorBlock = (): JSX.Element => {
    if (!taxonomies.data) {
      return <></>
    }

    return taxonomies.data.map((data: TaxonomyModel, index: number) => {
      return (
        <Stack key={`${index}-${data.name}`}>
          <TextField
            select
            label={data.name}
            name={`selector-${data.name}`}
            placeholder="Seleccionar"
            size="small"
            error={form.errors.tags !== ''}
            helperText={form.errors.tags || ''}
          >
            {getTaxonomyMenuItems(data)}
          </TextField>
        </Stack>
      )
    })
  }

  return (
    <Grid item container>
    <Grid item xs={4} sx={style.sectionTitleContainer}>
      <Typography variant="h5" sx={style.sectionTitle}>
        {`${translate('new-location:tag-group-title')}`}
      </Typography>

      <Typography sx={style.sectionSubtitle}>
        {`${translate('new-location:tag-group-description')}`}
      </Typography>
    </Grid>

    <Grid item xs={8}>
      <Card sx={style.formContainer}>
        <form>
          <FormControl sx={style.formWrapper}>
            {getTaxonomySelectorBlock()}
            {getTags()}
          </FormControl>
        </form>
      </Card>
    </Grid>
  </Grid>
  )
}
