import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { PATH_ACCOUNTS } from '../../routes/paths'

import { createLocation, updateLocation } from './locationService'
import { adaptCreateLocationPayload, adaptUpdateLocationPayload } from './locationAdapters'
import LocationFormModel from './model/LocationFormModel'

function useLocationMutation(organizationId: string) {
  const navigate = useNavigate()

  const [state, setState] = useState({
    success: false,
    loading: false,
    error: null,
  })

  const create = useCallback(
    async (payload: LocationFormModel) => {
      if (!organizationId) return

      setState({
        success: false,
        loading: true,
        error: null,
      })

      try {
        await createLocation(
          adaptCreateLocationPayload(payload, organizationId),
        )

        setState(state => ({
          ...state,
          loading: false,
          success: true,
        }))

        navigate(`${PATH_ACCOUNTS.locations(organizationId)}?created=true`)

        return Promise.resolve()
      } catch (error) {
        setState({
          loading: false,
          success: false,
          error,
        })

        return Promise.reject(error)
      }
    },
    [organizationId, navigate],
  )

  const update = (id: string) => async (payload: LocationFormModel, organizationUUID: string) => {
    setState({
      success: false,
      loading: true,
      error: null,
    })
    
    try {
      await updateLocation(
        adaptUpdateLocationPayload(payload),
        id,
        organizationUUID,
      )

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.locations(organizationId)}?updated=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    create,
    update,
    clearError,
  }
}

export default useLocationMutation
