import { Grid, Typography } from '@mui/material'
import ViewContainer from '../../../components/container/ViewContainer/ViewContainer'
import FilterBar from '../../../components/FilterBar/FilterBar'
import Skeleton from '../../../components/skeleton/Skeleton'
import {
  ANONYMOUS_DETECTIONS_DATA,
  ANONYMOUS_KPI_DATA,
  ANONYMOUS_TRAFFIC_DATA,
  AVERAGE_DETECTIONS_DATA,
} from '../../../utils/dashboardsConstants'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import useAnonymousKPIs from '../../../services/anonymous/useAnonymousKPIs'
import AnonymousKPIs from '../../../components/anonymous/AnonymousKPIs/AnonymousKPIs'
import { useState } from 'react'
import NoItemsCard from '../../../components/no-items-card/NoItemsCard'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { SelectItemType } from '../../../services/common/types/SelectItemType'
import DatePeriod from '../../../services/common/enum/DatePeriod'
import useAnonymousTrafficZones from '../../../services/anonymous/useAnonymousTraficZones'
import useAnonymousFrequency, {
  UseAnonymousFrequencyStateType,
} from '../../../services/anonymous/useAnonymousFrequency'
import EmptyCard from '../../../components/card/EmptyCard/EmptyCard'
import useAnonymousDetections from '../../../services/anonymous/useAnonymousDetections'
import HeatmapWeekSelector from '../../../components/HeatmapWeekSelector/HeatmapWeekSelector'
import useAnonymousKPIsType from '../../../services/anonymous/types/useAnonymousKPIsType'
import TimeComebackChart from '../../../components/timeComebackChart/TimeComebackChart'
import useAnonymousDwellTime from '../../../services/anonymous/useAnonymousDwellTime'
import useAnonymousAttractionRate from '../../../services/anonymous/useAnonymousAttractionRate'
import AnonymousAttractionRateModel from '../../../services/anonymous/model/AnonymousAttractionRateModel'
import UseAnonymousStateType from '../../../services/common/types/UseAnonymousStateType'
import AverageCard from '../../../components/card/AverageCard/AverageCard'
import useAnonymousAverageVisits from '../../../services/anonymous/useAnonymousAverageVisits'
import AnonymousAverageVisitsModel from '../../../services/anonymous/model/AnonymousAverageVisitsModel'
import useAnonymousAverageFrequencyVisits from '../../../services/anonymous/useAnonymousAverageFrecuencyVisits'
import AnonymousDwellTimeModel from '../../../services/anonymous/model/AnonymousDwellTimeModel'
import AttractionRatioGraph from '../../../components/attractionRatioGraph/AttractionRatioGraph'
import TotalVisitsGraph from '../../../components/totalVisitsGraph/TotalVisitsGraph'
import DwellTimeGraph from '../../../components/dwellTimeGraph/DwellTimeGraph'
import TotalVisitsFrequencyGraph from '../../../components/totalVisitsFrequencyGraph/TotalVisitsFrequencyGraph'
import { NoLocationsError } from '../../../services/anonymous/errors/noLocationsFoundError'
import { useTheme } from '@emotion/react'
import style from './AnonymousStyle'
import { GRAPH_CARD_MIN_HEIGHT } from '../../../style/components/GridStyle'
import { Helmet } from 'react-helmet-async'

export default function Anonymous(): JSX.Element {
  const { translate } = useLocales()

  const [selectedLocations, setSelectedLocations] = useState<SelectItemType[]>(
    [],
  )
  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    DatePeriod.YESTERDAY,
  )

  const { organizationId, groupId } = useParams<string>()
  const {
    data: anonymousKPIsData,
    error: anonymousKPIsError,
    clearError: anonymousKPIsClearError,
    loading: isLoadingKPIs,
  } = useAnonymousKPIs(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as useAnonymousKPIsType
  const { data: anonymousDwellTimeData, loading: isLoadingDwellTime } =
    useAnonymousDwellTime(
      organizationId!,
      groupId!,
      selectedPeriod,
    ) as UseAnonymousStateType<AnonymousDwellTimeModel>
  const {
    data: anonymousTrafficZonesData,
    error: anonymousTrafficZonesError,
    clearError: anonymousTrafficZonesClearError,
    loading: isLoadingTrafficZones,
  } = useAnonymousTrafficZones(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as any
  const {
    data: anonymousDetectionsData,
    error: anonymousDetectionsError,
    clearError: anonymousDetectionsClearError,
    loading: isLoadingDetections,
  } = useAnonymousDetections(
    organizationId!,
    groupId!,
    selectedLocations,
    selectedPeriod,
  ) as any
  const {
    data: anonymousFrequencyData,
    loading: isLoadingAnonymousFrequencyData,
    error: anonymousFrequencyError,
  } = useAnonymousFrequency(
    organizationId!,
    groupId!,
    selectedPeriod,
  ) as UseAnonymousFrequencyStateType
  const {
    data: anonymousAttractionRateData,
    error: anonymousAttractionRateError,
    clearError: anonymousAttractionRateClearError,
  } = useAnonymousAttractionRate(
    organizationId!,
    groupId!,
    selectedPeriod,
  ) as UseAnonymousStateType<AnonymousAttractionRateModel>
  const {
    data: anonymousAverageVisitsData,
    loading: isLoadingAnonymousAverageVisitsData,
    error: anonymousAverageVisitsError,
  } = useAnonymousAverageVisits(
    organizationId!,
    groupId!,
    selectedPeriod,
  ) as UseAnonymousStateType<AnonymousAverageVisitsModel>
  const {
    data: anonymousAverageFrequencyVisitsData,
    loading: isLoadingAnonymousAverageFrequencyVisitsData,
    error: anonymousAverageFrequencyVisitsError,
  } = useAnonymousAverageFrequencyVisits(
    organizationId!,
    groupId!,
    selectedPeriod,
  ) as UseAnonymousStateType<AnonymousAverageVisitsModel>
  const theme = useTheme() as any

  const notLocationAssigned = (errors: Error[] | null[]): boolean => {
    return errors.some(error => error instanceof NoLocationsError)
  }

  if (
    notLocationAssigned([
      anonymousKPIsError,
      anonymousTrafficZonesError,
      anonymousFrequencyError,
      anonymousAttractionRateError,
      anonymousAverageVisitsError,
      anonymousAverageFrequencyVisitsError,
    ])
  ) {
    return (
      <Typography variant="h4" align="center">
        {`${translate('anonymous:no-locations:error:message')}`}
      </Typography>
    )
  }

  const handleChangePeriod = (period: string) => {
    setSelectedPeriod(period)
  }

  const handleChangeLocations = (locations: SelectItemType[]) => {
    setSelectedLocations(locations)
  }

  const getErrorMessage = (): string => {
    return `${translate('anonymous:request:generic:error')}`
  }

  const getKPIs = (): JSX.Element => {
    if (isLoadingKPIs) {
      return <Skeleton loadingData={ANONYMOUS_KPI_DATA} />
    }

    if (selectedLocations?.length === 0 && !selectedPeriod) {
      return (
        <NoItemsCard
          title={`${translate('anonymous:empty:filter:title')}`}
          description={`${translate('anonymous:empty:filter:text')}`}
        />
      )
    }

    return <AnonymousKPIs anonymousKPIs={anonymousKPIsData!} />
  }


  const getTrafficChart = (): JSX.Element => {
    return (
      <TotalVisitsGraph
        detectionsSum={anonymousKPIsData?.detections_sum!}
        anonymousTrafficZonesData={anonymousTrafficZonesData}
        selectedPeriod={selectedPeriod}
      />
    )
  }

  const getChartBlock = (): JSX.Element => {
    if (isLoadingTrafficZones) {
      return <Skeleton loadingData={ANONYMOUS_TRAFFIC_DATA} />
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {getTrafficChart()}
        </Grid>
      </Grid>
    )
  }

  const getFrequencyChart = (): JSX.Element => {
    if (isLoadingAnonymousFrequencyData) {
      return <></>
    }

    return (
        <TotalVisitsFrequencyGraph
          maxValue={anonymousFrequencyData?.getTotalVisits()}
          series={[
            {
              data: anonymousFrequencyData?.one || 0,
              name: `${translate('anonymous:kpis:chart:frequency:one-visit')}`,
              color: theme.palette.chart.light.secondZone,
            },
            {
              data: anonymousFrequencyData?.two || 0,
              name: `${translate('anonymous:kpis:chart:frequency:two-visits')}`,
              color: theme.palette.chart.light.firstZone,
            },
            {
              data: anonymousFrequencyData?.three_plus || 0,
              name: `${translate(
                'anonymous:kpis:chart:frequency:three-or-more-visits',
              )}`,
              color: theme.palette.chart.light.cashRegisters,
            },
          ]}
        />
    )
  }

  const getAttractionFrequencyChart = (): JSX.Element => {
    return (
      <AttractionRatioGraph
        series={[
          {
            value: anonymousAttractionRateData?.current.internal,
            label: `${translate('anonymous:visits:graph:unique')}`,
            color: '#0dae75',
          },
        ]}
        maxValue={anonymousAttractionRateData?.current.external}
        totalLabel={`${translate('anonymous:ratio-attraction:graph:label')}`}
        variant={anonymousAttractionRateData?.variation}
      />
    )
  }

  const getDwellTimeChart = (): JSX.Element => {
    const title = `${translate('anonymous:retention-ratio:graph:title')}`
    const tooltip = `${translate('anonymous:retention-ratio:graph:tooltip')}`

    if (isLoadingDwellTime || !anonymousDwellTimeData) {
      return <EmptyCard title={title} tooltip={tooltip} height={GRAPH_CARD_MIN_HEIGHT} />
    }

    return (
      <DwellTimeGraph
        title={title}
        tooltip={tooltip}
        variant={anonymousDwellTimeData.dwellTimeDifferenceRate}
        series={[
          {
            value: Math.min(anonymousDwellTimeData.dwellTimeRate, 100),
            label: `${translate('anonymous:retention-ratio:graph:info')}`,
            color: 'purple',
          },
        ]}
      />
    )
  }

  const getHeatmap = (): JSX.Element => {
    if (isLoadingDetections) {
      return <Skeleton loadingData={ANONYMOUS_DETECTIONS_DATA} />
    }

    if (!anonymousDetectionsData) {
      return <></>
    }

    const maxValue = Math.max(
      ...anonymousDetectionsData?.flatMap((item: { data: any[] }) =>
        item.data.map(dataPoint => dataPoint.y),
      ),
    )

    return (
      <HeatmapWeekSelector
        title={`${translate('anonymous:heatmap:detections:label')}`}
        tooltip={`${translate('anonymous:heatmap:detections:tooltip')}`}
        maxValue={maxValue}
        data={anonymousDetectionsData}
      />
    )
  }

  const getAverageVisitsCard = (): JSX.Element => {
    const title = `${translate('anonymous:kpis:visits-average')}`
    const tooltip = `${translate('anonymous:kpis:visits-average:tooltip')}`
    if (isLoadingAnonymousAverageVisitsData) {
      return <Skeleton loadingData={AVERAGE_DETECTIONS_DATA} />
    }
    if (!anonymousAverageVisitsData) {
      return <EmptyCard title={title} tooltip={tooltip} />
    }

    return (
      <AverageCard
        title={title}
        value={anonymousAverageVisitsData?.currentPeriod}
        variation={anonymousAverageVisitsData.variation}
        tooltip={tooltip}
      />
    )
  }

  const getAverageFrequencyVisitsCard = (): JSX.Element => {
    const title = `${translate('anonymous:kpis:frequency-visits-average')}`
    const tooltip = `${translate('anonymous:kpis:frequency-visits-average:tooltip')}`
    if (isLoadingAnonymousAverageFrequencyVisitsData) {
      return <Skeleton loadingData={AVERAGE_DETECTIONS_DATA} />
    }
    if (!anonymousAverageFrequencyVisitsData) {
      return (
        <EmptyCard title={title} tooltip={tooltip} />
      )
    }

    return (
      <AverageCard
        title={title}
        value={anonymousAverageFrequencyVisitsData?.currentPeriod}
        variation={anonymousAverageFrequencyVisitsData.variation}
        tooltip={tooltip}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{`${translate('nav:dashboard:anonymous')}`} | So69</title>
      </Helmet>
      <ViewContainer>
        <Grid container sx={style.container}>
          <Typography variant="h4">{`${translate(
            'nav:dashboard:anonymous',
          )}`}</Typography>
          <FilterBar
            updatePeriod={handleChangePeriod}
            updateLocation={handleChangeLocations}
          />

          <Grid container spacing={2}>
            {getKPIs()}
          </Grid>

          {getChartBlock()}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {getFrequencyChart()}
              </Grid>
              <Grid item xs={12}>
                <TimeComebackChart anonymousKPIsData={anonymousKPIsData} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {getAttractionFrequencyChart()}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {getDwellTimeChart()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                {getHeatmap()}
              </Grid>
              <Grid item sm={6}>
                {getAverageVisitsCard()}
              </Grid>
              <Grid item sm={6}>
                {getAverageFrequencyVisitsCard()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SnackbarAlert
        isOpen={anonymousKPIsError !== null}
        message={getErrorMessage()}
        onClose={anonymousKPIsClearError}
        type={SnackBarAlertTypes.ERROR}
      />
      <SnackbarAlert
        isOpen={anonymousTrafficZonesError !== null}
        message={getErrorMessage()}
        onClose={anonymousTrafficZonesClearError}
        type={SnackBarAlertTypes.ERROR}
      />
      <SnackbarAlert
        isOpen={anonymousDetectionsError !== null}
        message={getErrorMessage()}
        onClose={anonymousDetectionsClearError}
        type={SnackBarAlertTypes.ERROR}
      />
      <SnackbarAlert
        isOpen={anonymousAttractionRateError !== null}
        message={getErrorMessage()}
        onClose={anonymousAttractionRateClearError}
        type={SnackBarAlertTypes.ERROR}
      />
    </ViewContainer>
  </>
  )
}
