import { useCallback, useEffect, useState } from 'react'

import { fetchUserById, updateUserById } from './service'
import UserFormModel from './model/UserFormModel'
import UserInfoModel from './model/UserInfoModel'

export default function useUser(id: string) {
  interface useUserState {
    loading: boolean;
    updating: boolean;
    error: any;
    user: UserInfoModel | undefined;
  }

  const [state, setState] = useState({
    loading: false,
    updating: false,
    error: null,
    user: undefined,
  } as useUserState)

  const fetchById = useCallback(async (id: string) => {
    if (id === undefined) return

    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const user = await fetchUserById(id)

      setState({
        ...state,
        loading: false,
        error: null,
        user: user,
      })
    } catch (error) {
      setState(state => ({
        ...state,
        loading: false,
        error: error,
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const updateById = useCallback(
    async (payload: UserFormModel) => {
      if (!id) return

      setState(state => ({
        ...state,
        updating: true,
        error: null,
      }))

      try {
        await updateUserById(id, payload)

        setState(state => ({
          ...state,
          updating: false,
        }))
      } catch (error) {
        setState(state => ({
          ...state,
          updating: false,
          error,
        }))

        return Promise.reject(error)
      }
    },
    [id],
  )

  useEffect(() => {
    fetchById(id)
  }, [id, fetchById])

  return {
    ...state,
    updateById,
  }
}
