import PropTypes from 'prop-types'

import { styled, useTheme } from '@mui/material/styles'
import { Box, Card, CardHeader, Chip } from '@mui/material'
import Chart, { useChart } from '../../../components/chart'
import { useLocales } from '../../../locales'

AnalyticsWebsiteVisits.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

const StyledChart = styled('div')(({ theme }) => ({
  '& .apexcharts-tooltip': {
    color: 'black',
  },
}))

export default function AnalyticsWebsiteVisits({
  title,
  subheader,
  granularity,
  data,
  labels,
  colors,
  options,
  ...other
}) {
  const theme = useTheme()
  const { translate } = useLocales()
  const defaultColors = [
    theme.palette.data.registered,
    theme.palette.data.new,
    theme.palette.data.recurrent,
  ]

  const selectedColors = colors ?? defaultColors

  const chartOptions = useChart({
    colors: selectedColors,
    plotOptions: {
      bar: {
        columnWidth: '16%',
      },
    },
    fill: {
      type: data.map(i => i.fill),
    },
    labels,
    xaxis: {
      type: 'date',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: value => {
          if (typeof value !== 'undefined') {
            return `${value.toFixed(0)} ${translate(
              'graph:users',
            ).toLowerCase()}`
          }
          return value
        },
      },
    },
    ...options,
  })

  return (
    <Card {...other}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ pr: 3 }}
      >
        <CardHeader title={title} subheader={subheader} />
        {granularity ? (
          <Chip
            sx={{
              borderRadius: '6px',
              backgroundColor: 'disabled',
              fontSize: '14px',
              fontWeight: '600',
            }}
            label={granularity}
          />
        ) : null}
      </Box>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <StyledChart dir="ltr">
          <Chart
            type="line"
            series={data}
            options={chartOptions}
            height={364}
          />
        </StyledChart>
      </Box>
    </Card>
  )
}
