import PropTypes from 'prop-types'
// @mui
import { TableRow, TableCell } from '@mui/material'
//
import EmptyContent from '../empty-content'
import { useLocales } from '../../locales'

// ----------------------------------------------------------------------

TableNoData.propTypes = {
  isNotFound: PropTypes.bool,
  title: PropTypes.string,
}

export default function TableNoData({
  isNotFound,
  title = 'realtime:no-data',
}) {
  const { translate } = useLocales()
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={translate(title)}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  )
}
