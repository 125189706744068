import { useCallback, useReducer, useState } from 'react'
import { useLocales } from '../locales'
import { EventTargetType } from './types/EventTargetType'
import { StateType } from './types/StateType'
import { ActionType } from './types/ActionType'

const ERRORS = {
  missingField: 'common:form:error-missing-field',
  requiredPOS: 'create:node:form:description:not:pos:error',
}

const isDescriptionRequired = (value: any, form: any): boolean => {
  return !Boolean(value) && form.values?.zone?.sort === 0
}

const makeValidations = (form: any, translate: any) => ({
  description: (value: any) => isDescriptionRequired(value, form) ? translate(ERRORS.requiredPOS) : '',
  organization: (value: any) => !Boolean(value) ? translate(ERRORS.missingField) : '',
  location: (value: any) => !Boolean(value) ? translate(ERRORS.missingField) : '',
  zone: (value: any) => !Boolean(value) ? translate(ERRORS.missingField) : '',
})

function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case 'change-field-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: action.payload,
        },
      }

    case 'change-organization-value':
      return {
        ...state,
        values: {
          ...state.values,
          organization: action.payload,
          location: null,
          zone: null,
        },
      }

    case 'change-fields-values':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      }

    case 'update-error':
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.payload },
      }

    case 'update-errors':
      return {
        ...state,
        errors: { ...action.payload },
      }

    default:
      return { ...state }
  }
}

function createInitialState() {
  return {
    values: {
      description: '',
      organization: null,
      location: null,
      zone: null,
    },
    errors: {
      description: '',
      organization: '',
      location: '',
      zone: '',
    },
  }
}

function useNodeForm({ successCallback, initialState = null }: any) {
  const { translate } = useLocales()

  const [form, dispatchForm] = useReducer(
    reducer,
    initialState ?? createInitialState(),
  )

  const validations: any = makeValidations(form, translate)

  const [shouldValidateForm, setShouldValidateForm] = useState(false)

  const activateFormValidation = () => {
    setShouldValidateForm(true)
  }

  const changeFieldValue = (event: EventTargetType): void => {
    dispatchForm({
      type: 'change-field-value',
      fieldName: event.target.name,
      payload: event.target.value,
    })
  }

  const changeOrganizationValue = (event: EventTargetType): void => {
    dispatchForm({
      type: 'change-organization-value',
      fieldName: event.target.name,
      payload: event.target.value,
    })
  }

  const changeFieldsValues = useCallback((values: any) => {
    return dispatchForm({
      type: 'change-fields-values',
      payload: values,
      fieldName: undefined
    })
  }, [])

  const validateFieldValue = useCallback((event: EventTargetType): void => {
      if (!shouldValidateForm) return

      const name = event.target.name
      const value = event.target.value
      const result = validations[name](value)

      dispatchForm({
        type: 'update-error',
        fieldName: name,
        payload: result,
      })
    },
    [validations, shouldValidateForm],
  )

  const validate = useCallback(() => {
    const errors = { ...form.errors }

    errors.description = validations.description(
      form.values.description,
    )

    errors.organization = validations.organization(
      form.values.organization,
    )

    errors.location = validations.location(
      form.values.location,
    )

    errors.zone = validations.zone(
      form.values.zone,
    )

    dispatchForm({
      type: 'update-errors',
      payload: errors,
      fieldName: undefined
    })

    return Object.values(errors).every(error => error === '')
  }, [validations, form.values, form.errors])

  const submit = useCallback((event: { preventDefault: () => void }) => {
      event.preventDefault()

      if (!shouldValidateForm) {
        activateFormValidation()
      }

      const isFormValid = validate()

      if (isFormValid) {
        successCallback(form.values)
      }
    },
    [shouldValidateForm, validate, form.values, successCallback],
  )

  return {
    values: form.values,
    errors: form.errors,
    changeFieldValue,
    changeOrganizationValue,
    changeFieldsValues,
    validateFieldValue,
    validate,
    submit,
  }
}

export default useNodeForm
