import { alpha, Theme } from "@mui/material"
import { ICON } from "../../../config-global"

const NavSectionItemStyle = (theme: Theme, isActive: boolean, isCollapsed: boolean) => {
  const activeStyle = {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  }

  const hoverStyle = {
    color: 'text.primary',
    backgroundColor: 'action.hover',
  }

  const listItemButtonStyle = {
    color:  theme.palette.text.secondary,
    textTransform: 'capitalize',
    borderRadius: 1,
    '&:hover': hoverStyle,
    ...(isActive && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle,
      },
    }),
  }

  return ({
    container: {
      color: 'text.secondary',
      '&:hover': {
        textDecoration: 'none',
      }
    },
    listItemButton: {
      ...listItemButtonStyle,
      padding: theme.spacing(1, 0, 0.5, 2),
      gap: 2,
    },
    listItemButtonCollapsed: {
      ...listItemButtonStyle,
      flexDirection: 'column',
      padding: theme.spacing(1, 0, 0.5, 0),
    },
    icon: {
      marginRight: 0,
      marginBottom: .5,
      width: ICON.NAV_ITEM_MINI,
      height: ICON.NAV_ITEM_MINI,
    },
    text: {
      width: isCollapsed ? 72 : '100%',
      fontSize: isCollapsed ? 10 : 12,
      lineHeight: '16px',
      textAlign: isCollapsed ? 'center' : 'left',
      ...(isActive && {
        fontWeight: 'fontWeightMedium',
      }),
    }
  })
}

export default NavSectionItemStyle
