import base64Conversor from "../../utils/base64Conversor";
import { generateUUID } from "../../utils/uuidGenerator";
import { OrganizationApiInterface } from "./interfaces/OrganizationApiInterface";
import { OrganizationZoneApiInterface } from "./interfaces/OrganizationZoneApiInterface";
import OrganizationContactPayloadModel from "./model/OrganizationContactPayloadModel"
import OrganizationModel from "./model/OrganizationModel"
import OrganizationPayloadModel from "./model/OrganizationPayloadModel"
import OrganizationZoneModel from "./model/OrganizationZoneModel";

function adaptContactFormValuesToPayload(payload: any): OrganizationContactPayloadModel {
  return new OrganizationContactPayloadModel(
    payload.contactName,
    payload.contactSurname,
    `${payload.contactPhonePreffix} ${payload.contactPhoneNumber}`,
    payload.contactEmail,
  )
}

export function adaptOrganizationZoneResponse(response: OrganizationZoneApiInterface | OrganizationZoneModel): OrganizationZoneModel {
  return new OrganizationZoneModel(
    response.sort,
    response.name,
    response.uuid ?? generateUUID(),
  )
}
const isConversionNeeded = (file: any) => file instanceof File;
export async function adaptOrganizationFormValuesToPayload(payload: any, uuid: boolean): Promise<OrganizationPayloadModel> {
  return new OrganizationPayloadModel(
    payload.name,
    payload.description,
    payload.industry,
    adaptContactFormValuesToPayload(payload),
    payload.zones.map((zone: OrganizationZoneModel) => adaptOrganizationZoneResponse(zone)),
    payload.permissions,
    isConversionNeeded(payload.logo) ? await base64Conversor(payload.logo) : payload.logo,
    uuid ? generateUUID() : undefined,
  )
}

export function adaptOrganizationResponse(response: OrganizationApiInterface): OrganizationModel {
  const [prefix, number] = response.contact.phone.split(' ')

  return new OrganizationModel(
    response.uuid,
    response.name,
    response.description,
    response.sector,
    response.contact.name,
    response.contact.lastName,
    prefix,
    number,
    response.contact.email,
    response.zones.map((zone: any) => adaptOrganizationZoneResponse(zone)),
    response.permissions,
    response.logo,
  )
}
