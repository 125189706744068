import { useCallback, useReducer, useState } from 'react'
import { useLocales } from '../locales'
import TaxonomyTagGroupModel from '../services/tagGroups/model/TaxonomyTagGroupModel';

const ERRORS = {
  missingField: 'common:form:error-missing-field',
}

const makeValidations = (translate: any) => ({
  name: (value: any) => !Boolean(value) ? translate(ERRORS.missingField) : '',
  tags: (value: any) => value.length === 0 ? translate(ERRORS.missingField) : '',
})

function reducer(state: { values: any; errors: any }, action: { type: any; fieldName: any; payload: any }) {
  switch (action.type) {
    case 'change-field-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: action.payload,
        },
      }

    case 'add-tag-value':
      const tagExist = state.values.tags.some((item: TaxonomyTagGroupModel) => item.tagUUID === action.payload.tagUUID)

      if (tagExist) {
        return state
      }

      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: [...state.values.tags, action.payload],
        },
      }

    case 'remove-tag-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: state.values.tags.filter((tag: TaxonomyTagGroupModel) => tag !== action.payload),
        },
      }

    case 'change-fields-values':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      }

    case 'update-error':
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.payload },
      }

    case 'update-errors':
      return {
        ...state,
        errors: { ...action.payload },
      }

    default:
      return { ...state }
  }
}

function createInitialState() {
  return {
    values: {
      name: '',
      tags: [],
    },
    errors: {
      name: '',
      tags: '',
    },
  }
}

function useTagGroupForm({ successCallback, initialState = null }: any) {
  const { translate } = useLocales()

  const [form, dispatchForm] = useReducer(
    reducer,
    initialState ?? createInitialState(),
  )

  const validations: any = makeValidations(translate)

  const [shouldValidateForm, setShouldValidateForm] = useState(false)

  const activateFormValidation = () => {
    setShouldValidateForm(true)
  }

  const changeFieldValue = (event: { target: { name: string; value: string } }) => {
    dispatchForm({
      type: 'change-field-value',
      fieldName: event.target.name,
      payload: event.target.value,
    })
  }

  const addTagValue = (taxonomyTagGroup: TaxonomyTagGroupModel[]) => {
    dispatchForm({
      type: 'add-tag-value',
      fieldName: 'tags',
      payload: taxonomyTagGroup,
    })
  }

  const removeTagValue = (tag: string) => {
    dispatchForm({
      type: 'remove-tag-value',
      fieldName: 'tags',
      payload: tag,
    })
  }

  const changeFieldsValues = useCallback((values: any) => {
    return dispatchForm({
      type: 'change-fields-values',
      payload: values,
      fieldName: undefined
    })
  }, [])

  const validateFieldValue = useCallback((event: { target: { name: string; value: string } }) => {
      if (!shouldValidateForm) return

      const name = event.target.name
      const value = event.target.value
      const result = validations[name](value)

      dispatchForm({
        type: 'update-error',
        fieldName: name,
        payload: result,
      })
    },
    [validations, shouldValidateForm],
  )

  const validate = useCallback(() => {
    const errors = { ...form.errors }

    errors.name = validations.name(
      form.values.name,
    )

    errors.tags = validations.tags(
      form.values.tags,
    )

    dispatchForm({
      type: 'update-errors',
      payload: errors,
      fieldName: undefined
    })

    return Object.values(errors).every(error => error === '')
  }, [validations, form.values, form.errors])

  const submit = useCallback((event: { preventDefault: () => void }) => {
      event.preventDefault()

      if (!shouldValidateForm) {
        activateFormValidation()
      }

      const isFormValid = validate()

      if (isFormValid) {
        successCallback(form.values)
      }
    },
    [shouldValidateForm, validate, form.values, successCallback],
  )

  return {
    values: form.values,
    errors: form.errors,
    changeFieldValue,
    changeFieldsValues,
    addTagValue,
    removeTagValue,
    validateFieldValue,
    validate,
    submit,
  }
}

export default useTagGroupForm
export { createInitialState }
