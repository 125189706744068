class AnonymousAttractionRateModel {
  constructor(
    readonly current: {
      external: number,
      internal: number,
    },
    readonly previous: {
      external: number,
      internal: number,
    },
    readonly variation: number,
  ) {}
}

export default AnonymousAttractionRateModel
