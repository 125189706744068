const style = {
  container: {
    pl: 3,
    pr: 3,
  },
  title: {
    mb: 5,
  },
  sectionTitleContainer: {
    pt: 2,
  },
  sectionTitle: {
    mb: 2,
  },
  sectionSubtitle: {
    color: 'text.secondary',
  },
  formContainer: {
    p: 3,
  },
  formWrapper: {
    display: 'flex',
    gap: '1rem',
  },
  inputField: {
    mb: 1,
  },
  selectField: {
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
  },
  autocompleteSelector: {
    minWidth: '8rem',
  },
  tagChipContainer: {
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap"
  }
}

export default style
