import TaxonomyTagModel from "../../taxonomies/model/TaxonomyTagModel";
import { TagGroupModelInterface } from "../interfaces/TagGroupModelInterface";

export default class TagGroupModel implements TagGroupModelInterface {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly organizationUUID: string,
    readonly taxonomyTags: TaxonomyTagModel[],
  ) {}
}
