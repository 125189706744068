import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Box, Stack, Drawer, Typography } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import Logo from '../../../components/logo'
import Scrollbar from '../../../components/scrollbar'
import NavToggleButton from '../NavToggleButton/NavToggleButton'
import NavSectionVertical from '../NavSectionVertical/NavSectionVertical'
import style from './NavVerticalStyle'
import useOrganization from '../../../services/organizations/useOrganization'
import { NavigationSectionType } from '../../../services/common/types/NavigationSectionType'

export default function NavVertical({
  navLinks,
  openNav,
  onCloseNav,
} : {
  navLinks: NavigationSectionType[],
  openNav: boolean,
  onCloseNav: () => void,
}) {
  const { pathname } = useLocation()
  const isDesktop = useResponsive('up', 'lg')
  const { organizationId } = useParams()
  const { data: organization } = useOrganization(organizationId!) as any

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={style.contentContainer}
    >
      <Stack sx={style.logoContainer}>
        <Logo />
      </Stack>

      {organization !== undefined ? (
        <Stack sx={style.titleContainer}>
          <Typography variant="h4">{organization.name}</Typography>
        </Stack>
      ) : null}

      <NavSectionVertical data={navLinks} />
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={style.container}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: style.desktopDrawer,
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: style.collapsedDrawer,
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
