import { useState } from 'react'
import { isSameDay, isSameMonth, getYear, isBefore, isAfter } from 'date-fns'

import { fDate } from '../../utils/formatTime'

const INITIAL_STATE_ERRORS = {
  endDateIsBeforeStartdate: false,
  endDateIsAfterToday: false,
  startDateIsNotSelected: false,
  startDateIsAfterToday: false,
}

const INITIAL_DATES_STATE = {
  startDate: null,
  endDate: null,
}

export default function useDateRangePicker(start, end) {
  const [isOpen, setOpen] = useState(false)

  const [dates, setDates] = useState({ startDate: start, endDate: end })

  const [errors, setErrors] = useState(INITIAL_STATE_ERRORS)

  const close = () => {
    setOpen(false)
    setErrors(INITIAL_STATE_ERRORS)
  }

  const checkAndUpdateErrors = ({ start, end }) => {
    const updatedErrors = { ...INITIAL_STATE_ERRORS }

    if (!start) {
      updatedErrors.startDateIsNotSelected = true
    } else if (end) {
      const isBeforeStartDate = isBefore(new Date(end), new Date(start))
      const isEndAfterToday = isAfter(new Date(end), new Date())
      updatedErrors.endDateIsBeforeStartdate = isBeforeStartDate
      updatedErrors.endDateIsAfterToday = isEndAfterToday
    } else {
      const isStartAfterToday = isAfter(new Date(start), new Date())
      updatedErrors.startDateIsAfterToday = isStartAfterToday
    }
    setErrors(updatedErrors)
    const errorsCount = Object.values(updatedErrors).filter(error => error)
    return errorsCount > 0
  }

  const currentYear = new Date().getFullYear()

  const startDateYear = dates.startDate ? getYear(dates.startDate) : null

  const endDateYear = dates.endDate ? getYear(dates.endDate) : null

  const isCurrentYear =
    currentYear === startDateYear && currentYear === endDateYear

  const isSameDays =
    dates.startDate && dates.endDate
      ? isSameDay(new Date(dates.startDate), new Date(dates.endDate))
      : false

  const isSameMonths =
    dates.startDate && dates.endDate
      ? isSameMonth(new Date(dates.startDate), new Date(dates.endDate))
      : false

  const standardLabel = `${fDate(dates.startDate)} - ${fDate(dates.endDate)}`

  const getShortLabel = () => {
    if (isCurrentYear) {
      if (isSameMonths) {
        if (isSameDays) {
          return fDate(dates.endDate, 'dd MMM yy')
        }
        return `${fDate(dates.startDate, 'dd')} - ${fDate(
          dates.endDate,
          'dd MMM yy',
        )}`
      }
      return `${fDate(dates.startDate, 'dd MMM')} - ${fDate(
        dates.endDate,
        'dd MMM yy',
      )}`
    }
    return `${fDate(dates.startDate, 'dd MMM yy')} - ${fDate(
      dates.endDate,
      'dd MMM yy',
    )}`
  }

  const onChangeStartDate = newValue => {
    setDates(dates => ({ ...dates, startDate: newValue }))
  }

  const onChangeEndDate = newValue => {
    if (checkAndUpdateErrors(newValue)) {
      setDates(dates => ({ ...dates, endDate: null }))
    }
    setDates(dates => ({ ...dates, endDate: newValue }))
  }

  const reset = () => {
    setDates(INITIAL_DATES_STATE)
  }

  const checkHasErrors = () => Object.values(errors).some(error => error)

  return {
    startDate: dates.startDate,
    endDate: dates.endDate,
    updateDates: setDates,

    onChangeStartDate,
    onChangeEndDate,

    isOpen,
    open: () => setOpen(true),
    close,
    reset,

    isSelected: !!dates.startDate && !!dates.endDate,
    hasErrors: checkHasErrors(),
    errors,
    checkAndUpdateErrors,

    label: standardLabel || '',
    shortLabel: getShortLabel() || '',
  }
}
