import PropTypes from 'prop-types'
// @mui
import { useTheme } from '@mui/material/styles'
import { fNumber } from '../../../utils/formatNumber'
import { Card, Typography, Stack } from '@mui/material'
import { useLocales } from '../../../locales'
// ----------------------------------------------------------------------

EcommerceCurrentBalance.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  icon: PropTypes.object,
  anonymous: PropTypes.number,
  recurrents: PropTypes.number,
  registers: PropTypes.number,
  total: PropTypes.number,
}

export default function EcommerceCurrentBalance({
  title,
  anonymous,
  icon,
  recurrents,
  registers,
  total,
  sx,
  ...other
}) {
  const theme = useTheme()
  const { translate } = useLocales()
  return (
    <Card sx={{ p: 3, ...sx }} {...other}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        {icon}
      </Stack>

      {other.children ? (
        other.children
      ) : (
        <Stack spacing={2}>
          <Typography variant="h3">
            {(title === translate('kpis:sell-out') ? '€ ' : '') +
              fNumber(total)}
          </Typography>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: theme.palette.data.new }}>
              {translate('users:new-users')}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.data.new }}>
              {fNumber(anonymous)} {title === 'Sell-Out' ? '€ ' : ''}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="body2"
              sx={{ color: theme.palette.data.recurrent }}
            >
              {translate('users:recurrent')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.data.recurrent }}
            >
              {fNumber(recurrents)} {title === 'Sell-Out' ? '€ ' : ''}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="body2"
              sx={{ color: theme.palette.data.registered }}
            >
              {translate('users:registered-users')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.data.registered }}
            >
              {fNumber(registers)} {title === 'Sell-Out' ? '€ ' : ''}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Card>
  )
}
