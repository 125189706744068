import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useLocales } from '../../locales'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import style from './OrganizationSectionFormStyle'
import { ChangeEvent } from 'react'
import OrganizationZoneModel from '../../services/organizations/model/OrganizationZoneModel'

const INITIAL_ZONE = 0
const TOTAL_ZONES = 3

export default function OrganizationZonesForm({
  form,
 } : {
  form: any,
 }) {
  const { translate } = useLocales()

  const changeZone = (
    index: number,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    oldZone: OrganizationZoneModel | undefined
  ): void => {
    form.addZoneValue(
      new OrganizationZoneModel(
        index,
        event.target.value,
        oldZone?.uuid
      )
    )
  }

  const getZones = (): JSX.Element => {
    const zones: JSX.Element[] = []

    for (let i = INITIAL_ZONE; i < TOTAL_ZONES; i++) {
      const oldZone = form.values?.zones?.find((zone: { sort: number }) => zone.sort === i)

      zones.push(
        <TextField
          key={`zone-${i}`}
          fullWidth
          variant="outlined"
          name={`zone-${i}`}
          label={`${translate('new:organization:zones:form:label')} ${i + 1}`}
          value={oldZone !== undefined ? oldZone.name : ''}
          onChange={(event) => changeZone(i, event, oldZone)}
          error={form.errors.zones !== ''}
          helperText={form.errors.zones}
        />
      )
    }

    return <>{zones}</>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('new:organization:zones:title')}`}
          subtitle={`${translate('new:organization:zones:description')}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={style.card}>
          <form>
            <FormControl sx={style.form}>
              {getZones()}
            </FormControl>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}
