import axios from '../../utils/axios'
import { HTTPFailureStatusCodes } from '../Errors'
import { FetchUsersError, InvalidRoleWithAssignedOrganizationError, UserAlreadyExistsError, UsersGenericError } from './Error'
import {
  adaptUserFormValuesToRequestPayload,
  adaptUsersResponse,
} from './adapters'
import UserFormModel from './model/UserFormModel'
import UserInfoModel from './model/UserInfoModel'
import UserModel from './model/UserModel'
import UserPayloadModel from './model/UserPayloadModel'

export async function createNewUser(payload: UserPayloadModel): Promise<void> {
  const endpoint = '/api/v1/users'

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.data.name === UserAlreadyExistsError.name) {
      throw new UserAlreadyExistsError(endpoint)
    }

    if (error.response.data.name === InvalidRoleWithAssignedOrganizationError.name) {
      throw new InvalidRoleWithAssignedOrganizationError(endpoint)
    }

    throw new UsersGenericError(endpoint)
  }
}

export async function updateUserById(id: string, formValues: UserFormModel): Promise<void> {
  const endpoint = `/api/v1/users/${id}`

  try {
    await axios.put(endpoint, adaptUserFormValuesToRequestPayload(formValues))
    return Promise.resolve()
  } catch (error) {
    if (error.response.data.name === InvalidRoleWithAssignedOrganizationError.name) {
      throw new InvalidRoleWithAssignedOrganizationError(endpoint)
    }

    throw new UsersGenericError(endpoint)
  }
}

export async function fetchUserById(id: string): Promise<UserInfoModel> {
  const endpoint = `/api/v1/users/${id}`

  try {
    const response = await axios.get(endpoint)

    return response.data as UserInfoModel
  } catch (error) {
    throw new FetchUsersError(endpoint)
  }
}

export async function fetch(organizationId: string | null): Promise<UserModel[]> {
  const endpoint = '/api/v1/users'

  try {
    const response = await axios.get(endpoint, {
      params: {
        organizationId: organizationId,
      },
    })

    return adaptUsersResponse(response.data)
  } catch (error) {
    throw new FetchUsersError(endpoint)
  }
}

export async function resetPassword(email: string) {
  const endpoint = '/api/v1/users/reset-password'

  try {
    const response = await axios.post(endpoint, { email })

    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export async function confirmPassword(token: string, password: string) {
  const endpoint = '/api/v1/users'

  try {
    const response = await axios.patch(
      endpoint,
      { password },
      { headers: { Authorization: `Bearer ${token}` } },
    )

    return response.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export async function update(userId: string, data: UserModel) {
  const endpoint = `/api/v1/users/${userId}`

  try {
    const response = await axios.patch(endpoint, data)
    return response.data
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new Error('User does not exists')
      case HTTPFailureStatusCodes.forbidden:
        throw new Error('Forbidden')
      default:
        throw error
    }
  }
}

export async function remove(userId: string) {
  try {
    const response = await axios.delete(`/api/v1/users/${userId}`)
    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new Error('User does not exists')
      case HTTPFailureStatusCodes.forbidden:
        throw new Error('Forbidden')
      default:
        throw error
    }
  }
}

export async function fetchUserInfo() {
  const endpoint = '/api/v1/users/info'

  try {
    const response = await axios.get(endpoint)
    return response.data
  } catch (error) {
    throw new FetchUsersError(endpoint)
  }
}
