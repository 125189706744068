import { Zone } from "../services/common/interface/ZonesInterface";

export const ZONES: Record<string, Zone> = {
  ZONE_CHECKOUT: {
    name: 'zone_checkout',
    value: 0,
  },
  ZONE_2: {
    name: 'zone_2',
    value: 1,
  },
  ZONE_3: {
    name: 'zone_3',
    value: 2,
  },
}
