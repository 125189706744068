import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocales } from '../../locales'
import useOrganizationForm from '../../hooks/useOrganizationForm'
import useOrganization from '../../services/organizations/useOrganization'
import useOrganizationMutation from '../../services/organizations/useOrganizationMutation'
import { adaptOrganizationResponse } from '../../services/organizations/adapters'
import OrganizationForm from '../../sections/dashboard/organizations/OrganizationForm'
import { Typography } from '@mui/material'
import ContainerBase from '../../components/container/ContainerBase/ContainerBase'
import SnackbarAlert from '../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { Trans } from 'react-i18next'
import { PermissionsType } from '../../services/permissions/types/PermissionType'
import PermissionsValue from '../../services/common/enum/PermissionsValues'

export default function EditOrganization() {
  const { translate } = useLocales()
  const { organizationId } = useParams()
  const organization = useOrganization(organizationId || '') as any
  const mutation = useOrganizationMutation()
  const { changeFieldsValues, ...form } = useOrganizationForm({
    successCallback: mutation.update(organization.data?.uuid),
  })

  useEffect(() => {
    if (organization.data !== undefined) {
      changeFieldsValues(adaptOrganizationResponse(organization.data))
    }
  }, [organization.data, changeFieldsValues])

  const modulesPermissions: PermissionsType[] = [
    {
      label: `${translate('permission:PERM_ANONYMOUS')}`,
      value: PermissionsValue.ANONYMOUS_PERMISSION,
      checked: form.values?.permissions?.PERM_ANONYMOUS ?? false,
      disabled: true,
    },
    {
      label: `${translate('permission:PERM_REGISTERED')}`,
      value: PermissionsValue.REGISTERED_PERMISSION,
      checked: form.values?.permissions?.PERM_REGISTERED ?? false
    },
  ]

  return (
    <ContainerBase>
      <Typography variant="h4">
        <Trans i18nKey="new-organization:title:edit" />
      </Typography>
      <OrganizationForm
        form={form}
        modulesPermissions={modulesPermissions}
        submitButtonLabel={`${translate('new-organization:submit-label:edit')}`}
        editing
      />
      <SnackbarAlert
        isOpen={mutation.loading}
        message={`${translate('edit-organization:submitting-form-info-message')}`}
        type={SnackBarAlertTypes.INFO}
      />

      <SnackbarAlert
        isOpen={mutation.error !== null}
        message={`${translate('edit-organization:submit-error-message')}`}
        onClose={() => mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
      />
    </ContainerBase>
  )
}
