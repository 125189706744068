import { NAV } from "../../../config-global"

const NavVerticalStyle = {
  container: {
    flexShrink: { lg: 0 },
    width: { lg: NAV.W_DASHBOARD },
  },
  desktopDrawer: {
    zIndex: 0,
    width: NAV.W_DASHBOARD,
    bgcolor: 'transparent',
    borderRightStyle: 'dashed',
  },
  collapsedDrawer: {
    width: NAV.W_DASHBOARD,
  },
  contentContainer: {
    height: 1,
    '& .simplebar-content': {
      height: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  logoContainer: {
    pt: 2,
    pb: 3,
    px: 2.5,
    flexShrink: 0,
  },
  titleContainer: {
    px: 2,
    pb: 3,
  },
}

export default NavVerticalStyle
