import TaxonomyTagGroupModel from "./TaxonomyTagGroupModel";

export default class TagGroupSingleModel {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly organizationUUID: string,
    readonly tags: TaxonomyTagGroupModel[],
  ) {}
}
