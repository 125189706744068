import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import useResponsive from '../../hooks/useResponsive'
import { useSettingsContext } from '../../components/settings'
import useUserInfo from '../../services/users/useUserInfo'
import OrzanizationFluxBreadcrumbs from '../../components/breadcrumb/OrzanizationFluxBreadcrumbs/OrzanizationFluxBreadcrumbs'
import style from '../../style/containers/LayoutStyle'
import MainContainer from '../../components/container/Maincontainer/MainContainer'
import NavMini from '../../components/nav-section/NavMini/NavMini'
import NavVertical from '../../components/nav-section/NavVertical/NavVertical'
import ContentLayoutHeader from './ContentLayoutHeader/ContentLayoutHeader'
import { isLayoutMini } from '../../utils/layoutHelper'
import { NavigationSectionType } from '../../services/common/types/NavigationSectionType'

export default function ContentLayout({
  navLinks,
}: {
  navLinks: NavigationSectionType[]
}) {
  const { themeLayout } = useSettingsContext()
  useUserInfo()

  const isDesktop = useResponsive('up', 'lg')
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ContentLayoutHeader onOpenNav={handleOpen} />
      <Box sx={style.container}>
        {isDesktop && isLayoutMini(themeLayout) ? (
          <NavMini navLinks={navLinks} />
        ) : (
          <NavVertical
            navLinks={navLinks}
            openNav={open}
            onCloseNav={handleClose}
          />
        )}

        <MainContainer>
          <Stack sx={style.breadcrumbsContainer}>
            <OrzanizationFluxBreadcrumbs />
          </Stack>
          <Outlet />
        </MainContainer>
      </Box>
    </>
  )
}
