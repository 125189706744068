import TaxonomyTagModel from "../../taxonomies/model/TaxonomyTagModel";

export default class TagGroupCreatePayloadModel {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly organizationUUID: string,
    readonly taxonomyTags: TaxonomyTagModel[],
  ) {}
}
