import axios from '../../utils/axios'
import { TagGroupModelApiInterface } from './interfaces/TagGroupModelApiInterface'
import TagGroupModel from './model/TagGroupModel'
import TagGroupCreatePayloadModel from './model/TagGroupCreatePayloadModel'
import TagGroupSingleModel from './model/TagGroupSingleModel'
import { adaptTagGroupSingleResponse, adaptTagGroupsResponse } from './tagGroupAdapter'
import { FetchTagGroupsError, InvalidCreateTagGroupPayloadError, TagGroupGenericError } from './tagGroupError'
import TagGroupUpdatePayloadModel from './model/TagGroupUpdatePayloadModel'

const TAG_GROUP_ALREADY_EXIST_ERROR = 'TagGroupAlreadyExists';

export async function fetchTagGroups(organizationUUID: string | null): Promise<TagGroupModel[]> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/tag-groups`

  try {
    const response = await axios.get<TagGroupModelApiInterface[]>(endpoint)

    return adaptTagGroupsResponse(response.data)
  } catch (error) {
    // TODO - manage all errors
    throw new FetchTagGroupsError(endpoint)
  }
}

export async function fetchTagGroup(organizationUUID: string, tagGroupUUID: string): Promise<TagGroupSingleModel> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/tag-groups/${tagGroupUUID}`

  if (!Boolean(organizationUUID) || !Boolean(tagGroupUUID)) {
    throw new FetchTagGroupsError(endpoint)
  }

  try {
    const response = await axios.get<TagGroupModelApiInterface>(endpoint)

    return adaptTagGroupSingleResponse(response.data)
  } catch (error) {
    // TODO - manage all errors
    throw new FetchTagGroupsError(endpoint)
  }
}

export async function createTagGroup(organizationUUID: string, payload: TagGroupCreatePayloadModel): Promise<void> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/tag-groups`

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.data.name === TAG_GROUP_ALREADY_EXIST_ERROR) {
      throw new InvalidCreateTagGroupPayloadError(endpoint)
    }

    throw new TagGroupGenericError(endpoint)
  }
}

export async function updateTagGroup(
  organizationUUID: string,
  tagGroupUUID: string,
  payload: TagGroupUpdatePayloadModel,
): Promise<void> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/tag-groups/${tagGroupUUID}`

  try {
    await axios.put(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    throw new TagGroupGenericError(endpoint)
  }
}

export async function removeTagGroup(organizationUUID: string, tagGroupUUID: string): Promise<void> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/tag-groups/${tagGroupUUID}`

  try {
    await axios.delete(endpoint)
  } catch (error) {
    throw new TagGroupGenericError(endpoint)
  }
}
