import { useLocales } from '../../locales'
import OrganizationForm from '../../sections/dashboard/organizations/OrganizationForm'
import useOrganizationForm from '../../hooks/useOrganizationForm'
import useOrganizationMutation from '../../services/organizations/useOrganizationMutation'
import ContainerBase from '../../components/container/ContainerBase/ContainerBase'
import { Typography } from '@mui/material'
import SnackbarAlert from '../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { Trans } from 'react-i18next'
import { PermissionsType } from '../../services/permissions/types/PermissionType'
import { useEffect } from 'react'
import PermissionsValue from '../../services/common/enum/PermissionsValues'

export default function NewOrganizationPage() {
  const mutation = useOrganizationMutation()
  const form = useOrganizationForm({ successCallback: mutation.create })
  const { translate } = useLocales()

  const modulesPermissions: PermissionsType[] = [
    {
      label: `${translate('permission:PERM_ANONYMOUS')}`,
      value: PermissionsValue.ANONYMOUS_PERMISSION,
      checked: true,
      disabled: true,
    },
    {
      label: `${translate('permission:PERM_REGISTERED')}`,
      value: PermissionsValue.REGISTERED_PERMISSION,
    },
  ]

  useEffect(() => {
    form.setDefaultPermissions(modulesPermissions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <ContainerBase>
      <Typography variant="h4">
        <Trans i18nKey="new-organization:title" />
      </Typography>
      <OrganizationForm
        form={form}
        modulesPermissions={modulesPermissions}
        submitButtonLabel={`${translate('new-organization:submit-label')}`}
      />
      <SnackbarAlert
        isOpen={mutation.loading}
        message={`${translate('new-organization:submitting-form-info-message')}`}
        type={SnackBarAlertTypes.INFO}
      />
      <SnackbarAlert
        isOpen={mutation.error !== null}
        message={`${translate('new-organization:submit-error-message')}`}
        onClose={() => mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
      />
    </ContainerBase>
  )
}
