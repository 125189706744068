export const emailValidator = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const phoneNumberValidator = (number: string): boolean => {
  const MAX_PHONE_LENGTH = 15
  const numberRegex = /^[0-9]+$/;

  return numberRegex.test(number) && number.length <= MAX_PHONE_LENGTH
}
