const VariantStyle = {
  container: {
    pt: 0,
    pb: 0,
    pl: 1,
    pr: 1,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconIncrement: {
    fontSize: '20px',
    color: '#479866',
  },
  iconDecrement: {
    fontSize: '20px',
    color: '#D06D68',
  },
}

export default VariantStyle
