import { useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Stack, Typography, alpha } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { IconButtonAnimate } from '../../../../components/animate'
import MenuPopover from '../../../../components/menu-popover/MenuPopover'
import Iconify from '../../../../components/iconify'
import { useLocales } from '../../../../locales'

ActionPopover.propTypes = {
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  goToView: PropTypes.func,
}

ActionPopover.defaultProps = {
  handleDelete: undefined,
  handleEdit: undefined,
  goToView: undefined,
}

export default function ActionPopover({ handleDelete, handleEdit, goToView }) {
  const [openPopover, setOpenPopover] = useState(null)
  const { translate } = useLocales()

  const handleOpenPopover = event => {
    event.preventDefault()
    event.stopPropagation()
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <Stack direction="row" justifyContent="flex-end">
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[700], 0.1),
            },
          }),
        }}
      >
        <MoreVertIcon sx={{ color: 'action.active' }} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-center"
        sx={{ width: 200, p: 0 }}
      >
        {handleEdit !== undefined ? (
          <MenuItem sx={{ m: 1 }} onClick={handleEdit}>
            <Iconify icon="solar:pen-bold" color={'other.facebook'} />
            <Typography>{translate('action:edit')}</Typography>
          </MenuItem>
        ) : null}

        {goToView !== undefined ? (
          <MenuItem sx={{ m: 1 }} onClick={goToView}>
            <Iconify icon="solar:eye-bold" color={'other.facebook'} />
            <Typography>{translate('action:view')}</Typography>
          </MenuItem>
        ) : null}

        {handleDelete !== undefined ? (
          <MenuItem sx={{ m: 1 }} onClick={handleDelete}>
            <Iconify
              icon="solar:trash-bin-trash-bold"
              color={'other.facebook'}
            />
            <Typography>{translate('action:delete')}</Typography>
          </MenuItem>
        ) : null}
      </MenuPopover>
    </Stack>
  )
}
