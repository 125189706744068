const EmptyCardStyle = {
  container: {
    width: '100%',
  },
  content: {
    width: '100%',
    p: 3,
  },
  cardHeader: {
    p: 0,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  }
}

export default EmptyCardStyle
