import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useAuthContext } from '../../auth/useAuthContext'
import Iconify from '../../components/iconify'
import FormProvider, { RHFTextField } from '../../components/hook-form'
import { PATH_PASSWORD } from '../../routes/paths'
import { useLocales } from '../../locales'

export default function AuthLoginForm() {
  const { login } = useAuthContext()
  const { translate } = useLocales()

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(translate('login:form:email:error:required'))
      .email(translate('login:form:email:error:invalid')),
    password: Yup.string().required(
      translate('login:form:password:error:required'),
    ),
  })

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  })

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods

  const onSubmit = async data => {
    try {
      await login(data.email, data.password)
    } catch (error) {
      setError('afterSubmit', {
        ...error,
        message: error.message || error,
      })
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="email"
          label={translate('login:form:email:label')}
        />

        <RHFTextField
          name="password"
          label={translate('login:form:password:label')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          variant="body2"
          color="inherit"
          underline="always"
          to={PATH_PASSWORD.reset}
        >
          {translate('login:form:forgot-password')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
        sx={{
          bgcolor: 'text.primary',
          color: theme =>
            theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
          '&:hover': {
            bgcolor: 'text.primary',
            color: theme =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
          },
        }}
      >
        {translate('login:form:submit-label')}
      </LoadingButton>
    </FormProvider>
  )
}
