import { Grid, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import TagGroupForm from '../../../sections/dashboard/tagGroups/TagGroupForm/TagGroupForm'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import useTagGroupForm from '../../../hooks/useTagGroupForm'
import useTagFormMutation from '../../../services/tagGroups/useTagGroupMutation'
import useTagGroup from '../../../services/tagGroups/useTagGroup'
import { useEffect } from 'react'

export default function EditTagGroup() {
  const { translate } = useLocales()
  const mutation = useTagFormMutation() as any
  const { organizationId, tagGroupId } = useParams<string>()

  const tagGroup = useTagGroup(organizationId!, tagGroupId!) as any
  const { changeFieldsValues, ...form } = useTagGroupForm({
    successCallback: mutation.update(organizationId!, tagGroupId!),
  })

  useEffect(() => {
    if (tagGroup.data !== undefined) {
      changeFieldsValues(tagGroup.data)
    }
  }, [tagGroup.data, changeFieldsValues])

  return (
    <ContainerBase>
      <Typography variant="h4">
        <Trans i18nKey="edit:tag:group:title" />
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <TagGroupForm organizationUUID={organizationId!} form={form} submitButtonLabel={`${translate('create:tag:group:button')}`} />
        </Grid>
      </Grid>
    </ContainerBase>
  )
}
