import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import Iconify from '../iconify'
import useLocales from '../../locales/useLocales'

ModalConfirmAction.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  actionText: PropTypes.string,
  opened: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default function ModalConfirmAction({
  title,
  text,
  actionText,
  opened,
  onConfirm,
  onCancel,
}) {
  const { translate } = useLocales()
  return (
    <Stack>
      <Dialog
        open={opened}
        onClose={onCancel}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={e => e.stopPropagation()}
      >
        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
          sx={{ p: 3 }}
        >
          <DialogTitle sx={{ p: 0 }}>{title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{ color: 'text.primary' }}
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>
        <DialogContent sx={{ pt: 1.5 }}>
          <DialogContentText variant="body1" color="default" sx={{ mb: 0.5 }}>
            {text}
          </DialogContentText>
        </DialogContent>
        <Stack
          direction="row"
          justifyContent={'flex-end'}
          gap={2}
          sx={{ p: 3 }}
        >
          <Button variant="contained" color="error" onClick={onConfirm}>
            {actionText}
          </Button>
          <Button
            variant="outlined"
            sx={{ color: 'grey.800', borderColor: 'grey.500' }}
            onClick={onCancel}
          >
            {translate('action:cancel')}
          </Button>
        </Stack>
      </Dialog>
    </Stack>
  )
}
