import { Box, Typography, useTheme } from '@mui/material'
import Chart from 'react-apexcharts'
import style from './HeatmapChartStyle'

export default function HeatmapChart({
  maxValue = 10000,
  data,
  chartHeight = 500,
  hidden = false,
}: {
  maxValue: number
  data: any
  chartHeight?: number
  hidden?: boolean
}) {
  const theme = useTheme()

  const colorRanges = [
    { from: 0, to: 0, color: '#FFFFFF' },
    { from: 1, to: maxValue * 0.3, color: '#FFCDD2' },
    { from: maxValue * 0.3, to: maxValue * 0.5, color: '#EF9A9A' },
    { from: maxValue * 0.5, to: maxValue * 0.7, color: '#E57373' },
    { from: maxValue * 0.7, to: maxValue * 0.9, color: '#EF5350' },
    { from: maxValue * 0.9, to: maxValue, color: '#D32F2F' },
  ]

  const options = {
    chart: {
      id: 'column-chart',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [theme.palette.grey[800]],
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      heatmap: {
        useFillColorAsStroke: true,
        distributed: true,
        shadeIntensity: 1,
        colorScale: {
          ranges: colorRanges,
        },
      },
    },
    grid: {
      padding: {
        top: -30,
        right: -30,
        bottom: -10,
        left: 0,
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: -10,
      },
    },
  }

  if (hidden) {
    return <></>
  }

  return (
    <>
      {/* @ts-ignore */}
      <Chart
        type="heatmap"
        options={options}
        series={data}
        height={chartHeight}
        sx={style.chart}
      />
      <Box sx={style.legendContainer}>
        <Box sx={style.legendWrapper}>
          <Typography variant="body2" color="textSecondary" sx={style.legendLeftText}>
            {0}
          </Typography>
          <Box
            sx={style.legendGraphic}
          />
          <Typography variant="body2" color="textSecondary" sx={style.legendRightText}>
            {maxValue}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
