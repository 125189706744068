const HeatmapWeekSelectorStyle = {
  container: {
    width: '100%',
    p: 3,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#6998ff'
  },
  titleContainer: {
    display: 'flex',
    gap: 1,
  }
}

export default HeatmapWeekSelectorStyle
