import { OrganizationModelInterface } from '../interfaces/OrganizationModelInterface'
import OrganizationPermissionsType from '../types/OrganizationPermissionsType';
import OrganizationZoneModel from './OrganizationZoneModel';

export default class OrganizationModel implements OrganizationModelInterface {
  constructor(
    readonly uuid: string,
    readonly name: string,
    readonly description: string,
    readonly industry: string,
    readonly contactName: string,
    readonly contactSurname: string,
    readonly contactPhonePreffix: string,
    readonly contactPhoneNumber: string,
    readonly contactEmail: string,
    readonly zones: OrganizationZoneModel[],
    readonly permissions: OrganizationPermissionsType,
    readonly logo: string,
  ) {}
}
