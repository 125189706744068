export default function calculateCampaignStatusColor(status) {
  if (!status) return

  switch (status) {
    case 'live':
      return {
        name: 'success',
        backgroundColor: '#22C55E29',
        color: '#118D57',
      }
    case 'programmed':
      return {
        name: 'warning',
        backgroundColor: '#FFAB0029',
        color: '#B76E00',
      }
    case 'paused':
      return {
        name: 'error',
        backgroundColor: '#FF563029',
        color: '#B71D18',
      }
    default: // completed
      return {
        name: 'default',
        backgroundColor: '#919EAB29',
        color: '#637381',
      }
  }
}
