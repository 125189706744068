import Button from '@mui/material/Button'
import style from './SecondaryButtonStyle'

export default function SecondaryButton({
  children,
  onClick
} : {
  children: React.ReactNode,
  onClick: () => void,
}) {
  return (
    <Button
      variant="outlined"
      sx={style.button}
      size="large"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
