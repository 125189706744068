import { Divider, Modal, Stack } from '@mui/material'
import ContentModalStyle from './ContentModalStyle'
import PrimaryButton from '../../button/PrimaryButton/PrimaryButton'
import { useLocales } from '../../../locales'
import SecondaryButton from '../../button/SecondaryButton/SecondaryButton'
import useResponsive from '../../../hooks/useResponsive'

export default function ContentModal({
  isOpen = false,
  children,
  onClickAction,
  onClickCancel,
  isPrimaryButtonDisabled = false,
} : {
  isOpen: boolean,
  children: React.ReactNode,
  onClickAction: () => void,
  onClickCancel: () => void,
  isPrimaryButtonDisabled?: boolean,
}): JSX.Element {
  const { translate } = useLocales()
  const isDesktop = useResponsive('up', 'md')
  const style = ContentModalStyle(isDesktop)

  return (
    <Modal
      open={isOpen}
      onClose={onClickCancel}
    >
      <Stack sx={style.container}>
        <Stack sx={style.content}>
          {children}
        </Stack>
        <Divider/>
        <Stack sx={style.actionBlock}>
          <SecondaryButton
            onClick={onClickCancel}
          >
            {`${translate('common:button:cancel')}`}
          </SecondaryButton>
          <PrimaryButton
            onClick={onClickAction}
            isDisabled={isPrimaryButtonDisabled}
          >
            {`${translate('common:button:accept')}`}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Modal>
  )
}
