import { Box } from "@mui/material";
import style from './CardGridContainerStyle'

export default function CardGridContainer({
  children,
} : {
  children: React.ReactNode,
}) {
  return (
    <Box
      gap={3}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
      }}
      sx={style.container}
    >
      {children}
    </Box>
  )
}
