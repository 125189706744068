import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useLocales } from '../../locales'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import { ORGANIZATION_SECTORS } from '../../sections/dashboard/organizations/OrganizationSectors'
import style from './OrganizationSectionFormStyle'

export default function OrganizationDetailsForm({
  form,
  editing
 } : {
  form: any,
  editing: boolean,
 }) {
  const { translate } = useLocales()

  const getAutocomplete = (): JSX.Element => {
    return (
      <Autocomplete
        sx={style.autocompleteSelector}
        options={ORGANIZATION_SECTORS}
        value={form.values.industry || null}
        onChange={(_event, value) => {
          form.changeFieldValue({
            target: {
              name: 'industry',
              value,
            },
          })
        }}
        onBlur={form.validateFieldValue}
        getOptionLabel={sector => {
          return sector
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={`${translate('new-organization:form:industry-label')}`}
            name="industry"
            value={form.values.industry}
            error={form.errors.industry !== ''}
            helperText={form.errors.industry}
          />
        )}
      />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('new-organization:details-title')}`}
          subtitle={`${translate('new-organization:details-description')}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={style.card}>
          <form>
            <FormControl sx={style.form}>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label={`${translate('new-organization:form:name-label')}`}
                value={form.values.name}
                onChange={form.changeFieldValue}
                onBlur={form.validateFieldValue}
                error={form.errors.name !== ''}
                helperText={form.errors.name}
                disabled={editing}
              />
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label={`${translate('new-organization:form:description-label')}`}
                value={form.values.description}
                onChange={form.changeFieldValue}
                onBlur={form.validateFieldValue}
                error={form.errors.description !== ''}
                helperText={form.errors.description}
              />
              {getAutocomplete()}
            </FormControl>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}
