import {
  Stack,
} from '@mui/material'
import style from './ContainerBaseStyle'

export default function ContainerBase({
  children,
} : {
  children: React.ReactNode,
}) {
  return (
    <Stack sx={style.container} gap={6}>
      {children}
    </Stack>
  )
}
