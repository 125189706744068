import { Theme } from "@mui/material"
import { NAV } from "../../../config-global"
import { hideScrollbarX } from "../../../utils/cssStyles"

const NavMiniStyle = (theme: Theme) => {
  return ({
    container: {
      flexShrink: { lg: 0 },
      width: { lg: NAV.W_DASHBOARD_MINI },
    },
    blockContainer: {
      pb: 2,
      height: 1,
      position: 'fixed',
      width: NAV.W_DASHBOARD_MINI,
      borderRight: `dashed 1px ${theme.palette.divider}`,
      ...hideScrollbarX,
    },
    logoContainer: {
      mx: 'auto',
      my: 2,
    }
  })
}

export default NavMiniStyle
