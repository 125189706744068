import { Grid, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import TagGroupForm from '../../../sections/dashboard/tagGroups/TagGroupForm/TagGroupForm'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import useTagGroupForm from '../../../hooks/useTagGroupForm'
import useTagFormMutation from '../../../services/tagGroups/useTagGroupMutation'
import { InvalidCreateTagGroupPayloadError } from '../../../services/tagGroups/tagGroupError'

export default function NewTagGroup() {
  const { translate } = useLocales()
  const mutation = useTagFormMutation() as any
  const { organizationId } = useParams<string>()
  const form = useTagGroupForm({ successCallback: mutation.create(organizationId!) })

  const getErrorMessage = (): string => {
    if (!mutation.error) {
      return ''
    }

    if (mutation.error instanceof InvalidCreateTagGroupPayloadError) {
      return `${translate('create:tag:group:error:already:exist')}`
    }

    return `${translate('create:tag:group:error:generic')}`
  }

  return (
    <ContainerBase>
      <Typography variant="h4">
        <Trans i18nKey="create:tag:group:title" />
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <TagGroupForm organizationUUID={organizationId!} form={form} submitButtonLabel={`${translate('create:tag:group:button')}`} />
        </Grid>
      </Grid>
      <SnackbarAlert
        isOpen={mutation.error !== null}
        message={getErrorMessage()}
        onClose={() => mutation.clearError()}
        type={SnackBarAlertTypes.ERROR}
      />
    </ContainerBase>
  )
}
