type NodeInfo = {
  uuid: string,
  name: string,
}

export default class NodeInfoModel {
  constructor(
    public readonly uuid: string,
    public readonly status: boolean,
    public readonly foreignID: string,
    public readonly populated: boolean,
    public readonly organization?: NodeInfo,
    public readonly location?: NodeInfo,
    public readonly zone?: NodeInfo,
  ) {}
}
