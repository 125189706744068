const OrganizationSectionFormStyle = {
  card: {
    p: 3,
  },
  form: {
    display: 'flex',
    gap: 2
  },
  autocompleteSelector: {
    minWidth: '8rem',
  },
  imageSectionContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    alignItems: {
      xs: 'normal',
      sm: 'center'
    },
    gap: 3,
  },
  previewImage: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    maxWidth: {
      xs: '100%',
      sm: '50%',
      md: '30%',
    }
  },
  imageHelperText: {
  },
  removeImageButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  hiddenInput: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  }
}

export default OrganizationSectionFormStyle
