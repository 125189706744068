import { useEffect, useState, useCallback } from 'react'

import { fetchOrganization, fetchOrganizations } from './service'

function useOrganizationSelector(isOrganizationView: boolean, organizationId: string): object {
  const [state, setState] = useState({
    data: undefined,
    loading: true,
    error: null,
  })

  const getResponse = async () => {
    if(isOrganizationView) {
      return await fetchOrganization(organizationId)
    }
    return await fetchOrganizations()
  }

  const fetchData = useCallback(async () => {
    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await getResponse();

      setState({
        data: response.data,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    refetch: fetchData,
  }
}

export default useOrganizationSelector
