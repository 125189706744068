export class FetchUsersError extends Error {
  constructor(readonly endpoint: string) {
  super(`Error fetching users from endponit ${endpoint}`)
    this.name = 'FetchUsersError'
  }
}

export class InvalidRoleWithAssignedOrganizationError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in user from endpoint ${endpoint}`)
    this.name = 'InvalidRoleWithAssignedOrganizationError'
  }
}

export class UserAlreadyExistsError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in user from endpoint ${endpoint}`)
    this.name = 'UserAlreadyExistsError'
  }
}

export class UsersGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in user from endpoint ${endpoint}`)
    this.name = 'UsersGenericError'
  }
}
