import axios from '../../utils/axios'
import {
  ClientDoesNotExist,
  ForbiddenOrganization,
  HTTPFailureStatusCodes,
} from '../Errors'

import {
  CampaignEndedError,
  CreateCampaignError,
  UpdateCampaignError,
} from './Error'
import campaignsMocks from './mocks'

export async function createCampaign(data: object) {
  try {
    const response = await axios.post('/api/v1/campaigns', data)
    return response.data
  } catch (error) {
    throw new CreateCampaignError(error)
  }
}

export async function updateCampaign(campaignId: string, payload: object) {
  try {
    const url = `/api/v1/campaigns/${campaignId}`
    const response = await axios.patch(url, payload)
    return response.data
  } catch (error) {
    if (error.response.status === 412) {
      throw new CampaignEndedError(error)
    }

    throw new UpdateCampaignError(error)
  }
}

export async function fetchOne(organizationId: string, campaignId: string) {
  try {
    const response = await axios.get(
      `/api/v1/campaigns/${organizationId}/${campaignId}`,
    )

    return response
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}

export async function fetch(organizationId: string) {
  try {
    // const response = await axios.get('/api/v1/campaigns', {
    //   params: {
    //     organizationId: organizationId,
    //   },
    // })

    return campaignsMocks.data
  } catch (error) {
    switch (error.response.status) {
      case HTTPFailureStatusCodes.notFound:
        throw new ClientDoesNotExist()
      case HTTPFailureStatusCodes.forbidden:
        throw new ForbiddenOrganization()
      default:
        throw error
    }
  }
}
