/**
 * Determines if JavaScript can make use of localStorage API.
 * This is needed because incognito mode do not have access to that API.
 * @returns {boolean}
 */
export default function canUseLocalStorage() {
  const key = '__test_localstorage_key'

  try {
    window.localStorage.setItem(key, key)
    window.localStorage.removeItem(key)
    return true
  } catch (error) {
    return false
  }
}
