export class FetchTagGroupsError extends Error {
  constructor(readonly endpoint: string) {
  super(`Error fetching tag groups from endpoint ${endpoint}`)
    this.name = 'FetchTagGroupError'
  }
}

export class InvalidCreateTagGroupPayloadError extends Error {
  constructor(endpoint: string) {
    super(`Error creating new tag group from endpoint ${endpoint}`)
    this.name = 'InvalidNewTagGroupPayloadError'
  }
}

export class TagGroupGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in tag group from endpoint ${endpoint}`)
    this.name = 'TagGroupGenericError'
  }
}
