import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  LinearProgress,
  Box,
} from '@mui/material'

import { fShortenNumber } from '../../../utils/formatNumber'
import { useLocales } from '../../../locales'

BookingBookedRoom.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

export default function BookingBookedRoom({
  title,
  subheader,
  data,
  ...other
}) {
  const { translate } = useLocales()
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ px: 3, my: 5 }}>
        {data?.map(progress => (
          <LinearProgress
            variant="determinate"
            key={progress.status}
            value={progress.value}
            color={
              ((progress.status === translate('graph:frequency:one-visit') ||
                progress.status === '01-10') &&
                'warning') ||
              ((progress.status === translate('graph:frequency:two-visits') ||
                progress.status === '10-30') &&
                'error') ||
              (progress.status === '30-60' && 'secondary') ||
              'info'
            }
            sx={{
              height: 8,
              bgcolor: theme => alpha(theme.palette.grey[500], 0.16),
            }}
          />
        ))}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, pb: 3 }}
      >
        {data?.map(progress => (
          <Stack key={progress.status} alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 1 }}
            >
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: 0.5,
                  bgcolor: 'info.main',
                  ...((progress.status ===
                    translate('graph:frequency:one-visit') ||
                    progress.status === '01-10') && {
                    bgcolor: 'warning.main',
                  }),
                  ...((progress.status ===
                    translate('graph:frequency:two-visits') ||
                    progress.status === '10-30') && { bgcolor: 'error.main' }),
                  ...(progress.status === '30-60' && {
                    bgcolor: 'secondary.main',
                  }),
                }}
              />

              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {progress.status}
              </Typography>
            </Stack>

            <Typography variant="h6">
              {fShortenNumber(progress.quantity)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  )
}
