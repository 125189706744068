export class TaxonomyTagGroupLocationModel {
  constructor(
    readonly uuid: string,
    readonly name: string,
  ) {}
}

export default class TaxonomyTagGroupModel {
  constructor(
    readonly taxonomyUUID: string,
    readonly taxonomyName: string,
    readonly tagUUID: string,
    readonly tagName: string,
    readonly locations: TaxonomyTagGroupLocationModel[],
  ) {}
}
