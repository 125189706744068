import { Stack } from '@mui/material';
import style from './NavSectionCollapsedStyle'
import NavSectionItem from '../NavSectionItem/NavSectionItem';
import { NavigationSectionType } from '../../../services/common/types/NavigationSectionType';

export default function NavSectionCollapsed({
  data,
} : {
  data: NavigationSectionType[],
}): JSX.Element {
  return (
    <Stack
      spacing={0.5}
      sx={style.container}
    >
    {data?.map((menuElements: any) => 
      menuElements.items?.map((item: any) => (
        <NavSectionItem
          key={`link-to-${item.path}`}
          item={item}
          isCollapsed
        />
      ))
    )}
    </Stack>
  )
}
