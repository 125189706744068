import { Card, Chip, MenuItem, Stack, TextField, Typography } from "@mui/material"
import SecondaryButton from "../../../../components/button/SecondaryButton/SecondaryButton"
import PrimaryButton from "../../../../components/button/PrimaryButton/PrimaryButton"
import { useNavigate } from "react-router"
import { useLocales } from "../../../../locales"
import style from './TagGroupFormStyle'
import TaxonomyTagModel from "../../../../services/taxonomies/model/TaxonomyTagModel"
import useTaxonomies from "../../../../services/taxonomies/useTaxonomies"
import TaxonomyModel from "../../../../services/taxonomies/model/TaxonomyModel"
import TaxonomyTagGroupModel from "../../../../services/tagGroups/model/TaxonomyTagGroupModel"

export default function TagGroupForm({
  organizationUUID,
  form,
  submitButtonLabel
} : {
  organizationUUID: string,
  form: any,
  submitButtonLabel: string
}): JSX.Element {
  const navigate = useNavigate()
  const { translate } = useLocales()
  const taxonomies = useTaxonomies(organizationUUID || '') as any

  const getTags = (): JSX.Element => {
    if (form.values?.tags?.length === 0) {
      return <></>
    }

    return (
      <Stack sx={style.tagChipContainer}>
        {form.values.tags.map((
          tag: TaxonomyTagGroupModel,
          index: number,
        ) =>
          <Chip
            key={`${index}-${tag.tagName}`}
            variant="outlined"
            label={tag.tagName}
            onDelete={() => form.removeTagValue(tag)}
          />
        )}
      </Stack>
    )
  }

  const changeField = (taxonomy: TaxonomyModel, tag: TaxonomyTagModel): void => {

    form.addTagValue(new TaxonomyTagGroupModel(
      taxonomy.uuid,
      taxonomy.name,
      tag.uuid,
      tag.value,
      [],
    ))
  }

  const getTaxonomyMenuItems = (taxonomy: TaxonomyModel): JSX.Element => {
    return (
      <Stack>
        {taxonomy.tags.map((
          tag: TaxonomyTagModel,
          index: number,
        ) =>
          <MenuItem
            key={`${index}-${tag.value}`}
            onClick={() => changeField(taxonomy, tag)}
          >
            {tag.value}
          </MenuItem>
        )}
      </Stack>
    )
  }

  const getTaxonomySelectorBlock = (): JSX.Element => {
    if (!taxonomies.data || taxonomies.data.length === 0) {
      return <>
        {translate('tag:group:empty')}
      </>
    }

    return taxonomies.data.map((data: TaxonomyModel, index: number) => {
      return (
        <Stack key={`${index}-${data.name}`} sx={style.tagBlock}>
          <Typography>{data.name}</Typography>
          <TextField
            sx={style.tagTextfield}
            select
            label={data.name}
            name={`selector-${data.name}`}
            placeholder="Seleccionar"
            size="small"
            error={form.errors.tags !== ''}
          >
            {getTaxonomyMenuItems(data)}
          </TextField>
        </Stack>
      )
    })
  }

  return (
    <Stack sx={style.container}>
      <Card sx={style.card}>
        <Stack sx={style.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            label={`${translate('create:taxonomy:form:name')}`}
            value={form.values.name}
            onChange={form.changeFieldValue}
            onBlur={form.validateFieldValue}
            error={form.errors.name !== ''}
            helperText={form.errors.name}
          />
          <Typography variant="h5">
            {`${translate('view:taxonomies:title')}`}
          </Typography>
          {getTaxonomySelectorBlock()}
          {getTags()}
        </Stack>
      </Card>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SecondaryButton
          onClick={() => navigate(-1)}
        >
          {`${translate('action:cancel')}`}
        </SecondaryButton>
        <PrimaryButton onClick={form.submit}>
          {submitButtonLabel}
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}
