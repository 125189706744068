export const SMALL_CARD_MIN_HEIGHT = 238
export const LONG_CARD_MIN_HEIGHT = 211
export const GRAPH_CARD_MIN_HEIGHT = 350

const GridStyle = {
  smallCard: {
    minHeight: SMALL_CARD_MIN_HEIGHT,
  },
  longCard: {
    minHeight: LONG_CARD_MIN_HEIGHT,
  },
  graphCard: {
    height: GRAPH_CARD_MIN_HEIGHT,
  },
  averageCard: {
    height: 138,
  }
}

export default GridStyle
