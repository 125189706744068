import { Navigate } from 'react-router-dom'
import LoadingScreen from '../components/loading-screen'
import { PATH_ACCOUNTS, PATH_AUTH } from '../routes/paths'
import { useAuthContext } from './useAuthContext'
import { ROLES } from '../utils/rolesAndPermissions'
import userDashboardRedirect from '../utils/userDashboardRedirect'
import LoggedApiInterface from '../services/users/interfaces/LoggedApiUserInterface'

export default function GuestGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated, isInitialized, user }: {isAuthenticated: boolean, isInitialized: boolean, user: LoggedApiInterface | null} = useAuthContext()
  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (isAuthenticated && user) {
    const roleRedirect = (): string => {
      if (user.role === ROLES.SUPER_ADMIN) {
        return PATH_ACCOUNTS.organizations
      }
      if (user.role === ROLES.BASE && !!user.organizationUUID) {
        return user.tagGroups.length > 1
          ? PATH_ACCOUNTS.tagGroups(user.organizationUUID)
          : userDashboardRedirect(
              user,
              user.organizationUUID,
              user.tagGroups[0].uuid,
            )
      }
      if (user.role === ROLES.ORGANIZATION_ADMIN && user.organizationUUID) {
        return PATH_ACCOUNTS.tagGroups(user.organizationUUID)
      }
      return PATH_AUTH.login
    }

    return <Navigate to={roleRedirect()} replace />
  }

  return <> {children} </>
}
