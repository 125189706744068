import { Navigate, useRoutes } from 'react-router-dom'

import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
import RoleBasedGuard from '../auth/RoleBasedGuard'
import PermissionGuard from '../auth/PermissionGuard'
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard/DashboardLayout'
import AccountsLayout from '../layouts/accounts/AccountsLayout'
import PermissionDenied from '../pages/PermissionDenied'
import NewUser from '../pages/general/NewUser'
import EditUser from '../pages/general/EditUser'
import NewOrganization from '../pages/general/NewOrganization'
import EditOrganization from '../pages/general/EditOrganization'
import EditCampaign from '../pages/dashboard/EditCampaign'
import EditLocation from '../pages/general/Locations/EditLocation'
import Locations from '../pages/general/Locations/Locations'
import NewLocation from '../pages/general/Locations/NewLocation'
import Taxonomies from '../pages/general/Taxonomies/Taxonomies'
import NewTaxonomy from '../pages/general/Taxonomies/NewTaxonomy'
import EditTaxonomy from '../pages/general/Taxonomies/EditTaxonomy'
import TagGroups from '../pages/general/TagGroups/TagGroups'
import NewTagGroup from '../pages/general/TagGroups/NewTagGroup'
import EditTagGroup from '../pages/general/TagGroups/EditTagGroup'
import Nodes from '../pages/general/Nodes/Nodes'

import {
  LoginPage,
  SendEmailPage,
  Organizations,
  Users,
  Analytics,
  Campaigns,
  NewCampaign,
  Connect,
  RealTime,
  Registers,
  Page404,
  ConfirmPasswordPage,
} from './elements'

import { PATH_AUTH, PATH_PASSWORD, PATH_PUBLIC } from './paths'

import { ROLES, PERMISSIONS } from '../utils/rolesAndPermissions'
import NewNode from '../pages/general/Nodes/NewNode'
import EditNode from '../pages/general/Nodes/EditNode'
import Anonymous from '../pages/dashboard/Anonymous/Anonymous'
import PermissionsValue from '../services/common/enum/PermissionsValues'
import UnsuscribeOrganization from '../pages/Unsuscribe/UnsuscribeOrganization/UnsuscribeOrganization'

export default function Router() {
  return useRoutes([
    {
      path: '',
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: PATH_PASSWORD.reset,
      element: (
        <GuestGuard>
          <SendEmailPage />
        </GuestGuard>
      ),
    },
    {
      path: PATH_PASSWORD.confirm,
      element: (
        <GuestGuard>
          <ConfirmPasswordPage />
        </GuestGuard>
      ),
    },
    {
      path: PATH_PUBLIC.unsuscribeOrganization,
      element: (
        <GuestGuard>
          <UnsuscribeOrganization />
        </GuestGuard>
      ),
    },
    {
      path: '/app/backoffice',
      element: (
        <AuthGuard>
          <AccountsLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'organizations',
          children: [
            {
              index: true,
              element: (
                <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
                  <Organizations />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/edit',
              element: (
                <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
                  <EditOrganization />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/locations',
              element: (
                <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
                  <Locations />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/locations/new',
              element: (
                <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
                  <NewLocation />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/locations/:locationId/edit',
              element: (
                <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
                  <EditLocation />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/users',
              element: (
                <RoleBasedGuard
                  roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN]}
                >
                  <Users />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/users/new',
              element: (
                <RoleBasedGuard
                  roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN]}
                >
                  <NewUser isOrganizationView />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId/users/edit/:userId',
              element: (
                <RoleBasedGuard
                  roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN]}
                >
                  <EditUser isOrganizationView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'organizations/new',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <NewOrganization />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'users',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <Users />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'users/new',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <NewUser />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'users/edit/:userId',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <EditUser />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'organizations/:organizationId/taxonomies',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <Taxonomies />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'organizations/:organizationId/taxonomies/new',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <NewTaxonomy />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'organizations/:organizationId/taxonomies/:taxonomyId/edit',
          element: (
            <RoleBasedGuard
              roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN]}
            >
              <EditTaxonomy />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'organizations/:organizationId/tag-groups',
          element: (
            <RoleBasedGuard
              roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.BASE]}
            >
              <TagGroups />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'organizations/:organizationId/tag-groups/new',
          element: (
            <RoleBasedGuard
              roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN]}
            >
              <NewTagGroup />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'organizations/:organizationId/tag-groups/:tagGroupId/edit',
          element: (
            <RoleBasedGuard
              roles={[ROLES.SUPER_ADMIN, ROLES.ORGANIZATION_ADMIN]}
            >
              <EditTagGroup />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'nodes',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <Nodes />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'nodes/new',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <NewNode />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'nodes/:nodeId/edit',
          element: (
            <RoleBasedGuard roles={[ROLES.SUPER_ADMIN]}>
              <EditNode />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: '/app/dashboard/organizations/:organizationId/groups/:groupId',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate to={'/app/dashboard/:organizationId/:brandId'} replace />
          ),
          index: true,
        },
        {
          path: 'anonymous',
          element: (
            <PermissionGuard permission={PermissionsValue.ANONYMOUS_PERMISSION}>
              <Anonymous />
            </PermissionGuard>
          ),
        },
        {
          path: 'analytics',
          element: (
            <PermissionGuard permission={PERMISSIONS.ANALYTICS}>
              <Analytics />
            </PermissionGuard>
          ),
        },
        {
          path: 'connect',
          element: (
            <PermissionGuard permission={PERMISSIONS.CONNECT}>
              <Connect />
            </PermissionGuard>
          ),
        },
        {
          path: 'registers',
          element: (
            <PermissionGuard
              permission={PermissionsValue.REGISTERED_PERMISSION}
            >
              <Registers />
            </PermissionGuard>
          ),
        },
        {
          path: 'campaigns',
          element: (
            <PermissionGuard
              permission={PermissionsValue.REGISTERED_PERMISSION}
            >
              <Campaigns />
            </PermissionGuard>
          ),
        },
        {
          path: 'campaigns/:campaignId/edit',
          element: (
            <PermissionGuard permission={PERMISSIONS.CAMPAIGN_WRITE}>
              <EditCampaign />
            </PermissionGuard>
          ),
        },
        {
          path: 'new-campaign',
          element: (
            <PermissionGuard permission={PERMISSIONS.CAMPAIGN_WRITE}>
              <NewCampaign />
            </PermissionGuard>
          ),
        },
        {
          path: 'realtime',
          element: (
            <PermissionGuard permission={PERMISSIONS.REALTIME}>
              <RealTime />
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '403', element: <PermissionDenied /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
