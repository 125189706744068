const style = {
  container: {
    display: 'flex',
    gap: '1rem'
  },
  formField: {
    mb: 1
  },
  cancelButton: {
    color: 'text.primary',
    borderColor: 'rgba(145, 158, 171, 0.32)',
  },
  submitButton: {
    backgroundColor: 'grey.800'
  },
  tagChipContainer: {
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap"
  },
  permissionContainer: {
    width: '100%'
  }
}

export default style;
