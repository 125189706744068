import i18n from "../../locales/i18n";
import AnonymousAttractionRateApiInterface from "./interfaces/AnonymousAttractionRateApiInterface";
import AnonymousAverageVisitsApiInterface from "./interfaces/AnonymousAverageVisitsApiInterface";
import { AnonymousDetectionsApiInterface } from "./interfaces/AnonymousDetectionsApiInterface";
import { AnonymousDwellTimeApiInterface } from "./interfaces/AnonymousDwellTimeApiInterface";
import AnonymousFrequencyApiInterface from "./interfaces/AnonymousFrequencyApiInterface";
import { AnonymousTraficZonesApiInterface } from "./interfaces/AnonymousTraficZonesApiInterface";
import AnonymousAttractionRateModel from "./model/AnonymousAttractionRateModel";
import AnonymousAverageVisitsModel from "./model/AnonymousAverageVisitsModel";
import AnonymousDetectionsModel from "./model/AnonymousDetectionsModel";
import AnonymousDetectionsWeekApiModel from "./model/AnonymousDetectionsWeekApiModel";
import AnonymousDwellTimeModel from "./model/AnonymousDwellTimeModel";
import AnonymousFrequencyModel from "./model/AnonymousFrequencyModel";
import AnonymousKPIsModel from "./model/AnonymousKPIsModel";
import AnonymousTraficZonesModel from "./model/AnonymousTraficZonesModel";


export function adaptAnonymousKPIsResponse(response: any): AnonymousKPIsModel {
  return new AnonymousKPIsModel(
    response?.unique_detection_sum || {},
    response?.detection_sum || {},
    response?.detection_average || {},
    response?.average_time_stay || {},
    response?.new_vs_loyal || {},
    response?.average_time_comeback || {},
  )
}

export function adaptAnonymousTraficZonesResponse(
  response: AnonymousTraficZonesApiInterface[],
): AnonymousTraficZonesModel[] {
  return response.map((traficZone: AnonymousTraficZonesApiInterface) => {
    return new AnonymousTraficZonesModel(
      traficZone.zoneIndex,
      traficZone.zoneName,
      traficZone.currentZoneVisits,
      traficZone.previousZoneVisits,
    )
  })
}

export function adaptAnonymousDwellTimeResponse(
  response: AnonymousDwellTimeApiInterface,
): AnonymousDwellTimeModel {
  return new AnonymousDwellTimeModel(
    (response.dwellTimeRate ?? 0) * 100,
    (response.dwellTimeDifferenceRate ?? 0) * 100,
  )
}

export function adaptAnonymousDetectionsResponse(
  response: AnonymousDetectionsApiInterface[],
): AnonymousDetectionsModel[] {
  return response.map((detection: AnonymousDetectionsApiInterface) => {
    return new AnonymousDetectionsModel(
      detection.hour,
      detection.data.map((data: AnonymousDetectionsWeekApiModel) => {
        return {
          x: i18n.t(`date:calendar:${data.weekday.toLowerCase()}`),
          y: data.value,
        }
      }),
    )
  })
}

export function adaptAnonymousFrequencyReponse(
  response: AnonymousFrequencyApiInterface,
): AnonymousFrequencyModel {
  return new AnonymousFrequencyModel(
    response.one,
    response.two,
    response.three_plus,
  )
}

export function adaptAnonymousAttractionRate(
  response: AnonymousAttractionRateApiInterface,
): AnonymousAttractionRateModel {
  return new AnonymousAttractionRateModel(
    response.current,
    response.previous,
    response.variation * 100
  )
}

export function adaptAnonymousAverageVisits(response: AnonymousAverageVisitsApiInterface): AnonymousAverageVisitsModel {
  return new AnonymousAverageVisitsModel(
    Math.round(response.currentPeriod),
    Math.round(response.previousPeriod),
    response.variation * 100,
  )
}
