import Chart, { useChart } from '../chart'

export default function HorizontalBarGraph({
  maxValue,
  series,
  axisYTooltipFormatter,
}: {
  maxValue?: number
  series: { name: string; data: number, color?: string }[]
  axisYTooltipFormatter?: (value: string) => string
}) {
  const BASE_HEIGHT = 120
  const HEIGHT_PER_ITEM = 40

  const calculateHeight = () => {
    if (series.length <= 3) {
      return BASE_HEIGHT
    }

    return series.length * HEIGHT_PER_ITEM
  }

  const options = useChart({
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 2,
        borderRadiusApplication: 'around',
        horizontal: true,
        barHeight: 12,
      },
    },
    colors: series.map((serie) => serie.color),
    xaxis: {
      max: maxValue ?? undefined,
      stepSize: 50,
      categories: series.map(i => i.name),
      labels: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: function (
            value: string,
            { dataPointIndex, w }: { w: any; dataPointIndex: number },
          ) {
            return w.globals.labels[dataPointIndex]
          },
        },
        formatter: axisYTooltipFormatter,
      },
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: { horizontal: 4 },
      fontSize: '10px',
      fontWeight: 600,
      markers: {
        radius: 2,
        width: 10,
        height: 10,
        size: 5,
      },
      formatter: function (seriesName: string, opts: any) {
        return (
          seriesName +
          ' - ' +
          opts.w.globals.stackedSeriesTotals[opts.seriesIndex]
        )
      },
      labels: {
        colors: 'grey',
      },
    },
    yaxis: {
      show: false,
    },
  })

  return (
    <>
      {/* @ts-ignore */}
      <Chart
        type="bar"
        options={options}
        series={[{ data: series.map(serie => serie.data) }]}
        height={calculateHeight()}
      />
    </>
  )
}
