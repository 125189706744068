import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const updateAll = createAsyncThunk(
  'organizations/updateAll',
  async (organizations, { dispatch }) => {
    for (const organizationId in organizations) {
      dispatch(update(organizations[organizationId]))
    }
  },
)

const initialState = {}

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    update: (state, action) => {
      const organization = action.payload
      state[organization.uuid] = organization
    },
  },
})

export const { update } = organizationsSlice.actions

export default organizationsSlice.reducer
