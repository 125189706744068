import { Link, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import useActiveLink from '../../../hooks/useActiveLink';
import NavSectionItemStyle from './NavSectionItemStyle'
import { useLocales } from '../../../locales';

export default function NavSectionItem({
  item,
  isCollapsed = false,
} : {
  item: any,
  isCollapsed?: boolean,
}): JSX.Element {
  const { active } = useActiveLink(item.path)
  const { translate } = useLocales()
  const theme = useTheme()
  const style = NavSectionItemStyle(theme, active, isCollapsed)

  return (
    <Link
      component={RouterLink}
      to={item.path}
      sx={style.container}
    >
      <ListItemButton
        sx={isCollapsed ? style.listItemButtonCollapsed : style.listItemButton}
      >
        {item.icon && (
           <ListItemIcon
            sx={style.icon}
           >
            {item.icon}
          </ListItemIcon>
        )}

        <ListItemText
          primary={`${translate(item.title)}`}
          primaryTypographyProps={{
            noWrap: isCollapsed,
            sx: style.text,
          }}
        />
      </ListItemButton>
    </Link>
  )
}
