import { useEffect, useState, useCallback } from 'react'
import { fetchTagGroup } from './tagGroupService'
import TagGroupSingleModel from './model/TagGroupSingleModel'

type UseTagGroupStateType = {
  data: TagGroupSingleModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useTagGroup(organizationUUID: string, tagGroupUUID: string): object {
  const [state, setState] = useState<UseTagGroupStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchTagGroup(organizationUUID, tagGroupUUID)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID, tagGroupUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    refetch: fetchData,
  }
}

export default useTagGroup
