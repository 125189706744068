import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, IconButton, Typography } from '@mui/material'

import { UploadIllustration } from '../../assets/illustrations'
import { useLocales } from '../../locales'

import Image from '../image'
import Iconify from '../iconify'

const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg']
const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024

ImageDropArea.propTypes = {
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default function ImageDropArea({ hasError, onChange, onError }) {
  const [file, setFile] = useState(null)

  const { translate } = useLocales()

  const processFile = file => {
    if (file === undefined) {
      return
    }

    if (
      file.size > MAX_FILE_SIZE_BYTES ||
      !ALLOWED_FILE_TYPES.includes(file.type)
    ) {
      onError()
      return
    }

    setFile(file)
    onChange(file)
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDrop = event => {
    event.preventDefault()
    processFile(event.dataTransfer.files[0])
  }

  const handleInputChange = event => {
    processFile(event.target.files[0])
  }

  const removeFile = () => {
    setFile(null)
    onChange(null)
  }

  return file !== null ? (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'grey.100',
        height: '350px',
        p: 2,
      }}
    >
      <IconButton
        aria-label="close"
        onClick={removeFile}
        sx={{
          position: 'absolute',
          top: 30,
          right: 30,
          zIndex: 1,
          mixBlendMode: 'exclusion',
        }}
      >
        <Iconify icon="mingcute:close-line" />
      </IconButton>

      <Image
        disabledEffect
        src={URL.createObjectURL(file)}
        sx={{ borderRadius: 1, width: '100%', height: '100%' }}
      />
    </Box>
  ) : (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        backgroundColor: hasError ? 'error.lighter' : 'grey.100',
        borderColor: hasError ? 'error.main' : 'grey.100',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: 1,
        p: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '350px',
      }}
    >
      <Box sx={{ width: '200px', mb: 2 }}>
        <UploadIllustration />
      </Box>

      <Typography variant="h6" sx={{ mb: 1 }}>
        {translate('new-campaign:form-image-drop-text')}{' '}
        <Button
          variant="text"
          component="label"
          sx={{
            p: 0,
            textTransform: 'inherit',
            fontSize: 'inherit',
            verticalAlign: 'inherit',
          }}
        >
          {translate('new-campaign:form-image-drop-button')}
          <input
            hidden
            type="file"
            accept={ALLOWED_FILE_TYPES.join()}
            onChange={handleInputChange}
          />
        </Button>
      </Typography>

      <Typography variant="body2">
        {translate('new-campaign:form-image-drop-restrictions')}
      </Typography>
    </Box>
  )
}
