const BLOCK = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 2
}

const LOCATIONS_CONTAINER = {
  width: '80vw'
}

const FilterBarStyle = (isDesktop: boolean) => {
  return ({
    container: {
      width: '100%',
      gap: 3,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filterBlock: {
      flexDirection: {sm: 'column', md: 'row'},
      justifyContent: 'space-between',
      gap: 2,
    },
    filterButton: {
      flexDirection: 'row',
      gap: 1,
    },
    filterLocationCounter: {
      backgroundColor: 'grey.200',
    },
    taxonomyListBlock: {
      ...BLOCK,
    },
    taxonomyTagsBlock: {
      ...BLOCK,
      width: '100%',
    },
    locationsContainer: [
      {
        py: 1,
        maxHeight: '80dvh',
        overflowY: 'scroll',
        gap: 3,
      },
      isDesktop ? LOCATIONS_CONTAINER : {},
    ],
    locationsBlock: {
      ...BLOCK,
    }
  })
}

export default FilterBarStyle
