import SemiGaugeGraph from '../semi-gauge-graph/SemiGaugeGraph'
import { GRAPH_CARD_MIN_HEIGHT } from '../../style/components/GridStyle'
import ChartCard from '../card/ChartCard/ChartCard'

export default function DwellTimeGraph({
  title,
  variant,
  series,
  tooltip,
}: {
  title: string
  variant: number
  tooltip?: string
  series: {
    value: number
    label: string
    color?: string
  }[]
}) {
  return (
    <ChartCard
      title={title}
      tooltip={tooltip}
      height={GRAPH_CARD_MIN_HEIGHT}
      variant={variant}
    >
      <SemiGaugeGraph series={series} />
    </ChartCard>
  )
}
