import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import useUserForm from '../../hooks/useUserForm'
import { useLocales } from '../../locales'
import useUser from '../../services/users/useUser'
import useTagGroups, {
  TagGroupStateType,
} from '../../services/tagGroups/useTagGroups'
import FormContainer from '../../components/form/FormContainer/FormContainer'
import UserForm from '../../sections/dashboard/users/userForm/UserForm'
import { PATH_ACCOUNTS } from '../../routes/paths'
import FormRequestActions from '../../components/form/FormRequestActions/FormRequestActions'
import { InvalidRoleWithAssignedOrganizationError } from '../../services/users/Error'
import SelectType from '../../services/common/types/SelectType'
import defaultUserPermissions from '../../services/permissions/default/defaultUserPermissions'

export default function EditUserPage({ isOrganizationView = false }) {
  const { translate } = useLocales()
  const navigate = useNavigate()
  const { organizationId, userId } = useParams()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [groups, setGroups] = useState<SelectType[] | undefined | null>(null)

  const displayError = (error: Error) => {
    if (error instanceof InvalidRoleWithAssignedOrganizationError) {
      setErrorMessage(
        `${translate(
          'edit:user:invalid:role:with:assigned:organization:error',
        )}`,
      )
      return
    }

    setErrorMessage(`${translate('edit:user:error')}`)
  }

  const clearError = () => {
    setErrorMessage(null)
  }

  const goBack = () => {
    if (isOrganizationView) {
      navigate(
        `${PATH_ACCOUNTS.organizationUsers(organizationId)}?updated=true`,
      )

      return
    }

    navigate(`${PATH_ACCOUNTS.generalUsers}?updated=true`)
  }

  const submitSuccess = async (values: any) => {
    clearError()

    try {
      await updateById(values)

      goBack()
    } catch (error) {
      displayError(error)
    }
  }

  const {
    changeField,
    changeRole,
    changeOrganization,
    submit,
    validateField,
    form,
    dispatchForm,
    updatePermission,
    updateGroupsOptions,
    deleteGroupOption,
    setDefaultPermissions,
    validatePermissions,
    validateTagGroups,
    validateRole,
  } = useUserForm(organizationId as string, {
    successCallback: submitSuccess,
    initialState: null,
  })

  const { loading, updating, user, updateById } = useUser(userId as string)
  const tagGroups = useTagGroups(
    organizationId || form.values.organizationId,
  ) as TagGroupStateType

  const defaultPermissions = defaultUserPermissions(form)
  useEffect(() => {
    setDefaultPermissions(defaultPermissions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.organizationId])

  useEffect(() => {
    setGroups(
      tagGroups?.data?.map(group => {
        return {
          id: group.uuid,
          label: group.name,
        }
      }),
    )
  }, [tagGroups.data, form.values.organizationId])

  useEffect(() => {
    if (user !== undefined) {
      dispatchForm({
        type: 'changeFields',
        payload: {
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          role: user.role,
          organizationId: user.organizationUUID,
          tagGroups: user.tagGroups,
          permissions: user.permissions,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatchForm])

  return (
    <>
      <FormContainer
        title={`${translate('users:edit')}`}
        showError={false}
        errorMessage={''}
        onCloseError={() => {}}
      >
        <UserForm
          isEditView
          errors={form.errors}
          values={form.values}
          tagGroups={groups}
          submit={submit}
          changeField={changeField}
          changeRole={changeRole}
          changeOrganization={changeOrganization}
          updateGroupsOptions={updateGroupsOptions}
          validateField={validateField}
          submitLabel={`${translate('edit-user:form:submit-label')}`}
          updatePermission={updatePermission}
          validatePermissions={validatePermissions}
          validateTagGroups={validateTagGroups}
          validateRole={validateRole}
          deleteGroupOption={deleteGroupOption}
          isOrganizationView={isOrganizationView}
        />
      </FormContainer>
      <FormRequestActions
        isLoading={loading}
        isUpdating={updating}
        errorMessage={errorMessage}
        closeRequestError={clearError}
      />
    </>
  )
}
