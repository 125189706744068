import { useState, useEffect, useCallback } from 'react'

import {
  fetchLocationsByOrganizationId,
  fetchLocationById,
  removeLocation,
} from './locationService'
import LocationPayloadModel from './model/LocationPayloadModel'

type UseLocationsByOrganizationIdState = {
  data: LocationPayloadModel[] | undefined;
  loading: boolean;
  error: string | null;
}

export function useLocationsByOrganizationId(organizationId: string) {
  const [state, setState] = useState<UseLocationsByOrganizationIdState>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchLocations = useCallback(async () => {
    if (!organizationId) return

    try {
      const response = await fetchLocationsByOrganizationId(organizationId)

      setState(state => ({
        ...state,
        loading: false,
        data: response?.data,
      }))
    } catch (e) {
      setState(state => ({
        ...state,
        loading: false,
        error: e,
      }))
    }
  }, [organizationId])

  useEffect(() => {
    fetchLocations()
  }, [fetchLocations])

  async function remove(id: string) {
    await removeLocation(id, organizationId)
  }

  return {
    ...state,
    refetch: fetchLocations,
    remove,
  }
}

type useLocationStateType = {
  data: undefined | null | any,
  loading: boolean,
  error: string | null
}

export function useLocation(organizationId: string, storeId: string) {
  const [state, setState] = useState<useLocationStateType>({
    data: undefined,
    loading: false,
    error: null,
  })
  const fetchStores = useCallback(async () => {
    try {
      const response = await fetchLocationById(organizationId, storeId)
      setState(state => ({
        ...state,
        data: response?.data,
      }))
    } catch (error) {
      setState({
        data: null,
        loading: false,
        error,
      })
    }
  }, [storeId, organizationId])

  useEffect(() => {
    fetchStores()
  }, [fetchStores])

  return {
    ...state,
    refetch: fetchStores,
  }
}
