import { generateUUID } from "../../utils/uuidGenerator";
import campaignsStatusEnum from "./enum/campaignsStatusEnum";
import { campaignsModel } from "./model/campaignsModel";

function generateMocks(numMocks: number) {
  const statuses = [campaignsStatusEnum.COMPLETED, campaignsStatusEnum.LIVE, campaignsStatusEnum.PROGRAMMED];
  const organizations = [
    "Walker, Hirthe and Hamill",
    "Smith and Sons",
    "Brown Group",
    "Green PLC",
    "Jones Incorporated",
    "Taylor Group",
    "Moore PLC",
    "Anderson LLC",
    "Jackson and Co.",
    "Clark and Partners"
  ];

  const locations = [
    "Cruickshank, Durgan and Pagac",
    "Johnston and Adams",
    "Schultz, Dietrich and Little",
    "King LLC",
    "Thomas, Spencer and Craig",
    "Miller and Sons",
    "Davis, Wilson and Lee",
    "Martinez and Roberts",
    "Harris, Thompson and White",
    "Lopez, King and Martinez"
  ];

  const zones = [
    "Marilou",
    "Harold",
    "Arnold",
    "Benedict",
    "Felicity",
    "Leonard",
    "Sophia",
    "Everett",
    "Lucas",
    "Mason"
  ];

  const mocks = [];
  for (let i = 0; i < numMocks; i++) {
    const uuid = generateUUID();
    const mac = `${Math.floor(Math.random() * 99)}:db:6e:8f:3b:7d`;
    const orgIndex = i % organizations.length;
    const startDate = new Date(Date.now() + i * 86400000).toISOString(); // Incremental start dates
    const endDate = new Date(Date.now() + (i + 1) * 86400000).toISOString(); // Incremental end dates
    const status = statuses[i % statuses.length];
    const includeImage = Math.random() > 0.5;

    mocks.push(
      new campaignsModel(
        uuid,
        `Test Campaign ${i + 1}`,
        `This is a test campaign ${i + 1}`,
        {
          uuid: generateUUID(),
          mac: mac,
          organizationUUID: generateUUID(),
          locationUUID: generateUUID(),
          zoneUUID: generateUUID(),
          organizationName: organizations[orgIndex],
          locationName: locations[orgIndex],
          zoneName: zones[orgIndex],
        },
        startDate,
        endDate,
        `Join Test Campaign ${i + 1} now!`,
        includeImage ? `https://picsum.photos/${i + 1}` : null,
        status,
        Math.random() > 0.5,
        Math.floor(Math.random() * 40),
      )
    );
  }

  return mocks;
}

const campaignsMocks = {
  data: [...generateMocks(32)]
}

export default campaignsMocks
