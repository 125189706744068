const GaugeGraphStyle = {
  container: {
    width: '100%'
  },
  graphContainer: {
    p: 2,
  },
}

export default GaugeGraphStyle
