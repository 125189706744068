const heatmapChartStyle = {
  chart: {
    width: '100%'
  },
  legendContainer: {
    display: "flex",
    justifyContent: "center"
  },
  legendWrapper: {
    display: "flex",
    alignItems: "center",
    width: "40%"
  },
  legendLeftText: {
    mr: 1
  },
  legendRightText: {
    ml: 1,
  },
  legendGraphic: {
    height: 16,
    flexGrow: 1,
    background: `linear-gradient(90deg, #FFFFFF, #E57373)`,
  }
}

export default heatmapChartStyle
