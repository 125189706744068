import { Autocomplete, Card, Stack, TextField } from "@mui/material"
import SecondaryButton from "../../../../components/button/SecondaryButton/SecondaryButton"
import PrimaryButton from "../../../../components/button/PrimaryButton/PrimaryButton"
import { useNavigate } from "react-router"
import { useLocales } from "../../../../locales"
import style from '../../../../style/components/FormStyle'
import useOrganizations from "../../../../services/organizations/useOrganizations"
import { useLocationsByOrganizationId } from "../../../../services/location/useLocations"
import SnackbarAlert from "../../../../components/alert/SnackbarAlert/SnackbarAlert"
import SnackBarAlertTypes from "../../../../components/alert/SnackbarAlert/SnackBarAlertTypes"

export default function NodeForm({
  form,
  submitButtonLabel
} : {
  form: any,
  submitButtonLabel: string
}): JSX.Element {
  const navigate = useNavigate()
  const { translate } = useLocales()
  const organizations = useOrganizations() as any
  const locations = useLocationsByOrganizationId(form.values?.organization?.uuid ?? '') as any

  const hasDescriptionError = (): boolean => {
    return form.errors.description !== ''
  }

  const isEmptyOrganizationLocations = (): boolean => {
    return Boolean(form.values?.organization) && !Boolean(locations?.data?.length)
  }

  const isEmptyOrganizationZones = (): boolean => {
    return Boolean(form.values?.organization) && !Boolean(form.values?.organization?.zones?.length)
  }

  const hasEmptyData = (): boolean => {
    return form.values.organization !== null
      && (isEmptyOrganizationLocations() || isEmptyOrganizationZones())
  }

  const getEmptyDataMessage = (): string => {
    const emptyLocationsError = `${translate('create:node:form:empty:locations:error')}`
    const emptyZonesError = `${translate('create:node:form:empty:zones:error')}`


    if (isEmptyOrganizationLocations() && isEmptyOrganizationZones()) {
      return `${emptyLocationsError} ${emptyZonesError}`
    }

    if (isEmptyOrganizationLocations()) {
      return emptyLocationsError
    }

    if (isEmptyOrganizationZones()) {
      return emptyZonesError
    }

    return ''
  }

  const getOrganizationsSeletor = (): JSX.Element => {
    return (
      <Autocomplete
        options={Boolean(organizations?.data?.length) ? organizations.data : []}
        fullWidth
        value={form.values.organization || null}
        onChange={(_, value) => {
          form.changeOrganizationValue({
            target: {
              name: 'organization',
              value,
            },
          })
        }}
        onBlur={form.validateFieldValue}
        getOptionLabel={(option: any) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        renderInput={params => (
          <TextField
            {...params}
            label={`${translate('create:node:form:organization')}`}
            name="organization"
            value={form.values.organization}
            error={form.errors.organization !== ''}
            helperText={form.errors.organization}
          />
        )}
      />
    )
  }

  const getLocationSelector = (): JSX.Element => {
    return (
      <Autocomplete
        options={Boolean(locations?.data?.length) ? locations.data :  []}
        disabled={!Boolean(locations?.data?.length)}
        fullWidth
        value={form.values.location || null}
        onChange={(_, value) => {
          form.changeFieldValue({
            target: {
              name: 'location',
              value,
            },
          })
        }}
        onBlur={form.validateFieldValue}
        getOptionLabel={(option: any) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        renderInput={params => (
          <TextField
            {...params}
            label={`${translate('create:node:form:location')}`}
            name="location"
            value={form.values.location}
            error={form.errors.location !== ''}
            helperText={form.errors.location}
          />
        )}
      />
    )
  }

  const getZoneSeletor = (): JSX.Element => {
    return (
      <Autocomplete
        options={Boolean(form.values?.organization?.zones?.length) ? form.values.organization.zones :  []}
        disabled={!Boolean(form.values?.organization?.zones?.length)}
        fullWidth
        value={form.values.zone || null}
        onChange={(_, value) => {
          form.changeFieldValue({
            target: {
              name: 'zone',
              value,
            },
          })
        }}
        onBlur={form.validateFieldValue}
        getOptionLabel={(option: any) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        renderInput={params => (
          <TextField
            {...params}
            label={`${translate('create:node:form:zone')}`}
            name="zone"
            value={form.values.zone}
            error={form.errors.zone !== ''}
            helperText={form.errors.zone}
          />
        )}
      />
    )
  }

  return (
    <Stack sx={style.container}>
      <Card sx={style.card}>
        <Stack sx={style.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            name="description"
            label={`${translate('create:node:form:description')}`}
            value={form.values?.description || ''}
            onChange={form.changeFieldValue}
            onBlur={form.validateFieldValue}
            error={hasDescriptionError()}
            helperText={form.errors.description}
          />
          {getOrganizationsSeletor()}
          {getLocationSelector()}
          {getZoneSeletor()}
        </Stack>
      </Card>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SecondaryButton
          onClick={() => navigate(-1)}
        >
          {`${translate('action:cancel')}`}
        </SecondaryButton>
        <PrimaryButton onClick={form.submit}>
          {submitButtonLabel}
        </PrimaryButton>
      </Stack>
      <SnackbarAlert
        isOpen={hasEmptyData()}
        message={getEmptyDataMessage()}
        type={SnackBarAlertTypes.ERROR}
      />
    </Stack>
  )
}
