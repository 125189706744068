import { Theme } from "@mui/material"
import { NAV } from "../../../config-global"

const NavToggleButtonStyle = (theme: Theme, isCollapsed?: boolean) => {
  return ({
    container: {
      p: 0.5,
      top: 32,
      position: 'fixed',
      left: (isCollapsed ? NAV.W_DASHBOARD_MINI :  NAV.W_DASHBOARD) - 12,
      zIndex: theme.zIndex.appBar + 1,
      border: `dashed 1px ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        bgcolor: 'background.default',
      },
    }
  })
}

export default NavToggleButtonStyle
