import { Grid } from '@mui/material'

import CardLoadingSkeleton from './CardLoadingSkeleton/CardLoadingSkeleton'

export default function Skeleton({ loadingData }) {
  return (
    <Grid container spacing={3}>
      {loadingData.map((data, index) => (
        <Grid
          item
          xs={data.xs}
          sm={data.sm}
          md={data.md}
          lg={data.lg}
          key={`${index}-${data.title}`}
        >
          {data.icon ? (
            <CardLoadingSkeleton title={data.title} icon={data.icon} />
          ) : (
            <CardLoadingSkeleton
              title={data.title}
              height={data.height}
              width={data.width}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}
