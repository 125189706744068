import { Box } from '@mui/material'
import Chart from 'react-apexcharts'
import { useChart } from '../chart'
import style from './GaugeGraphStyle'

export default function GaugeGraph({
  series,
  maxValue = Math.max(...series.map(serie => serie.value)),
  totalLabel,
  height = 300,
}: {
  series: {
    value: number
    label: string
    color?: string
  }[]
  maxValue?: number
  totalLabel?: string
  height?: number
}) {
  const normalizedSeries = series.map(serie => ({
    ...serie,
    value: (serie.value / maxValue) * 100,
  }))
  const chartOptions = useChart({
    legend: {
      show: false,
    },
    labels: series.map(serie => serie.label),
    colors: series.map(serie => serie.color),
    dataLabels: {
      style: {
        colors: series.map(serie => serie.color),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
      },
      pie: {
        donut: {
          labels: {
            value: {
              fontSize: '22px',
              color: 'black',
              formatter: function (value: number) {
                return value
              },
            },
            total: {
              show: true,
              label: totalLabel,
              fontSize: '12px',
              formatter: function (w: any) {
                return w.globals.seriesTotals.reduce(
                  (_: any, value: number) => {
                    return Math.round((value * maxValue) / 100) + '%'
                  },
                  0,
                )
              },
            },
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        gradientToColors: series.map(serie => serie.color || null),
        opacityFrom: 0.8,
        opacityTo: 1,
      },
    },
  })

  return (
      <Box sx={style.graphContainer}>
        {/* @ts-ignore */}
        <Chart
          type="radialBar"
          height={height}
          options={chartOptions}
          series={normalizedSeries.map(serie => serie.value)}
        />
      </Box>
  )
}
