import countryCodes from 'country-codes-list'

export default function useCountries() {
  const allCountries = countryCodes.all()

  const listPhonePrefixes = () => {
    return [
      ...new Set(allCountries.map(country => `+${country.countryCallingCode}`)),
    ]
  }

  const listCountries = () => {
    return allCountries.map(country => ({
      code: country.countryCode,
      name: country.countryNameEn,
    }))
  }

  return {
    listPhonePrefixes,
    listCountries,
  }
}
