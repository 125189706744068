import { Navigate } from 'react-router-dom'
import { useAuthContext } from './useAuthContext'

export default function RoleBasedGuard({ roles, children }: { roles: string, children: JSX.Element }) {
  const { user } = useAuthContext()

  if (typeof roles !== 'undefined' && !roles.includes(user?.role!)) {
    return <Navigate to={'/403'} replace />
  }

  return <> {children} </>
}
