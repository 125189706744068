const PermissionsCardStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 0
  },
  boxWrapper: {
    bgcolor: 'background.neutral',
    p: 1.5,
    borderRadius: 2,
  },
  boxWrapperError: {
    bgcolor: 'background.neutral',
    p: 1.5,
    border: '1px solid red',
    borderRadius: 2,
  }
}

export default PermissionsCardStyle;
