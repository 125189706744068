import { useCallback, useReducer, useState } from 'react'
import { PermissionsType } from '../services/permissions/types/PermissionType'

import { emailValidator } from '../utils/validators'
import { ROLES } from '../utils/rolesAndPermissions'
import i18n from '../locales/i18n'
import TagGroupUpdatePayloadModel from '../services/tagGroups/model/TagGroupUpdatePayloadModel'

const ERRORS = {
  missingField: i18n.t('edit-user:form:error-missing-field'),
  storesNotInformed: i18n.t('edit-user:form:error-stores-not-informed'),
  invalidEmail: i18n.t('edit-user:form:error-invalid-email'),
  invalidPermissions: i18n.t('edit-user:form:error-invalid-permissions'),
}

const isSuperAdmin = (role: string): Boolean => {
  return role === ROLES.SUPER_ADMIN
}

const isOrganizationAdmin = (role: string): Boolean => {
  return role === ROLES.ORGANIZATION_ADMIN
}

const adminValidationIgnore = (role: string): Boolean => {
  return (isSuperAdmin(role) || isOrganizationAdmin(role))
}

const makeValidations = () => ({
  name: (value: string) => (!Boolean(value) ? ERRORS.missingField : ''),
  lastname: (value: string) => (!Boolean(value) ? ERRORS.missingField : ''),
  email: (value: string) => (!Boolean(value) ? ERRORS.missingField : !emailValidator(value) ? ERRORS.invalidEmail : ''),
  organizationId: (value: string, role: string) =>
    (Boolean(role) && !Boolean(value) && !isSuperAdmin(role)) ? ERRORS.missingField : '',
  role: (value: string) => (!Boolean(value) ? ERRORS.missingField : ''),
  permissions: (value: object, role: string) => {
    return adminValidationIgnore(role) ? '' : Object.values(value).some(value => value === true)
      ? ''
      : ERRORS.invalidPermissions;
  },
  tagGroups: (value: TagGroupUpdatePayloadModel[], role: string) =>
    adminValidationIgnore(role) ? '' : !value.length ? ERRORS.missingField : ''
})

const formReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'changeFields':
      return {
        ...state,
        values: { ...state.values, ...action.payload },
      }
    case 'changeField':
      return {
        ...state,
        values: { ...state.values, [action.field]: action.payload },
      }

    case 'changeRole': {
      if (action.payload === ROLES.SUPER_ADMIN) {
        return {
          ...state,
          values: {
            ...state.values,
            [action.field]: action.payload,
            organizationId: '',
            tagGroups: [],
          },
        }
      }

      if (action.payload === ROLES.ORGANIZATION_ADMIN) {
        return {
          ...state,
          values: {
            ...state.values,
            [action.field]: action.payload,
            tagGroups: [],
          },
        }
      }

      return {
        ...state,
        values: { ...state.values, [action.field]: action.payload },
      }
    }

    case 'changeOrganization': {
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.payload,
          tagGroups: [],
        },
      }
    }

    case 'updateErrors':
      return {
        ...state,
        errors: { ...action.payload },
      }

    case 'updateError':
      return {
        ...state,
        errors: { ...state.errors, [action.field]: action.payload },
      }
    case 'updateGroups': {
      const groupExist = state.values.tagGroups.some((item: any) => item.uuid === action.payload.id)

      if (groupExist) {
        return state
      }

      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: [...state.values.tagGroups, {
            uuid: action.payload.id,
            name: action.payload.label,
          }],
        },
      }
    }
    case 'deleteGroup': {
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: state.values.tagGroups.filter((group: any) => group !== action.payload),
        },
      }
    }
    case 'setDefaultPermissions':
      return {
        ...state,
        values: {
          ...state.values,
          permissions: action.payload.reduce((permissionsObject: any, permission: PermissionsType) => {
            permissionsObject[permission.value] = permission.checked ?? false
            return permissionsObject
          }, {}),
        }
      }
    case 'updatePermission':
      return {
        ...state,
        values: {
          ...state.values,
          permissions: {
            ...state.values.permissions,
            [action.payload.value]: action.payload.checked,
          }
        }
      }
    default:
      return state
  }
}

const calculateInitialState = (organizationId: string) => ({
  values: {
    name: '',
    lastname: '',
    email: '',
    role: ROLES.BASE,
    organizationId: organizationId ?? '',
    permissions: {},
    tagGroups: [],
  },
  errors: {
    name: '',
    lastname: '',
    email: '',
    role: '',
    organizationId: '',
    permissions: '',
    tagGroups: '',
  },
})

export default function useUserForm (
  organizationId: string,
  { successCallback, initialState = null }: { successCallback: (values: object) => void, initialState: object | null },
) {

  const validations: any = makeValidations()

  const [form, dispatchForm] = useReducer(
    formReducer,
    initialState ?? calculateInitialState(organizationId),
  )

  const [shouldValidateForm, setShouldValidateForm] = useState(false)

  const validateField = useCallback(
    (event: any) => {
      if (!shouldValidateForm) {
        return
      }

      const name = event.target.name
      const value = event.target.value
      const result = validations[name](value)

      dispatchForm({
        type: 'updateError',
        field: name,
        payload: result,
      })
    },
    [validations, shouldValidateForm],
  )

  const validateForm = useCallback(() => {
    const errors = { ...form.errors }

    errors.name = validations.name(form.values.name)
    errors.lastname = validations.lastname(form.values.lastname)
    errors.email = validations.email(form.values.email)
    errors.organizationId = validations.organizationId(
      form.values.organizationId, form.values.role
    )
    errors.role = validations.role(form.values.role)
    errors.permissions = validations.permissions(form.values.permissions, form.values.role)
    errors.tagGroups = validations.tagGroups(form.values.tagGroups, form.values.role)
    
    dispatchForm({
      type: 'updateErrors',
      payload: errors,
    })

    return Object.values(errors).every(error => error === '')
  }, [validations, form.values, form.errors])

  const changeField = (event: any) => {
    dispatchForm({
      type: 'changeField',
      field: event.target.name,
      payload: event.target.value,
    })
  }

  const changeMultipleField = useCallback((name: string, value: string) => {
    dispatchForm({
      type: 'changeField',
      field: name,
      payload: value,
    })
  }, [])

  const updatePermission = useCallback((permissions: any) => {
    dispatchForm({
      type: 'updatePermission',
      payload: permissions,
    });
  }, []);

  const updateGroupsOptions = useCallback((organizationUUID: string) => {
    dispatchForm({
      type: 'updateGroups',
      fieldName: 'tagGroups',
      payload: organizationUUID,
    })
  }, [])

  const deleteGroupOption = useCallback((group: object) => {
    dispatchForm({
      type: 'deleteGroup',
      fieldName: 'tagGroups',
      payload: group,
    })
  }, []);

  const changeRole = (value: any) => {
    dispatchForm({
      type: 'changeRole',
      field: 'role',
      payload: value,
    })
  }

  const validateRole = () => {
    if (!shouldValidateForm) {
      return
    }

    const result = validations['role'](form.values.role)
    
    dispatchForm({
      type: 'updateError',
      field: 'role',
      payload: result,
    })
  }

  const changeOrganization = (event: any) => {
    dispatchForm({
      type: 'changeOrganization',
      field: event.target.name,
      payload: event.target.value,
    })
  }

  const setDefaultPermissions = (permissions: PermissionsType[]) => {
    dispatchForm({
      type: 'setDefaultPermissions',
      fieldName: 'permissions',
      payload: permissions,
    })
  }

  const validatePermissions = () => {
    if (!shouldValidateForm) {
      return
    }

    const result = validations['permissions'](form.values.permissions)
    
    dispatchForm({
      type: 'updateError',
      field: 'permissions',
      payload: result,
    })
  }

  const validateTagGroups = () => {
    if (!shouldValidateForm) {
      return
    }

    const result = validations['tagGroups'](form.values.tagGroups)
    
    dispatchForm({
      type: 'updateError',
      field: 'tagGroups',
      payload: result,
    })
  }

  const submit = useCallback(
    (event: any) => {
      event.preventDefault()

      if (!shouldValidateForm) {
        setShouldValidateForm(true)
      }

      const isFormValid = validateForm()

      if (isFormValid) {
        
        successCallback(form.values)
      }
    },
    [validateForm, successCallback, shouldValidateForm, form.values],
  )

  return {
    shouldValidateForm,
    validateField,
    validateForm,
    changeField,
    changeMultipleField,
    submit,
    form,
    dispatchForm,
    updatePermission,
    updateGroupsOptions,
    deleteGroupOption,
    changeRole,
    validateRole,
    changeOrganization,
    setDefaultPermissions,
    validatePermissions,
    validateTagGroups,
  }
}
