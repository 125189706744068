export class FetchNodesError extends Error {
  constructor(readonly endpoint: string) {
  super(`Error fetching nodes from endpoint ${endpoint}`)
    this.name = 'FetchNodesError'
  }
}

export class MissingParamsError extends Error {
  constructor(readonly message: string) {
    super(message)
    this.name = 'MissingParamsError'
  }
}

export class NodeGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in node request from endpoint ${endpoint}`)
    this.name = 'NodeGenericError'
  }
}
