import { useParams } from 'react-router'
import { useLocales } from '../../../locales'
import LocationForm from '../../../sections/dashboard/locations/LocationForm'
import useLocationMutation from '../../../services/location/useLocationMutation'
import useLocationForm from '../../../hooks/useLocationForm'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'


export default function NewLocationPage() {
  const { organizationId } = useParams()
  const mutation = useLocationMutation(organizationId ?? '')
  const form = useLocationForm({ successCallback: mutation.create })
  const { translate } = useLocales()

  return (
    <>
      <LocationForm form={form} organizationId={organizationId ?? ''} />
      <SnackbarAlert
        isOpen={mutation.loading}
        type={SnackBarAlertTypes.INFO}
        message={`${translate('new-location:submitting-form-info-message')}`}
      />
      <SnackbarAlert
        isOpen={mutation.error !== null}
        onClose={mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
        message={`${translate('new-location:submit-error-message')}`}
      />
    </>
  )
}
