import { Navigate, useParams } from 'react-router-dom'
import { useAuthContext } from './useAuthContext'
import { ROLES } from '../utils/rolesAndPermissions'
import { useEffect, useState } from 'react'
import LoadingScreen from '../components/loading-screen/LoadingScreen'

export default function PermissionGuard({ permission, children }: { permission: string, children: JSX.Element }) {
  const [authority, setAuthority] = useState({
    loading: true,
    hasAuthorithation: true,
  })
  const { user } = useAuthContext()
  const params = useParams()

  useEffect(() => {
    setAuthority(state => ({ ...state, loading: true }))
    
    if (user) {
      let hasAuthorithation = true
      if (user.role === ROLES.BASE && typeof permission !== 'undefined') {
        hasAuthorithation = user.permissions[permission]
          ? user.permissions[permission]
          : false
      }
      setAuthority({ hasAuthorithation, loading: false })
    }
  }, [user, params, permission])

  if (authority.loading) {
    return (
      <>
        <LoadingScreen />
      </>
    )
  }

  if (!authority.hasAuthorithation && !authority.loading) {
    return <Navigate to={'/403'} replace />
  }

  return <> {children} </>
}
