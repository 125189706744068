import { Box, Card, CardHeader, Typography } from '@mui/material'
import { useLocales } from '../../../locales'
import style from './EmptyCardStyle'
import InfoTooltip from '../../infoTooltip/InfoTooltip'
import Spacer from '../../Spacer/Spacer'

export default function EmptyCard({
  title,
  tooltip,
  height = 200,
}: {
  title?: string
  tooltip?: string
  height?: number | string
}): JSX.Element {
  const { translate } = useLocales()

  const getTooltip = (): JSX.Element => {
    if (!tooltip) {
      return <></>
    }

    return <InfoTooltip info={tooltip} />
  }

  return (
    <Card sx={[{ height: height }, style.content]}>
      <Box sx={style.titleContainer}>
        <CardHeader title={title} sx={style.cardHeader} />
        {getTooltip()}
      </Box>
      <Spacer size={2} />
      <Typography variant="subtitle2">
        {`${translate('anonymous:kpis:no:data')}`}
      </Typography>
    </Card>
  )
}
