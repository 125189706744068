import { useEffect, useState, useCallback } from 'react'

import { fetchOne } from './campaignsService'
import { adaptResponseToFormValues } from './adapters'

export default function useCampaign(organizationId, campaignId) {
  const [state, setState] = useState({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchOne(organizationId, campaignId)

      // TODO: devolver la campaña sin la propiedad `_id`?
      // @jira: https://redradix.atlassian.net/browse/SO69-395
      delete response.data._id

      setState({
        data: adaptResponseToFormValues(response),
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: null,
        loading: false,
        error,
      })
    }
  }, [organizationId, campaignId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    refetch: fetchData,
  }
}
