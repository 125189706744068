const OrzanizationFluxBreadcrumbsStyle = {
  loadText: {
    color: 'grey.600'
  },
  pathText: {
    color: 'text.primary'
  }
}

export default OrzanizationFluxBreadcrumbsStyle
