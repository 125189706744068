import TaxonomyModel from './model/TaxonomyModel';
import TaxonomyTagModel from './model/TaxonomyTagModel';
import TaxonomyCreatePayloadModel from './model/TaxonomyCreatePayloadModel';
import TaxonomyUpdatePayloadModel from './model/TaxonomyUpdatePayloadModel';
import { generateUUID } from '../../utils/uuidGenerator';

export function adaptTaxonomyFormValuesToCreatePayload(payload: any): TaxonomyCreatePayloadModel {
  return new TaxonomyCreatePayloadModel(
    generateUUID(),
    payload.name,
    payload.tags.map((tag: TaxonomyTagModel) => tag)
  )
}

export function adaptTaxonomyFormValuesToUpdatePayload(payload: any): TaxonomyUpdatePayloadModel {
  return new TaxonomyUpdatePayloadModel(
    payload.name,
    payload.tags.map((tag: TaxonomyTagModel) => tag)
  )
}

export function adaptTaxonomyResponse(response: any): TaxonomyModel {
  return new TaxonomyModel(
    response.uuid,
    response.name,
    response.tags.map((tag: TaxonomyTagModel) => new TaxonomyTagModel(tag.uuid, tag.value)),
  )
}

export function adaptTaxonomiesResponse(response: any): TaxonomyModel[] {
  return response.map((taxonomy: TaxonomyModel) => adaptTaxonomyResponse(taxonomy))
}
