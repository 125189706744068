import { useTheme } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import Iconify from '../../../components/iconify'
import { useSettingsContext } from '../../../components/settings'
import NavToggleButtonStyle from './NavToggleButtonStyle'

export default function NavToggleButton({
  isCollapsed = false,
} : {
  isCollapsed?: boolean,
}) {
  const theme = useTheme()
  const style = NavToggleButtonStyle(theme, isCollapsed)
  const { themeLayout, onToggleLayout } = useSettingsContext()
  const isDesktop = useResponsive('up', 'lg')
  const IconifyComponent = Iconify as any;

  if (!isDesktop) {
    return null
  }

  return (
    <IconButton
      size="small"
      onClick={onToggleLayout}
      sx={style.container}
    >
      <IconifyComponent
        width={16}
        icon={
          themeLayout === 'vertical'
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
      />
    </IconButton>
  )
}
