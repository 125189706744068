import { Box, LinearProgress } from '@mui/material'
import style from './TableLoaderStyle'

export default function TableLoader({
  colspan = 6,
 } : {
  colspan?: number,
 }) {
  return (
    <tr>
      <Box
        sx={style.container}
        component="td"
        colSpan={colspan}
      >
        <LinearProgress />
      </Box>
    </tr>
  )
}
