import { useEffect } from 'react'
import { useParams } from 'react-router'

import { Button, Snackbar, Alert, Typography, Stack } from '@mui/material'

import useCampaignForm from '../../hooks/useCampaignForm'
import { useLocales } from '../../locales'
import CampaignForm from '../../sections/dashboard/campaigns/Form'
import useCampaign from '../../services/campaigns/useCampaign'
import useCampaignMutation from '../../services/campaigns/useCampaignMutation'
import calculateCampaignStatus from '../../utils/calculateCampaignStatus'
import calculateCampaignStatusColor from '../../utils/calculateCampaignStatusColor'

export default function EditCampaign() {
  const { translate } = useLocales()
  const { organizationId, brandId, campaignId } = useParams()
  const mutation = useCampaignMutation(organizationId, brandId)
  const campaign = useCampaign(organizationId, campaignId)

  const { changeFields, ...form } = useCampaignForm({
    successCallback: mutation.update(campaignId)({
      paused: campaign.data?.paused,
    }),
  })

  useEffect(() => {
    if (campaign.data !== undefined) {
      changeFields(campaign.data)
    }
  }, [campaign.data, changeFields])

  const handlePause = () => {
    mutation.togglePause(campaignId, {
      ...campaign.data,
      paused: true,
    })
  }

  const handleReactivation = () => {
    mutation.togglePause(campaignId, {
      ...campaign.data,
      paused: false,
    })
  }

  const renderSwitchStatusSlot = () => {
    if (campaign.data?.status === 'live' && !campaign.data?.paused) {
      return (
        <Button
          variant="contained"
          sx={{ backgroundColor: 'grey.200', color: 'grey.900' }}
          onClick={handlePause}
        >
          {translate('campaign:pause')}
        </Button>
      )
    }

    if (campaign.data?.status === 'live' && campaign.data?.paused) {
      return (
        <Button
          variant="contained"
          sx={{ backgroundColor: 'grey.200', color: 'grey.900' }}
          onClick={handleReactivation}
        >
          {translate('campaign:activate')}
        </Button>
      )
    }
  }

  const renderStatusSlot = () => {
    const status = calculateCampaignStatus(campaign.data)
    const statusColor = calculateCampaignStatusColor(status)

    return (
      <Stack
        sx={{
          backgroundColor: statusColor?.backgroundColor,
          color: statusColor?.color,
          fontSize: '0.75rem',
          fontWeight: 700,
          padding: '0.5rem',
          borderRadius: '0.5rem',
          textTransform: 'capitalize',
        }}
      >
        <Typography variant="body">
          {translate(`campaign:status:${status}`)}
        </Typography>
      </Stack>
    )
  }

  return (
    <>
      <CampaignForm
        editing
        completed={campaign.data?.status === 'completed'}
        form={form}
        organizationId={organizationId}
        brandId={brandId}
        statusSlot={renderStatusSlot()}
        switchStatusSlot={renderSwitchStatusSlot()}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={mutation.error !== null}
        onClose={mutation.clearError}
        severity={'error'}
      >
        <Alert
          severity={'error'}
          onClose={mutation.clearError}
          sx={{ width: '100%' }}
        >
          {translate('new-campaign:error-dates-already-active')}
        </Alert>
      </Snackbar>
    </>
  )
}
