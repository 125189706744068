import { useState } from 'react'
import { useNavigate } from 'react-router'
import { PATH_ACCOUNTS } from '../../routes/paths'
import { createNode, updateNode } from './nodeService'
import { adaptNodeFormValuesToCreatePayload, adaptNodeFormValuesToUpdatePayload } from './nodeAdapter'

function useNodeMutation(): object {
  const navigate = useNavigate()

  const [state, setState] = useState({
    success: false,
    loading: false,
    error: null,
  })

  const create =(organizationUUID: string) => async (formValues: any): Promise<void> => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {
      await createNode(adaptNodeFormValuesToCreatePayload(formValues))

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.taxonomies(organizationUUID)}?created=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const update = (nodeUUID: string) => async (formValues: any): Promise<void> => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {
      await updateNode(
        nodeUUID,
        adaptNodeFormValuesToUpdatePayload(formValues),
      )

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.nodes}?updated=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }


  const clearError = (): void => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    create,
    update,
    clearError,
  }
}

export default useNodeMutation
