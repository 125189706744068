import { useLocales } from "../../../locales"
import SnackbarAlert from "../../alert/SnackbarAlert/SnackbarAlert"
import SnackBarAlertTypes from "../../alert/SnackbarAlert/SnackBarAlertTypes"

export default function FormRequestActions({
  isLoading = false,
  isSaving = false,
  isUpdating = false,
  errorMessage = null,
  closeRequestError,
  closeRequestMessage,
}: {
  isLoading?: boolean,
  isSaving?: boolean,
  isUpdating?: boolean,
  errorMessage?: string | null,
  closeRequestError: () => void,
  closeRequestMessage?: () => void,
}) {
  const { translate } = useLocales()

  const getRequestMessage = () => {
    if (isLoading) {
      return `${translate('loading')}`
    }

    if (isSaving) {
      return `${translate('saving')}`
    }

    if (isUpdating) {
      return `${translate('updating')}`
    }

    return null
  }

  return (
    <>
      <SnackbarAlert
        isOpen={errorMessage !== null}
        message={errorMessage as string}
        onClose={closeRequestError}
        type={SnackBarAlertTypes.ERROR}
      />
      <SnackbarAlert
        isOpen={getRequestMessage() !== null}
        message={getRequestMessage() as string}
        onClose={closeRequestMessage}
        type={SnackBarAlertTypes.INFO}
      />
    </>
  )
}
