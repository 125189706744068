import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Card, CardHeader, Box, Chip } from '@mui/material'
import Chart, { useChart } from '../../../components/chart'

EcommerceYearlySales.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

const StyledChart = styled('div')(({ theme }) => ({
  '& .apexcharts-tooltip': {
    color: 'black',
  },
}))

export default function EcommerceYearlySales({
  title,
  subheader,
  granularity,
  chart,
  ...other
}) {
  const { colors, categories, series, options } = chart

  const chartOptions = useChart({
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories,
    },
    ...options,
  })

  return (
    <Card {...other}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ pr: 3 }}
      >
        <CardHeader title={title} subheader={subheader} />
        {granularity ? (
          <Chip
            sx={{
              borderRadius: '6px',
              backgroundColor: 'disabled',
              fontSize: '14px',
              fontWeight: '600',
            }}
            label={granularity}
          />
        ) : null}
      </Box>

      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <StyledChart dir="ltr">
          <Chart
            type="area"
            series={series}
            options={chartOptions}
            height={364}
          />
        </StyledChart>
      </Box>
    </Card>
  )
}
