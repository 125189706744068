import { PATH_ACCOUNTS, PATH_DASHBOARD } from "../routes/paths"
import PermissionsValue from "../services/common/enum/PermissionsValues"

const userDashboardRedirect = (user: any, organizationId: string, groupId: string) => {
  if (user.permissions[PermissionsValue.ANONYMOUS_PERMISSION]) {
    return PATH_DASHBOARD.anonymous(organizationId, groupId)
  }
  if (user.permissions[PermissionsValue.REGISTERED_PERMISSION]) {
    return PATH_DASHBOARD.registers(organizationId, groupId)
  }
  //TODO: ADD HERE CAPTATION REDIRECT WHEN ITS DONE
  return PATH_ACCOUNTS.locations()
}

export default userDashboardRedirect
