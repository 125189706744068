const base64Conversor = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (): void => {
    const result = reader.result as string;
    resolve(result);
  };

  reader.onerror = (): void => {
    reject(new Error('Error al leer el archivo'));
  };

  reader.readAsDataURL(file);
});

export default base64Conversor;
