//TODO: Uncomment when backend its ready to receive permissions
// import { PermissionsType } from '../permissions/types/PermissionType';
import { ROLES } from '../../utils/rolesAndPermissions';
import UserFormModel from './model/UserFormModel';
import UserModel from './model/UserModel'
import UserPayloadModel from './model/UserPayloadModel';
// import UserPayloadModel from './model/UserPayloadModel'

//TODO: Uncomment when backend its ready to receive permissions
// const transformPermissions = (permissions: PermissionsType[]): Record<string, string[]> => {
//   return permissions.reduce((accumulatedPermissions, currentPermission) => {
//     const permissionName = currentPermission.permission.split('.')[0];
//     let permissionValue = currentPermission.value ? ['write'] : ['read'];
//     if (permissionName === 'application') {
//       permissionValue = ["read-economic-data"];
//     }
//     accumulatedPermissions[permissionName] = permissionValue;

//     return accumulatedPermissions;
//   }, {} as Record<string, string[]>);
// }


export function adaptUserFormValuesToRequestPayload(payload: UserFormModel, uuid?: string): object {
  const permissions = (payload.role === ROLES.SUPER_ADMIN || payload.role === ROLES.ORGANIZATION_ADMIN)
    ? undefined
    : payload.permissions;

  return new UserPayloadModel(
    payload.email,
    payload.name,
    payload.lastname,
    payload.password,
    payload.role,
    Boolean(payload.organizationId) ? payload.organizationId : null,
    payload.tagGroups.map((group: any) => group.uuid),
    permissions,
    uuid
  );
}

function adaptUserResponse(response: any): object {
  return new UserModel(
    response.organizationUUID,
    response.email,
    response.lastName,
    response.name,
    response.organization,
    response.role,
    response.permissions,
    response.uuid
  )
}

export function adaptUsersResponse(response: any): UserModel[] {
  return response.map((user: UserModel) => adaptUserResponse(user))
}
