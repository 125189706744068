const AnonymousKPIsStyle = {
  percentCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    px: 2,
  },
  variant: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
  }
}

export default AnonymousKPIsStyle
