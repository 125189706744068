import axios from '../../utils/axios'

import { InvalidNewLocationPayloadError, TaxonomyNotExistsError } from './Error'
import LocationPayloadModel from './model/LocationPayloadModel'
import UpdateLocationPayloadModel from './model/UpdateLocationPayloadModel'

const TAXONOMY_NOT_EXISTS_ERROR = 'TaxonomyNotExistsError';

export async function createLocation(payload: LocationPayloadModel) {
  const endpoint = `/api/v1/organizations/${payload.organizationUUID}/locations`

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.status === 400) {
      throw new InvalidNewLocationPayloadError(endpoint)
    }

    throw new Error('Unknown error')
  }
}

async function fetchLocationsByOrganizationId(organizationId: string) {
  try {
    const response = await axios.get(
      `/api/v1/organizations/${organizationId}/locations`,
    )

    return response
  } catch (error) {
    console.log('error!', error)
  }
}

async function fetchLocationById(organizationId: string, locationId: string) {
  try {
    const response = await axios.get(`/api/v1/organizations/${organizationId}/locations/${locationId}`)

    return response
  } catch (error) {
    console.log('error!', error)
  }
}

async function updateLocation(payload: UpdateLocationPayloadModel, locationId: string, organizationUUID: string) {
  const endpoint = `/api/v1/organizations/${organizationUUID}/locations/${locationId}`

  try {
    await axios.patch(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.data.name === TAXONOMY_NOT_EXISTS_ERROR) {
      throw new TaxonomyNotExistsError(endpoint)
    }

    throw new Error('Unknown error '+ error)
  }
}

async function removeLocation(id: string, organizationId: string) {
  await axios.delete(`/api/v1/organizations/${organizationId}/locations/${id}`)
}

export {
  fetchLocationsByOrganizationId,
  fetchLocationById,
  updateLocation,
  removeLocation,
}
