import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import style from './UserFormStyle'
import formStyle from '../../../../style/components/FormStyle'

import { useLocales } from '../../../../locales'
import { Autocomplete, Box, Card, Chip, MenuItem } from '@mui/material'
import { ROLES } from '../../../../utils/rolesAndPermissions'
import SecondaryButton from '../../../../components/button/SecondaryButton/SecondaryButton'
import PrimaryButton from '../../../../components/button/PrimaryButton/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import CreateUserPermissions from '../../../../components/create-user-permissions/CreateUserPermissions'
import { ChangeEvent } from 'react'
import SelectType from '../../../../services/common/types/SelectType'
import useOrganizationSelector from '../../../../services/organizations/useOrganizationsSelector'

const SUPER_ADMIN_ROLES = [
  ROLES.SUPER_ADMIN,
  ROLES.ORGANIZATION_ADMIN,
  ROLES.BASE,
]

const ORGANIZATION_ADMIN_ROLES = [ROLES.ORGANIZATION_ADMIN, ROLES.BASE]

export default function UserForm({
  values,
  errors,
  tagGroups,
  submitLabel,
  changeField,
  changeRole,
  changeOrganization,
  updatePermission,
  validateField,
  validatePermissions,
  validateTagGroups,
  validateRole,
  submit,
  updateGroupsOptions,
  deleteGroupOption,
  isEditView = false,
  isOrganizationView = false,
}: {
  values: any
  errors: any
  tagGroups: SelectType[] | null | undefined
  submitLabel: string
  changeField: (event: ChangeEvent<HTMLInputElement>) => void
  changeRole: (value: any) => void
  changeOrganization: (event: ChangeEvent<HTMLInputElement>) => void
  updatePermission: (permission: { value: string; checked: boolean }) => void
  validateField: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
  ) => void
  validatePermissions: () => void
  validateTagGroups: () => void
  validateRole: () => void,
  submit: (event: any) => void
  updateGroupsOptions: (value: any) => void
  deleteGroupOption: (value: any) => void
  isEditView: boolean
  isOrganizationView: boolean
}) {
  const { translate } = useLocales()
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const organizations = useOrganizationSelector(isOrganizationView, organizationId as string) as any

  const getTagsGroups = () => {
    if (values?.tagGroups?.length === 0) {
      return <></>
    }

    return (
      <Stack sx={style.tagChipContainer}>
        {values.tagGroups.map((tagGroup: any, index: number) => (
          <Chip
            key={`${index}-${tagGroup.id}`}
            variant="outlined"
            label={tagGroup.name}
            onDelete={() => deleteGroupOption(tagGroup)}
          />
        ))}
      </Stack>
    )
  }

  const getAvailableRoles = () => {
    return isOrganizationView ? ORGANIZATION_ADMIN_ROLES : SUPER_ADMIN_ROLES
  }

  const getRoleSelector = () => {
    return (
      <Autocomplete
        options={getAvailableRoles()}
        fullWidth
        value={values.role || null}
        onChange={(_, value) => {
          changeRole(value)
        }}
        onBlur={validateRole}
        getOptionLabel={option => `${translate(`role.${option}`)}` || ''}
        renderInput={params => (
          <TextField
            {...params}
            label={`${translate('new-user:form:role-label')}`}
            name="role"
            value={values.role}
            error={errors.role !== ''}
            helperText={errors.role}
          />
        )}
      />
    )
  }

  const getOrganizations = () => {
    return organizations.loading ? (
      <MenuItem value="loading">
        {`${translate('new-user:form:organization-label:loading')}`}
      </MenuItem>
    ) : (
      organizations.data?.map((organization: any) => (
        <MenuItem key={organization.uuid} value={organization.uuid}>
          {organization.name}
        </MenuItem>
      )) ?? <></>
    )
  }

  const getPassword = () => {
    if (isEditView) {
      return <></>
    }

    return (
      <TextField
        fullWidth
        disabled
        variant="outlined"
        name="password"
        label={`${translate('new-user:form:password-label')}`}
        value={values.password}
        helperText={`${translate('new-user:form:password-helper')}`}
      />
    )
  }

  const getOrganizationName = (organizationId: string) => {
    if (!organizations?.data) {
      return ''
    }

    return organizations?.data?.name
  }

  const getOrganizationSelector = () => {
    if (values.role === ROLES.SUPER_ADMIN) {
      return <></>
    }

    if (isOrganizationView) {
      return (
        <TextField
          fullWidth
          disabled
          label={`${translate('new-user:form:organization-label')}`}
          name="organizationId"
          value={getOrganizationName(values.organizationId)}
        />
      )
    }

    return (
      <TextField
        fullWidth
        select
        label={`${translate('new-user:form:organization-label')}`}
        name="organizationId"
        value={values.organizationId}
        onChange={changeOrganization}
        error={errors.organizationId !== ''}
        helperText={errors.organizationId}
        onBlur={validateField}
      >
        <MenuItem value="">
          <em>{`${translate('empty:organization:selector')}`}</em>
        </MenuItem>
        {getOrganizations()}
      </TextField>
    )
  }

  const getGroupsSelector = () => {
    if (
      values.role === ROLES.SUPER_ADMIN ||
      values.role === ROLES.ORGANIZATION_ADMIN
    ) {
      return <></>
    }

    return (
      <>
        <Autocomplete
          fullWidth
          disabled={!values.organizationId}
          options={tagGroups || []}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          disableClearable
          inputValue=""
          onChange={(_, value) => updateGroupsOptions(value)}
          onBlur={validateTagGroups}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              {...params}
              label={`${translate('new-user:groups-label')}`}
              helperText={errors.tagGroups}
              error={!!errors.tagGroups}
              name="groups"
            />
          )}
        />
        {getTagsGroups()}
      </>
    )
  }

  const getUserPermissions = (): JSX.Element => {
    if (
      values.role === ROLES.SUPER_ADMIN ||
      values.role === ROLES.ORGANIZATION_ADMIN
    ) {
      return <></>
    }
    return (
      <Box sx={style.permissionContainer}>
        <CreateUserPermissions
          handleChangePermissions={updatePermission}
          onBlur={validatePermissions}
          organizations={organizations?.data}
          error={errors.permissions}
          form={values}
        />
      </Box>
    )
  }

  return (
    <Stack sx={formStyle.container}>
      <Card sx={formStyle.card}>
        <Stack sx={formStyle.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            sx={style.formField}
            name="name"
            label={`${translate('new-user:form:name-label')}`}
            value={values.name}
            onChange={changeField}
            error={errors.name !== ''}
            helperText={errors.name}
            onBlur={validateField}
          />
          <TextField
            fullWidth
            variant="outlined"
            sx={style.formField}
            name="lastname"
            label={`${translate('new-user:form:lastname-label')}`}
            value={values.lastname}
            onChange={changeField}
            error={errors.lastname !== ''}
            helperText={errors.lastname}
            onBlur={validateField}
          />
          <TextField
            fullWidth
            variant="outlined"
            sx={style.formField}
            name="email"
            label={`${translate('new-user:form:email-label')}`}
            value={values.email}
            onChange={changeField}
            error={errors.email !== ''}
            helperText={errors.email}
            onBlur={validateField}
          />

          {getPassword()}
          {getRoleSelector()}
          {getOrganizationSelector()}
          {getGroupsSelector()}
          {getUserPermissions()}
        </Stack>
      </Card>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <SecondaryButton onClick={() => navigate(-1)}>
          {`${translate('action:cancel')}`}
        </SecondaryButton>
        <PrimaryButton onClick={submit}>{submitLabel}</PrimaryButton>
      </Stack>
    </Stack>
  )
}
