import TaxonomyTagModel from "../taxonomies/model/TaxonomyTagModel"
import TagGroupModel from "./model/TagGroupModel"
import { TagGroupModelApiInterface } from "./interfaces/TagGroupModelApiInterface";
import { TaxonomyTagGroupApiModelInterface } from "./interfaces/TaxonomyTagGroupApiModelInterface";
import TaxonomyTagGroupPayloadModel from "./model/TaxonomyTagGroupPayloadModel";
import TagGroupSingleModel from "./model/TagGroupSingleModel";
import TaxonomyTagGroupModel, { TaxonomyTagGroupLocationModel } from "./model/TaxonomyTagGroupModel";
import TagGroupCreatePayloadModel from "./model/TagGroupCreatePayloadModel";
import TagGroupUpdatePayloadModel from "./model/TagGroupUpdatePayloadModel";
import { generateUUID } from "../../utils/uuidGenerator";
import { TaxonomyTagGroupLocationApiInterface } from "./interfaces/TaxonomyTagGroupLocationApiInterface";

export function adaptTagGroupFormValuesToCreatePayload(payload: any): TagGroupCreatePayloadModel {
  return new TagGroupCreatePayloadModel(
    generateUUID(),
    payload.name,
    payload.organizationUUID,
    payload.tags.map((tag: TaxonomyTagGroupModel) => new TaxonomyTagGroupPayloadModel(
      tag.tagUUID,
      tag.taxonomyUUID,
    ))
  )
}

export function adaptTagGroupFormValuesToUpdatePayload(payload: any): TagGroupUpdatePayloadModel {
  return new TagGroupUpdatePayloadModel(
    payload.name,
    payload.tags.map((tag: TaxonomyTagGroupModel) => new TaxonomyTagGroupPayloadModel(
      tag.tagUUID,
      tag.taxonomyUUID,
    ))
  )
}

export function adaptTagGroupResponse(response: TagGroupModelApiInterface): TagGroupModel {
  return new TagGroupModel(
    response.uuid,
    response.name,
    response.organizationUUID,
    response.taxonomyTags.map((tag: TaxonomyTagGroupApiModelInterface) => new TaxonomyTagModel(tag.taxonomyTagUUID, tag.taxonomyTagValue)),
  )
}

export function adaptTagGroupSingleResponse(response: TagGroupModelApiInterface): TagGroupSingleModel {
  return new TagGroupSingleModel(
    response.uuid,
    response.name,
    response.organizationUUID,
    response.taxonomyTags.map((tag: TaxonomyTagGroupApiModelInterface) => new TaxonomyTagGroupModel(
      tag.taxonomyUUID,
      tag.taxonomyName,
      tag.taxonomyTagUUID,
      tag.taxonomyTagValue,
      tag.locations.map((location: TaxonomyTagGroupLocationApiInterface) => new TaxonomyTagGroupLocationModel(
        location.uuid,
        location.name,
      ))
    )),
  )
}

export function adaptTagGroupsResponse(response: TagGroupModelApiInterface[]): TagGroupModel[] {
  return response.map((tagGroup: TagGroupModelApiInterface) => adaptTagGroupResponse(tagGroup))
}
