import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, MenuItem } from '@mui/material'

import { PATH_AUTH } from '../../routes/paths'
import { useLocales } from '../../locales'
import { useAuthContext } from '../../auth/useAuthContext'

import { CustomAvatar } from '../custom-avatar'
import { useSnackbar } from '../snackbar'
import MenuPopover from '../menu-popover'
import { IconButtonAnimate } from '../animate'

export default function AccountPopover() {
  const navigate = useNavigate()
  const { translate } = useLocales()

  const { user, logout } = useAuthContext()

  const { enqueueSnackbar } = useSnackbar()

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleLogout = async () => {
    try {
      logout()
      navigate(PATH_AUTH.login, { replace: true })
      handleClosePopover()
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              border: theme => `1px solid ${theme.palette.primary.main}`,
            },
          }),
        }}
      >
        <CustomAvatar
          src={user.photo}
          alt={user.name}
          name={`${user.name} ${user.lastname}`}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user.name} ${user.lastname}`}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <MenuItem onClick={handleLogout} sx={{ m: 1, mt: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
            {translate('avatar.logout')}
          </Typography>
        </MenuItem>
      </MenuPopover>
    </>
  )
}
