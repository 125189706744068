const SEPARATION = 3

const TagGroupFormStyle = {
  container: {
    gap: SEPARATION,
  },
  card: {
    padding: SEPARATION,
  },
  formContainer: {
    alignItems: "flex-start",
    gap: SEPARATION,
  },
  tagBlock: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tagTextfield: {
    width: '50%',
  },
  tagChipContainer: {
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap"
  }
}

export default TagGroupFormStyle
