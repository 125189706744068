import {
  Business,
  ContactPage,
  Store,
  Label,
  CellTower,
  JoinLeft,
} from '@mui/icons-material'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../../auth/useAuthContext'
import { PATH_ACCOUNTS } from '../../routes/paths'
import { ROLES } from '../../utils/rolesAndPermissions'
import { NavigationSectionType } from '../../services/common/types/NavigationSectionType'

 const ICONS: { [key: string]: JSX.Element } = {
  clients: <Business />,
  users: <ContactPage />,
  stores: <Store />,
  label: <Label />,
  group: <JoinLeft />,
  nodes: <CellTower />,
}

export default function useNavLinksBackoffice() {
  const { organizationId } = useParams()
  const { user } = useAuthContext() as any
  const { pathname } = useLocation()

  const superAdminSections: NavigationSectionType[] = [
    {
      subheader: 'nav:accounts:subheader',
      items: [
        {
          title: 'nav:accounts:organizations',
          path: PATH_ACCOUNTS.organizations,
          icon: ICONS.clients,
        },
        {
          title: 'nav:accounts:users',
          path: PATH_ACCOUNTS.generalUsers,
          icon: ICONS.users,
        },
        {
          title: 'nav:accounts:nodes',
          path: PATH_ACCOUNTS.nodes,
          icon: ICONS.nodes,
        },
      ],
    }
  ]

  if (
    organizationId === undefined ||
    pathname === PATH_ACCOUNTS.editOrganization(organizationId)
  ) {
    return superAdminSections
  }

  const organizationSections: NavigationSectionType[] = [
    {
      subheader: 'nav:dashboard:subheader',
      items: [
        {
          title: 'nav:accounts:tag:group',
          path: PATH_ACCOUNTS.tagGroups(organizationId),
          icon: ICONS.group,
        },
        {
          title: 'nav:accounts:taxonomies',
          path: PATH_ACCOUNTS.taxonomies(organizationId),
          icon: ICONS.label,
        },
        {
          title: 'nav:dashboard:locations',
          path: PATH_ACCOUNTS.locations(organizationId),
          icon: ICONS.stores,
        },
        {
          title: 'nav:dashboard:users',
          path: PATH_ACCOUNTS.organizationUsers(organizationId),
          icon: ICONS.users,
        },
      ],
    }
  ]
  const userSections =
    [
      {
        subheader: 'nav:dashboard:subheader',
        items: [
          {
            title: 'nav:accounts:tag:group',
            path: PATH_ACCOUNTS.tagGroups(organizationId),
            icon: ICONS.group,
          },
        ],
      }
    ]

  const organizationAdminSections =
    [
      {
        subheader: 'nav:dashboard:subheader',
        items: [
          {
            title: 'nav:accounts:tag:group',
            path: PATH_ACCOUNTS.tagGroups(organizationId),
            icon: ICONS.group,
          },
          {
            title: 'nav:dashboard:users',
            path: PATH_ACCOUNTS.organizationUsers(organizationId),
            icon: ICONS.users,
          },
        ],
      }
    ]

  switch(user.role) {
    case ROLES.BASE:
      return userSections
    case ROLES.SUPER_ADMIN:
      return organizationSections
    case ROLES.ORGANIZATION_ADMIN:
      return organizationAdminSections
    default:
      return []
  }
}
