import { useEffect, useState, useCallback } from 'react'
import { fetchTaxonomies, removeTaxonomy } from './taxonomyService'
import TaxonomyModel from './model/TaxonomyModel'

type TaxonomyStateType = {
  data: TaxonomyModel[] | undefined,
  loading: boolean,
  error: Error | null,
}

function useTaxonomies(organizationUUID: string): object {
  const [state, setState] = useState<TaxonomyStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchTaxonomies(organizationUUID)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  async function remove(organizationUUID: string, taxonomyUUID: string): Promise<void> {
    await removeTaxonomy(organizationUUID, taxonomyUUID)
  }

  return {
    ...state,
    refetch: fetchData,
    remove
  }
}

export default useTaxonomies
