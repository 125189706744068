/**
 * @param {object} formValues - Datos para la creación de la campaña.
 * @param {string} formValues.clientId - ID del cliente al que pertenece la campaña.
 * @param {string} formValues.brandId - ID de la marca a la que pertenece la campaña.
 * @param {string} formValues.name - Nombre de la campaña.
 * @param {string} formValues.description - Descripción de la campaña.
 * @param {string} formValues.status - Estatus de la campaña.
 * @param {Date} formValues.start - Fecha de inicio de la campaña.
 * @param {Date} formValues.end - Fecha de fin de la campaña.
 * @param {string} formValues.text - Mensaje de la campaña.
 * @param {object} formValues.image - URL de la imagen de la campaña.
 * @param {boolean} paused - Indica si la campaña que se está modificando está parada o no.
 */
export function adaptFormValuesToUpdatePayload(formValues, paused) {
  const imageUrl = formValues.image

  delete formValues.image
  delete formValues.status

  return {
    ...formValues,
    paused,
    imageUrl: imageUrl,
    start: new Date(formValues.start),
    end: new Date(formValues.end),
  }
}

/**
 * @param {object} response - Respuesta del back.
 * @param {object} response.data - Datos de la campaña.
 * @param {string} response.data.clientId - ID del cliente al que pertenece la campaña.
 * @param {string} response.data.brandId - ID de la marca a la que pertenece la campaña.
 * @param {string} response.data.name - Nombre de la campaña.
 * @param {string} response.data.description - Descripción de la campaña.
 * @param {string} response.data.start - Fecha de inicio de la campaña.
 * @param {object} response.data.end - Fecha de fin de la campaña.
 * @param {object} response.data.text - Mensaje de la campaña.
 * @param {object} response.data.imageUrl - URL de la imagen de la campaña.
 * @param {object} response.data.paused - Indicador de si la campaña se encuentra en pausa.
 * @param {object} response.data.status - Status de la campaña.
 *
 * @returns {object} Objeto con el esquema del formulario de campañas.
 */
export function adaptResponseToFormValues(response) {
  return {
    name: response.data.name,
    description: response.data.description,
    start: new Date(response.data.start),
    end: new Date(response.data.end),
    text: response.data.text,
    image: response.data.imageUrl,
    paused: response.data.paused,
    status: response.data.status,
    brandId: response.data.brandId,
    clientId: response.data.clientId,
  }
}
