const ChartCardStyle = {
  container: {
    width: '100%',
    py: 3,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    px: 3,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardHeader: {
    p: 0,
  },
}

export default ChartCardStyle
