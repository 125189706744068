import { Theme } from "@mui/material"
import { HEADER, NAV } from "../../../config-global"
import { bgBlur } from "../../../utils/cssStyles"

const ContentLayoutHeaderStyle = (
  theme: Theme,
  isDesktop: boolean,
  isOffset: boolean,
  isNavHorizontal: boolean,
  isNavMini: boolean,
) => {
  return ({
    container: {
      boxShadow: 'none',
      height: HEADER.H_MOBILE,
      zIndex: theme.zIndex.appBar + 1,
      ...bgBlur({
        color: theme.palette.background.default,
      }),
      transition: theme.transitions.create(['height'], {
        duration: theme.transitions.duration.shorter,
      }),
      ...(isDesktop && {
        width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
        height: HEADER.H_DASHBOARD_DESKTOP,
        ...(isOffset && {
          height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        }),
        ...(isNavHorizontal && {
          width: 1,
          bgcolor: 'background.default',
          height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          borderBottom: `dashed 1px ${theme.palette.divider}`,
        }),
        ...(isNavMini && {
          width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
        }),
      }),
    },
    toolbar: {
      height: 1,
      px: { lg: 5 },
    },
    logoComponent: {
      mr: 2.5
    },
    iconButton: {
      mr: 1,
      color: 'text.primary',
    },
  })
}

export default ContentLayoutHeaderStyle
