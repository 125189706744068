import { Grid, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import TaxonomyForm from '../../../sections/dashboard/taxonomy/TaxonomyForm/TaxonomyForm'
import useTaxonomyForm from '../../../hooks/useTaxonomyForm'
import { useLocales } from '../../../locales'
import useTaxonomyMutation from '../../../services/taxonomies/useTaxonomyMutation'
import { useParams } from 'react-router'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { InvalidCreateTaxonomyPayloadError } from '../../../services/taxonomies/taxonomyError'

export default function NewTaxonomy() {
  const { translate } = useLocales()
  const mutation = useTaxonomyMutation() as any
  const { organizationId } = useParams<string>()
  const form = useTaxonomyForm({ successCallback: mutation.create(organizationId!) })

  const getErrorMessage = (): string => {
    if (!mutation.error) {
      return ''
    }

    if (mutation.error instanceof InvalidCreateTaxonomyPayloadError) {
      return `${translate('create:taxonomy:error:already:exist')}`
    }

    return `${translate('create:taxonomy:error:generic')}`
  }

  return (
    <ContainerBase>
      <Typography variant="h4">
        <Trans i18nKey="create:taxonomy:title" />
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <TaxonomyForm form={form} submitButtonLabel={`${translate('create:taxonomy:button')}`} />
        </Grid>
      </Grid>
      <SnackbarAlert
        isOpen={mutation.error !== null}
        message={getErrorMessage()}
        onClose={() => mutation.clearError()}
        type={SnackBarAlertTypes.ERROR}
      />
    </ContainerBase>
  )
}
