import { createSlice } from '@reduxjs/toolkit'

const initialState = { data: {}, loading: true, error: null }

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    updateData: (state, action) => {
      const store = action.payload
      state.data[store.uuid] = store
    },
    updateLoading: (state, action) => {
      state.loading = action.payload
    },
    updateError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const { updateData, updateLoading, updateError } = storesSlice.actions

export default storesSlice.reducer
