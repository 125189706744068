import { Box, Typography } from '@mui/material'
import style from './VariantStyle'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { compactNumberFormatter } from '../../utils/formatNumber'

export default function Variant({ value }: { value: number }) {
  if (typeof value !== 'number') {
    return <></>
  }

  const isIncrement = value >= 0

  return (
    <Box
      sx={{
        ...style.container,
        backgroundColor: isIncrement ? '#E2F6E5' : '#F6DCD8',
      }}
    >
      {isIncrement ? (
        <KeyboardDoubleArrowUpIcon style={style.iconIncrement} />
      ) : (
        <KeyboardDoubleArrowDownIcon style={style.iconDecrement} />
      )}

      <Typography
        variant="h6"
        noWrap
        style={{
          color: isIncrement ? '#479866' : '#D06D68',
        }}
      >
        {/* TODO: Remove when backend returns formated values */}
        {compactNumberFormatter(value.toFixed(2))} %
      </Typography>
    </Box>
  )
}
