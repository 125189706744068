import { useEffect, useState, useCallback } from 'react'
import NodeSingleModel from './model/NodeSingleModel'
import { fetchNode } from './nodeService'

type UseNodeStateType = {
  data: NodeSingleModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useNode(nodeUUID: string): object {
  const [state, setState] = useState<UseNodeStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchNode(nodeUUID)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })

      return Promise.reject(error)
    }

  }, [nodeUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ...state,
    refetch: fetchData,
  }
}

export default useNode
