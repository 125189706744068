
import { Button, useTheme } from '@mui/material'
import FileFilterButtonStyle from './FileFilterButtonStyle'


export default function FileFilterButton({
  children,
  isSelected,
  startIcon,
  onClick,
} : {
  children: React.ReactNode,
  isSelected: boolean,
  startIcon?: any,
  onClick: () => void,
}) {
  const theme = useTheme()
  const style = FileFilterButtonStyle(theme)

  return (
    <Button
      variant="contained"
      color="inherit"
      onClick={onClick}
      startIcon={startIcon}
      sx={[style.container, isSelected ? style.selected : {} ]}
    >
      {children}
    </Button>
  )
}
