import Button from '@mui/material/Button'
import style from './PrimaryButtonStyle'
import Iconify from '../../iconify'
import { ButtonSize } from '../ButtonSize';

export default function PrimaryButton({
  children,
  onClick,
  icon = null,
  iconComponent = null,
  size = ButtonSize.LARGE,
  isDisabled = false,
  fullWidth = false,
} : {
  children?: React.ReactNode,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  icon?: string | null,
  iconComponent?: JSX.Element | null,
  size?: ButtonSize,
  isDisabled?: boolean,
  fullWidth?: boolean,
}): JSX.Element {
  const IconComponent: any = Iconify;

  const getIcon = (): JSX.Element | null => {
    if (icon) {
      return <IconComponent icon={icon} width={16} />
    }

    if (iconComponent) {
      return iconComponent
    }

    return null
  }

  const getIconButtonStyle = (): object => {
    if (!children && (icon || iconComponent)) {
      return style.iconButton
    }

    return {}
  }

  const getFullWidth = (): object => {
    if (!fullWidth) {
      return {}
    }

    return { width: '100%'}
  }

  return (
    <Button
      type="submit"
      variant="contained"
      sx={[style.button, getIconButtonStyle(), getFullWidth()]}
      size={size}
      onClick={onClick}
      startIcon={getIcon()}
      disabled={isDisabled}
    >
      {children}
    </Button>
  )
}
