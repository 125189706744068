import { useState } from "react"
import { Chip, Stack, TextField } from "@mui/material"
import style from './TagManagerStyle'
import TaxonomyTagModel from "../../../services/taxonomies/model/TaxonomyTagModel"
import { useLocales } from "../../../locales"
import PrimaryButton from "../../button/PrimaryButton/PrimaryButton"
import { ButtonSize } from "../../button/ButtonSize"

const EMPTY_TAG = ''

export default function TagManager({
  tags,
  error,
  addTag,
  removeTag,
} : {
  tags: TaxonomyTagModel[],
  error?: string,
  addTag: (newTag: string) => void,
  removeTag: (tag: TaxonomyTagModel) => void,
}): JSX.Element {
  const { translate } = useLocales()
  const [currentTag, setCurrentTag] = useState<string>(EMPTY_TAG)

  const changeTag = (event: { target: { name: string; value: string } }): void => {
    setCurrentTag(event.target.value)
  }

  const handleAddTag = (): void => {
    if (!currentTag || currentTag === EMPTY_TAG) {
      return
    }

    addTag(currentTag)
    setCurrentTag(EMPTY_TAG)
  }

  const getTags = (): JSX.Element => {
    if (!tags || tags?.length === 0) {
      return <></>
    }

    return (
      <Stack sx={style.tagChipContainer}>
        {tags.map((
          tag: TaxonomyTagModel,
          index: number,
        ) =>
          <Chip
            key={`${index}-${tag.value}`}
            variant="outlined"
            label={tag.value}
            onDelete={() => removeTag(tag)}
          />
        )}
      </Stack>
    )
  }

  return (
    <>
      <Stack sx={style.tagContainer}>
        <TextField
          fullWidth
          variant="outlined"
          name="tags"
          label={`${translate('create:taxonomy:form:tag')}`}
          value={currentTag}
          onChange={changeTag}
          error={error !== ''}
          helperText={error}
        />
        <PrimaryButton
          onClick={handleAddTag}
          size={ButtonSize.MEDIUM}
        >
          {`${translate('create:taxonomy:add:tag:button')}`}
        </PrimaryButton>
      </Stack>
      {getTags()}
    </>
  )
}
