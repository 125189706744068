import { useParams } from 'react-router'
import { PATH_DASHBOARD } from '../../routes/paths'

import {
  AccountBox,
  AdminPanelSettings,
  Campaign,
  ConnectWithoutContact,
  Store,
  Update,
  QueryStats,
} from '@mui/icons-material'
import { useLocales } from '../../locales'
import PermissionsValue from '../../services/common/enum/PermissionsValues'
import { NavigationSectionType } from '../../services/common/types/NavigationSectionType'

const ICONS = {
  anonymous: <QueryStats />,
  analytics: <QueryStats />,
  connect: <ConnectWithoutContact />,
  store: <Store />,
  registers: <AccountBox />,
  campaigns: <Campaign />,
  realtime: <Update />,
  admin: <AdminPanelSettings />,
}

export function useNavLinksDashboard(user: any) {
  const { organizationId, groupId } = useParams()
  const { translate } = useLocales()
  if (!user) {
    return []
  }

  const linksDashBoard: NavigationSectionType[] = [
    {
      subheader: `${translate('nav:dashboard:subheader:reports')}`,
      items: [
        {
          title: `${translate('nav:dashboard:anonymous')}`,
          path: PATH_DASHBOARD.anonymous(organizationId, groupId),
          icon: ICONS.anonymous,
        },
      ],
    },
    {
      subheader: `${translate('nav:dashboard:campaign')}`,
      items: [
        {
          title: `${translate('nav:dashboard:campaign:engagement')}`,
          path: PATH_DASHBOARD.campaigns(organizationId, groupId),
          icon: ICONS.campaigns,
        },
      ],
    }
  ]

  const permissions = user.permissions
  if (Object.keys(permissions).length === 0) {
    return []
  }
  
  const userLinksDashBoards: NavigationSectionType[] = [
    ...linksDashBoard.map((links) => {
      return {
        subheader: links.subheader,
        items: [],
      }
    })
  ]
  
  if (permissions[PermissionsValue.ANONYMOUS_PERMISSION]) {
    const link = linksDashBoard[0].items[0];
    if (link){
      userLinksDashBoards[0].items.push(link);
    }
  }

  if (permissions[PermissionsValue.CAPTATION_PERMISSION]) {
    const link = linksDashBoard[1].items;
    if (link){
      userLinksDashBoards[1].items.push(...link);
    }
  }
  
  // TODO: Uncomment when the new screens are ready
  // if (permissions[PermissionsValue.REGISTERED_PERMISSION]) {
  //   const link = linksDashBoard.items[1];
  //   if (link) {
  //     userLinksDashBoards.push(link);
  //   } 
  // }
  

  return userLinksDashBoards
}
