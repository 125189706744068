import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocales } from '../../../locales'
import useNodeForm from '../../../hooks/useNodeForm'
import NodeForm from '../../../sections/dashboard/nodes/NodeForm/NodeForm'
import FormContainer from '../../../components/form/FormContainer/FormContainer'
import useNodeMutation from '../../../services/Nodes/useNodeMutation'
import useNode from '../../../services/Nodes/useNode'
import { MissingParamsError } from '../../../services/Nodes/nodeError'

export default function EditNode() {
  const { translate } = useLocales()
  const mutation = useNodeMutation() as any
  const { nodeId } = useParams<string>()

  const node = useNode(nodeId!) as any
  const { changeFieldsValues, ...form } = useNodeForm({
    successCallback: mutation.update(nodeId!),
  })

  useEffect(() => {
    if (node.data !== undefined) {
      changeFieldsValues(node.data)
    }
  }, [node.data, changeFieldsValues])

  const getErrorMessage = (): string => {
    if (mutation.error instanceof MissingParamsError) {
      return `${translate(mutation.error?.message)}`
    }


    return `${translate('edit:node:error:generic')}`
  }

  return (
    <FormContainer
      title={`${translate('edit:node:title')}`}
      showError={mutation.error !== null}
      errorMessage={getErrorMessage()}
      onCloseError={() => mutation.clearError()}
    >
      <NodeForm
        form={form}
        submitButtonLabel={`${translate('edit:node:button')}`}
      />
    </FormContainer>
  )
}
