import { IconButton, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import style from './InfoTooltipStyle'

export default function InfoTooltip({ info }: { info: string }) {
  return (
    <Tooltip title={info}>
      <IconButton sx={style.button}>
        <InfoOutlined sx={style.icon} />
      </IconButton>
    </Tooltip>
  )
}
