import { FormControl, Grid, InputLabel, TextField, Card, MenuItem } from "@mui/material"
import FormInfoSection from "../../../components/form/FormInfoSection/FormInfoSection"
import { useLocales } from "../../../locales"
import style from './LocationFormStyle'
import getTimezones from '../../../utils/getTimezones'

export default function LocationInfoForm({form}: {form: any}) {
  const { translate } = useLocales()

  return (
    <Grid item container>
    <Grid item xs={4} sx={style.sectionTitleContainer}>
    <FormInfoSection
      title={`${translate('new-location:content-title')}`}
      subtitle={`${translate('new-location:content-description')}`}
    />
    </Grid>

    <Grid item xs={8}>
      <Card sx={style.formContainer}>
        <form>
          <FormControl sx={style.formWrapper}>
            <InputLabel htmlFor="name" />
            <TextField
              fullWidth
              variant="outlined"
              sx={style.inputField}
              name="name"
              label={`${translate('new-location:name-label')}`}
              value={form.values.name}
              onChange={form.changeFieldValue}
              onBlur={form.validateFieldValue}
              error={form.errors.name !== ''}
              helperText={form.errors.name}
            />
            <InputLabel htmlFor="timezone" />
            <TextField
              select
              sx={style.selectField}
              name="timezone"
              label={`${translate('new-location:timezone-label')}`}
              onChange={form.changeFieldValue}
              value={form.values.timezone}
              error={form.errors.timezone !== ''}
              helperText={form.errors.timezone}
            >
              {getTimezones()?.map(timezone => {
                return (
                  <MenuItem key={timezone.value} value={timezone.value}>
                    {timezone.name}
                  </MenuItem>
                )
              })}
            </TextField>
          </FormControl>
        </form>
      </Card>
    </Grid>
  </Grid>
  )
}
