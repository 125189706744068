export default class LocationFormModel {
  constructor(
    readonly uuid: string,
    readonly organizationUUID: string,
    readonly name: string,
    readonly address: string,
    readonly contactName: string,
    readonly contactLastName: string,
    readonly contactPhonePreffix: string,
    readonly contactPhoneNumber: string,
    readonly contactEmail: string,
    readonly timezone: string,
    readonly tags: {
      tagName: string,
      tagUUID: string,
      taxonomyUUID: string
      taxonomyTagValue?: string
    } [],
  ) {}
}
