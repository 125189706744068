const style = {
  table: {
    minWidth: 800
  },
  titleContainer: {
    width: '100%',
  }
}

export default style;
