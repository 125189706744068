import { Theme } from "@mui/material"

const UnsuscribeOrganizationStyle = (theme: Theme) => {
  return ({
    container: {
      minHeight: '100dvh',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
    form: {
      width: '400px',
      textAlign: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflowY: 'scroll',
      maxHeight: '100dvh',
      gap: 3,
      p: 3,
    }
  })
}

export default UnsuscribeOrganizationStyle
