const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  BASE: 'BASE',
}

const PERMISSIONS = {
  ANALYTICS: 'analytics.read',
  CONNECT: 'connect.read',
  STORES: 'stores.read',
  REGISTERS: 'registers.read',
  CAMPAIGN_READ: 'campaign.read',
  CAMPAIGN_WRITE: 'campaign.write',
  REALTIME: 'realtime.read',
  ECONOMIC_DATA: 'application.read-economic-data',
}

export { ROLES, PERMISSIONS }
