import { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import TaxonomyForm from '../../../sections/dashboard/taxonomy/TaxonomyForm/TaxonomyForm'
import useTaxonomyForm from '../../../hooks/useTaxonomyForm'
import { useLocales } from '../../../locales'
import { useParams } from 'react-router'
import useTaxonomy from '../../../services/taxonomies/useTaxonomy'
import useTaxonomyMutation from '../../../services/taxonomies/useTaxonomyMutation'
import { adaptTaxonomyResponse } from '../../../services/taxonomies/taxonomyAdapter'

export default function EditTaxonomy() {
  const { translate } = useLocales()
  const { organizationId, taxonomyId } = useParams<string>()
  const mutation = useTaxonomyMutation() as any

  const taxonomy = useTaxonomy(organizationId!, taxonomyId!) as any
  const { changeFieldsValues, ...form } = useTaxonomyForm({
    successCallback: mutation.update(organizationId!, taxonomyId!),
  })

  useEffect(() => {
    if (taxonomy.data !== undefined) {
      changeFieldsValues(adaptTaxonomyResponse(taxonomy.data))
    }
  }, [taxonomy.data, changeFieldsValues])

  return (
    <ContainerBase>
      <Typography variant="h4">
        <Trans i18nKey="edit:taxonomy:title" />
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <TaxonomyForm form={form} submitButtonLabel={`${translate('edit:taxonomy:button')}`} />
        </Grid>
      </Grid>
    </ContainerBase>
  )
}
