import { Helmet } from 'react-helmet-async'
import { Container, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSettingsContext } from '../settings'
import maintenance from './maintenance.png'
import PropTypes from 'prop-types'

const StyledImage = styled('img')(() => ({
  width: '272px',
  // Note: All template enforces to use a 16px margin-top that overrides
  // every marginTop, so we need to ensure it with !important
  marginTop: '70px!important',
}))

DashboardError.propTypes = {
  title: PropTypes.string.isRequired,
}

export default function DashboardError(props) {
  const { title } = props

  const { themeStretch } = useSettingsContext()
  return (
    <>
      <Helmet>
        <title> {title} | So69</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="h4"
            sx={{ mt: '109px' }}
            maxWidth="275px"
            textAlign="center"
          >
            Well, something didn't go as expected!
          </Typography>
          <Typography
            variant="p"
            marginTop="0"
            color="grey"
            maxWidth="400px"
            textAlign="center"
            fontSize="14px"
          >
            We have not been able to recover the data. Please try again in a few
            minutes. If the error persists, please contact us.
          </Typography>
          <StyledImage
            src={maintenance}
            alt="A woman holding gears and a computer screen.	"
          />
        </Stack>
      </Container>
    </>
  )
}
