import { useEffect, useState, useCallback } from 'react'

import { fetchOrganizations, removeOrganization } from './service'

function useOrganizations(): object {
  const [state, setState] = useState({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState(state => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchOrganizations()

      setState({
        data: response.data,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  async function remove(id: string): Promise<void> {
    await removeOrganization(id)
  }

  return {
    ...state,
    refetch: fetchData,
    remove,
  }
}

export default useOrganizations
