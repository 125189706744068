import { useState } from 'react'
import { useLocation } from 'react-router'
import history from 'history/browser'

import { useLocales } from '../locales'

import useQueryParams from './useQueryParams'

export default function useSnackbarMessageByQueryParams(
  messagesByQueryParam = {},
) {
  const { translate } = useLocales()
  const { pathname } = useLocation()

  const queryParams = useQueryParams()
  const queryParam = Object.keys(messagesByQueryParam).find(queryParam =>
    queryParams.get(queryParam),
  )

  const severity =
    queryParam === undefined
      ? undefined
      : queryParam.toLowerCase().includes('error')
      ? 'error'
      : 'success'

  const [message, setMessage] = useState(
    queryParam !== undefined
      ? translate(messagesByQueryParam[queryParam])
      : null,
  )

  const closeMessage = () => {
    queryParams.delete(queryParam)

    history.replace({
      search: queryParams.toString(),
      pathname,
    })

    setMessage(null)
  }

  return [message, closeMessage, severity]
}
