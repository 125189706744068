import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import style from './HeatmapWeekSelectorStyle';
import Spacer from '../Spacer/Spacer';
import HeatmapChart from '../chart/HeatmapChart/HeatmapChart';
import { useState } from 'react';
import { useLocales } from '../../locales';
import EmptyCard from '../card/EmptyCard/EmptyCard';
import { LocalFireDepartment } from '@mui/icons-material';
import AnonymousDetectionsModel from '../../services/anonymous/model/AnonymousDetectionsModel';
import InfoTooltip from '../infoTooltip/InfoTooltip';

enum Intervals {
  FULL_DAY = 'full-day',
  HALF_DAY = 'half-day',
}

const MIN_HOUR_RANGE = 9
const MAX_HOUR_RANGE = 21

export default function HeatmapWeekSelector({
  title,
  maxValue = 10000,
  data,
  tooltip,
}: {
  title: string;
  maxValue: number;
  data: AnonymousDetectionsModel[];
  tooltip?: string;
}) {
  const { translate } = useLocales();

  const [selectedInterval, setSelectedInterval] = useState<string>(Intervals.FULL_DAY);

  const getFilteredData = (): any[] => {
    return data.filter((item: any) => {
      const nameAsNumber = parseInt(item.name, 10);
      return nameAsNumber >= MIN_HOUR_RANGE && nameAsNumber <= MAX_HOUR_RANGE;
    });
  };

  const handleChange = (event: { target: { value: string } }) => {
    setSelectedInterval(event.target.value);
  };

  const getIntervalSelect = () => {
    return (
      <FormControl>
        <InputLabel id="interval-select-label">{`${translate('date:calendar:interval')}`}</InputLabel>
        <Select
          labelId="interval-select"
          id="interval-select"
          value={selectedInterval}
          label="interval"
          size="small"
          onChange={handleChange}
        >
          <MenuItem value={Intervals.FULL_DAY}>{`${translate(`date:calendar:${Intervals.FULL_DAY}`)}`}</MenuItem>
          <MenuItem value={Intervals.HALF_DAY}>{`${translate(`date:calendar:${Intervals.HALF_DAY}`)}`}</MenuItem>
        </Select>
      </FormControl>
    )
  }

  if (!data?.length){
    return <EmptyCard height={500} tooltip={tooltip} />
  }

  return (
    <Card sx={style.container}>
      <Stack sx={style.header}>
        <Box sx={style.titleContainer}>
          <LocalFireDepartment sx={style.title} />
          <Typography variant="subtitle2" color={style.title.color}>
            {title}
          </Typography>
          {tooltip && <InfoTooltip info={tooltip} /> }
        </Box>
        {getIntervalSelect()}
      </Stack>
      <Spacer size={3} />
      <HeatmapChart maxValue={maxValue} data={data} hidden={selectedInterval !== Intervals.FULL_DAY} />
      <HeatmapChart maxValue={maxValue} data={getFilteredData()} hidden={selectedInterval !== Intervals.HALF_DAY} />
    </Card>
  );
}
