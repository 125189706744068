export class InvalidNewLocationPayloadError extends Error {
  constructor(endpoint: string) {
    super(`Error creating new store from endponit ${endpoint}`)
    this.name = 'InvalidNewLocationPayloadError'
  }
}

export class TaxonomyNotExistsError extends Error {
  constructor(endpoint: string) {
    super(`Error in taxonomy editing location from endponit ${endpoint}`)
    this.name = 'TaxonomyNotExistsError'
  }
}

