import { useNavigate } from 'react-router'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'

import { useLocales } from '../../../locales'
import ImageDropArea from '../../../components/image-drop-area'
import Iconify from '../../../components/iconify'
import { PATH_DASHBOARD } from '../../../routes/paths'
import GridContainer from '../../../components/grid/GridContainer/GridContainer'

export default function CampaignForm({
  form,
  brandId,
  organizationId,
  editing = false,
  completed = false,
  switchStatusSlot,
  statusSlot,
}) {
  const navigate = useNavigate()
  const { translate } = useLocales()

  const goBackToCampaigns = () => {
    navigate(PATH_DASHBOARD.campaigns(organizationId, brandId))
  }

  return (
    <GridContainer>
      <Grid item xs={12} sx={{ mb: 5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" columnGap="1.75rem" alignItems="center">
            <Typography variant="h4">
              {translate(
                editing ? 'edit-campaign:title' : 'new-campaign:title',
              )}
            </Typography>
            {statusSlot}
          </Stack>
          {switchStatusSlot}
        </Stack>
      </Grid>

      <Grid item xs={4} sx={{ mb: 2, pt: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('new-campaign:details-title')}
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          {translate('new-campaign:details-description')}
        </Typography>
      </Grid>

      <Grid item xs={8} sx={{ mb: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <form>
                <FormControl sx={{ display: 'flex', gap: '1rem' }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    disabled={completed}
                    sx={{ mb: 1 }}
                    name="name"
                    label={translate('new-campaign:form-name-label')}
                    value={form.values.name}
                    onChange={form.handleFieldChange}
                    error={form.errors.name !== ''}
                    helperText={form.errors.name}
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    disabled={completed}
                    sx={{ mb: 1 }}
                    name="description"
                    label={translate('new-campaign:form-description-label')}
                    value={form.values.description}
                    onChange={form.handleFieldChange}
                    error={form.errors.description !== ''}
                    helperText={form.errors.description}
                  />

                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      sx={{ mb: 1 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label={translate('new-campaign:form-start-label')}
                          name="start"
                          disabled={completed}
                          format="dd-MM-yyyy HH:mm"
                          ampm={false}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: form.errors.start !== '',
                              helperText: form.errors.start,
                            },
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={form.values.start}
                          minDate={new Date()}
                          minTime={new Date()}
                          disableIgnoringDatePartForTimeValidation
                          maxDate={form.values.end}
                          onChange={date => {
                            form.handleDateChange('start', date)
                          }}
                        />

                        <DateTimePicker
                          label={translate('new-campaign:form-end-label')}
                          name="end"
                          disabled={completed}
                          format="dd-MM-yyyy HH:mm"
                          ampm={false}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: form.errors.end !== '',
                              helperText: form.errors.end,
                            },
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={form.values.end}
                          minDate={form.values.start}
                          minTime={form.values.start}
                          disableIgnoringDatePartForTimeValidation
                          onChange={date => {
                            form.handleDateChange('end', date)
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }} color="grey.600">
                      <Iconify icon="eva:info-fill" />
                      <Typography marginLeft="2px">
                        {translate('campaigns:create:caption')}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Box sx={{ mb: 1 }}>
                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
                      {translate('new-campaign:form-type-label')}
                    </Typography>

                    <Typography>
                      {translate('campaign-type:registration')}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
                      {translate('new-campaign:form-channel-label')}
                    </Typography>

                    <Typography>
                      {translate('campaign-channel:whatsapp')}
                    </Typography>
                  </Box>
                </FormControl>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4} sx={{ mb: 2, pt: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate('new-campaign:content-title')}
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          {translate('new-campaign:content-description')}
        </Typography>
      </Grid>

      <Grid item xs={8} sx={{ mb: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <form>
                <FormControl sx={{ display: 'flex', gap: '1rem' }}>
                  <Box>
                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
                      {translate('new-campaign:form-text-label')}
                    </Typography>

                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      disabled={editing || completed}
                      rows={5}
                      sx={{ mb: 1 }}
                      name="text"
                      placeholder={translate(
                        'new-campaign:form-text-placeholder',
                      )}
                      value={form.values.text}
                      onChange={form.handleFieldChange}
                      error={form.errors.text !== ''}
                      helperText={form.errors.text}
                    />
                  </Box>

                  <Box>
                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>
                      {translate('new-campaign:form-image-label')}
                    </Typography>

                    {editing || completed ? (
                      <img src={form.values.image} alt={form.values.text} />
                    ) : (
                      <>
                        <ImageDropArea
                          hasError={!!form.errors.image}
                          onChange={form.handleImageChange}
                          onError={form.handleImageError}
                        />

                        {form.errors.image !== '' ? (
                          <FormHelperText error>
                            {form.errors.image}
                          </FormHelperText>
                        ) : null}
                      </>
                    )}
                  </Box>
                </FormControl>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {completed ? null : (
            <Button
              variant="outlined"
              sx={{
                color: 'text.primary',
                borderColor: 'rgba(145, 158, 171, 0.32)',
              }}
              onClick={() => {
                navigate(PATH_DASHBOARD.campaigns(organizationId, brandId))
              }}
            >
              {translate('action:cancel')}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: 'grey.800' }}
            onClick={completed ? goBackToCampaigns : form.handleSubmit}
          >
            {translate(
              completed
                ? 'edit-campaign:back-to-campaigns'
                : editing
                ? 'edit-campaign:save-changes'
                : 'new-campaign:confirm',
            )}
          </Button>
        </Stack>
      </Grid>
    </GridContainer>
  )
}
