import { Box, Card, Typography } from '@mui/material'
import GridStyle from '../../../style/components/GridStyle'
import InfoTooltip from '../../infoTooltip/InfoTooltip'
import Variant from '../../variant/Variant'
import style from './AverageCardStyle'

export default function AverageCard({
  title,
  value,
  variation,
  tooltip,
}: {
  title: string
  value: number
  variation: number,
  tooltip?: string
}) {
  const getTooltip = (): JSX.Element => {
    if (!tooltip) {
      return <></>
    }

    return <InfoTooltip info={tooltip} />
  }

  return (
    <Card sx={[style.card, GridStyle.averageCard]}>
      <Box sx={style.textContainer}>
        <Box sx={style.titleContainer}>
          <Typography variant="subtitle2">{title}</Typography>
          {getTooltip()}
        </Box>
        <Typography variant="h3">{value}</Typography>
      </Box>
      <Box>
        <Variant value={variation} />
      </Box>
    </Card>
  )
}
