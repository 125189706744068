import { useCallback, useReducer, useState } from 'react'
import useLocales from '../locales/useLocales'
import { getDefaultCountry } from '../utils/getDefaultCountry'

const ERRORS = {
  missingField: 'new-location:form:error-missing-field',
}

const DEFAULT_PREFIX = getDefaultCountry().prefix
const DEFAULT_TIME_ZONE = 'Europe/Madrid'

const makeValidations = translate => ({
  name: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  timezone: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  address: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  contactName: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  contactLastName: value =>
    !Boolean(value) ? translate(ERRORS.missingField) : '',
  contactPhonePreffix: value =>
    !Boolean(value) ? translate(ERRORS.missingField) : '',
  contactPhoneNumber: value =>
    !Boolean(value) ? translate(ERRORS.missingField) : '',
  contactEmail: value =>
    !Boolean(value) ? translate(ERRORS.missingField) : '',
  tags: value => (value.length <= 0 ? translate(ERRORS.missingField) : ''),
})

function createInitialState() {
  return {
    values: {
      name: '',
      address: '',
      contactName: '',
      contactLastName: '',
      contactPhonePreffix: DEFAULT_PREFIX,
      contactPhoneNumber: '',
      contactEmail: '',
      timezone: DEFAULT_TIME_ZONE,
      tags: [],
    },
    errors: {
      name: '',
      address: '',
      contactName: '',
      contactLastName: '',
      contactPhonePreffix: '',
      contactPhoneNumber: '',
      contactEmail: '',
      timezone: '',
      tags: '',
    },
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'change-field-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: action.payload,
        },
      }
    case 'change-fields-values':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      }
    case 'add-tag-value':
      const tagExist = state.values.tags.some(
        item => item.tagUUID === action.payload.tagUUID,
      )

      if (tagExist) {
        return state
      }

      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: [...state.values.tags, action.payload],
        },
      }
    case 'remove-tag-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: state.values.tags.filter(
            tag => tag !== action.payload,
          ),
        },
      }
    case 'update-error':
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.payload },
      }

    case 'update-errors':
      return {
        ...state,
        errors: { ...action.payload },
      }

    default:
      return { ...state }
  }
}

function useLocationForm({ successCallback }) {
  const { translate } = useLocales()

  const [form, dispatchForm] = useReducer(reducer, createInitialState())

  const validations = makeValidations(translate)

  const [shouldValidateForm, setShouldValidateForm] = useState(false)

  const activateFormValidation = () => {
    setShouldValidateForm(true)
  }

  const changeFieldValue = event => {
    dispatchForm({
      type: 'change-field-value',
      fieldName: event.target.name,
      payload: event.target.value,
    })
  }

  const changeFieldsValues = useCallback(values => {
    dispatchForm({
      type: 'change-fields-values',
      payload: values,
    })
  }, [])

  const addTagValue = taxonomyTagGroup => {
    dispatchForm({
      type: 'add-tag-value',
      fieldName: 'tags',
      payload: taxonomyTagGroup,
    })
  }

  const removeTagValue = tag => {
    dispatchForm({
      type: 'remove-tag-value',
      fieldName: 'tags',
      payload: tag,
    })
  }

  const validateTagValue = useCallback(
    event => {
      const validation = (form.errors.tags = validations.tags(form.values.tags))
      dispatchForm({
        type: 'update-error',
        fieldName: 'tags',
        payload: validation,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validations, shouldValidateForm],
  )

  const validateFieldValue = useCallback(
    event => {
      if (!shouldValidateForm) return

      const name = event.target.name
      const value = event.target.value
      const result = validations[name](value)

      dispatchForm({
        type: 'update-error',
        fieldName: name,
        payload: result,
      })
    },
    [validations, shouldValidateForm],
  )

  const validate = useCallback(() => {
    const errors = { ...form.errors }

    errors.name = validations.name(form.values.name)
    errors.address = validations.address(form.values.address)
    errors.contactName = validations.contactName(form.values.contactName)
    errors.contactLastName = validations.contactLastName(
      form.values.contactLastName,
    )
    errors.contactPhonePreffix = validations.contactPhonePreffix(
      form.values.contactPhonePreffix,
    )
    errors.contactPhoneNumber = validations.contactPhoneNumber(
      form.values.contactPhoneNumber,
    )
    errors.contactEmail = validations.contactEmail(form.values.contactEmail)
    errors.timezone = validations.contactPhonePreffix(form.values.timezone)
    errors.tags = validations.tags(form.values.tags)

    dispatchForm({
      type: 'update-errors',
      payload: errors,
    })
    return Object.values(errors).every(error => error === '')
  }, [validations, form.values, form.errors])

  const submit = useCallback(
    event => {
      event.preventDefault()

      if (!shouldValidateForm) {
        activateFormValidation()
      }

      const isFormValid = validate()

      if (isFormValid) {
        successCallback(form.values)
      }
    },
    [shouldValidateForm, validate, form.values, successCallback],
  )

  return {
    values: form.values,
    errors: form.errors,
    changeFieldValue,
    changeFieldsValues,
    validateFieldValue,
    addTagValue,
    removeTagValue,
    validateTagValue,
    validate,
    submit,
  }
}

export default useLocationForm
