class CreateCampaignError extends Error {
  constructor(error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'CreateCampaignError'
  }
}

class UpdateCampaignError extends Error {
  constructor(error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'UpdateCampaignError'
  }
}

class CampaignEndedError extends Error {
  constructor(error) {
    super(`${error.name} - ${error.message}`)
    this.name = 'CampaignEndedError'
  }
}

export { CreateCampaignError, UpdateCampaignError, CampaignEndedError }
