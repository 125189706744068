const NavSectionVerticalStyle = {
  container: {
    px: 2,
  },
  title: {
    fontSize: 11,
    lineHeight: 3,
    textTransform: 'uppercase',
    // pt: 3,
    // pb: 1,
    color: 'text.secondary',
  }
}

export default NavSectionVerticalStyle
