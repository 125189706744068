import PropTypes from 'prop-types'
// @mui
import { Stack } from '@mui/material'
// components
import Logo from '../../components/logo'
import Image from '../../components/image'
//
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from './styles'

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
}

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          display: { xs: 'none', md: 'block' },
          position: { md: 'fixed' },
          left: { md: '2rem' },
          width: { md: '80px' },
          height: { md: '80px' },
          top: { md: '2rem' },
        }}
      />
      <StyledSection>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={
            illustration || '/assets/illustrations/illustration_dashboard.png'
          }
          sx={{ maxWidth: 1000 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  )
}
