import { Chip, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material"
import DatesBarFilter from "../DatesBarFilter/DatesBarFilter"
import FilterBarStyle from './FilterBarStyle'
import { useParams } from "react-router"
import { useState } from "react"
import { useLocales } from "../../locales"
import useTagGroup from "../../services/tagGroups/useTagGroup"
import TaxonomyTagGroupModel from "../../services/tagGroups/model/TaxonomyTagGroupModel"
import { SelectItemType } from "../../services/common/types/SelectItemType"
import Spacer from "../Spacer/Spacer"
import ContentModal from "../modal/ContentModal/ContentModal"
import useResponsive from "../../hooks/useResponsive"
import FileFilterButton from "../button/FileFilterButton/FileFilterButton"
import { FilterList, Delete } from "@mui/icons-material"
import PrimaryButton from "../button/PrimaryButton/PrimaryButton"
import { ButtonSize } from "../button/ButtonSize"
import UseAnonymousStateType from "../../services/common/types/UseAnonymousStateType"
import TagGroupSingleModel from "../../services/tagGroups/model/TagGroupSingleModel"

export default function FilterBar({
  updatePeriod,
  updateLocation,
} : {
  updatePeriod: (period: string) => void,
  updateLocation: (location: SelectItemType[]) => void,
}): JSX.Element {
  const { translate } = useLocales()
  const { organizationId, groupId } = useParams()
  const tagGroup = useTagGroup(organizationId!, groupId!) as UseAnonymousStateType<TagGroupSingleModel>
  const [currentLocations, setCurrentLocations] = useState<SelectItemType[]>([])
  const [currentTags, setCurrentTags] = useState<TaxonomyTagGroupModel[]>([])
  const [isLocationsSelectorModalVisible, setIsLocationsSelectorModalVisible] = useState<boolean>(false)
  const isDesktop = useResponsive('up', 'md')
  const style = FilterBarStyle(isDesktop)

  const showLocationsSelectorModal = (): void => {
    setIsLocationsSelectorModalVisible(true)
  }

  const closeLocationsSelectorModal = (): void => {
    setIsLocationsSelectorModalVisible(false)
  }

  const addTag = (tag: TaxonomyTagGroupModel) => {
    if (!currentTags.includes(tag)) {
      setCurrentTags([...currentTags, tag])
    }
  }

  const removeTag = (tag: TaxonomyTagGroupModel) => {
    const newCurrentTags = currentTags.filter(tagData => tagData.tagUUID !== tag.tagUUID)

    setCurrentTags(newCurrentTags)
    setCurrentLocations([])
    updateLocation([])
  }

  const removeLocation = (location: SelectItemType) => {
    const newCurrentLocations = currentLocations.filter(locationData => locationData.uuid !== location.uuid)

    setCurrentLocations(newCurrentLocations)
  }

  const getTaxonomyMenuItems = (taxonomyTags: TaxonomyTagGroupModel[]): JSX.Element => {
    return (
      <Stack>
        {taxonomyTags.map((
          tag: TaxonomyTagGroupModel,
          index: number,
        ) =>
          <MenuItem
            key={`${index}-${tag.tagName}`}
            onClick={() => addTag(tag)}
          >
            {tag.tagName}
          </MenuItem>
        )}
      </Stack>
    )
  }

  const getTaxonomySelector = (taxonomyTags: TaxonomyTagGroupModel[]): JSX.Element => {
    return (
      <TextField
        fullWidth
        select
        label={taxonomyTags[0]?.taxonomyName}
        name={`selector-${taxonomyTags[0]?.taxonomyName}`}
        size="small"
      >
        {getTaxonomyMenuItems(taxonomyTags)}
      </TextField>
    )
  }

  const getTaxonomyTagSelectors = (taxonomyUUID: string) => {
    const newTags = tagGroup?.data?.tags.filter((myTag: TaxonomyTagGroupModel) => myTag.taxonomyUUID === taxonomyUUID)

    return getTaxonomySelector(newTags)
  }

  const getTaxonomyTags = (taxonomy: TaxonomyTagGroupModel, index: number): JSX.Element => {
    if (currentTags.length === 0 && index === 0) {
      return <>
        <Spacer size={1} />
        {translate('anonymous:kpis:location:selector:empty:tag')}
      </>
    }

    return (
      <Stack sx={style.taxonomyTagsBlock}>
        {currentTags.map((
          tag: TaxonomyTagGroupModel,
          index: number,
        ) => {
          if (tag.taxonomyUUID !== taxonomy.taxonomyUUID) {
            return null
          }

          return (
            <Chip
              key={`${index}-${tag.tagName}`}
              variant="outlined"
              label={tag.tagName}
              onDelete={() => removeTag(tag)}
            />
          )
        })}
      </Stack>
    )
  }

  const getTaxonomiesBlock = (): JSX.Element => {
    const taxonomies = tagGroup?.data?.tags.filter((tagData: TaxonomyTagGroupModel, index: number, self: any[]) =>
      index === self.findIndex((f) => f.taxonomyUUID === tagData.taxonomyUUID)
    )

    if (!Boolean(taxonomies)) {
      return <></>
    }

    const mappedTaxonomies = taxonomies.map((taxonomy: TaxonomyTagGroupModel, index: number) =>
      <Grid
        container
        key={`${index}-${taxonomy.taxonomyName}`}
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          {getTaxonomyTagSelectors(taxonomy.taxonomyUUID)}
        </Grid>
        <Grid item xs={12} md={6}>
          {getTaxonomyTags(taxonomy, index)}
        </Grid>
      </Grid>
    )

    return (
      <Stack sx={style.taxonomyListBlock}>
        {mappedTaxonomies}
      </Stack>
    )
  }

  const changeLocation = (location: SelectItemType): void => {
    if (!currentLocations.includes(location)) {
      setCurrentLocations([...currentLocations, location])
    }
  }

  const handleUpdateLocation = (): void => {
    updateLocation(currentLocations)
    closeLocationsSelectorModal()
  }

  const resetFilters = (): void => {
    setCurrentLocations([])
    setCurrentTags([])
    updateLocation([])
  }

  const getHeader = (): JSX.Element => {
    return (
      <Stack sx={style.header}>
        <Typography variant="h4">
          {`${translate('anonymous:kpis:location:filter:title')}`}
        </Typography>
        <PrimaryButton
          iconComponent={<Delete />}
          size={ButtonSize.MEDIUM}
          onClick={resetFilters}
        />
      </Stack>
    )
  }

  const getLocationMenuItems = (locations: SelectItemType[]): JSX.Element => {
    return (
      <Stack>
        {locations.map((
          location: SelectItemType,
          index: number,
        ) =>
          <MenuItem
            key={`${index}-${location.name}`}
            onClick={() => changeLocation(location)}
          >
            {location.name}
          </MenuItem>
        )}
      </Stack>
    )
  }

  const getLocationSelector = (): JSX.Element => {
    if (!Boolean(tagGroup?.data?.tags)) {
      return <></>
    }

    const currentTagsUUIDs = new Set(currentTags.map(currentTagData => currentTagData.tagUUID))
    const locationTaxonomies = tagGroup?.data?.tags.filter((tag: TaxonomyTagGroupModel) => currentTagsUUIDs.has(tag.tagUUID))
    const uniqueLocations: SelectItemType[] = []

    for (const locationTaxonomy of locationTaxonomies) {
      for (const location of locationTaxonomy.locations) {
        if (!uniqueLocations.some((locationItem: SelectItemType) => locationItem.uuid === location.uuid)) {
          uniqueLocations.push(location)
        }
      }
    }

    return (
      <TextField
        fullWidth
        select
        label={`${translate('anonymous:kpis:location:selector')}`}
        name="location-selector"
        disabled={!currentTags || currentTags.length === 0}
        size="small"
      >
        {getLocationMenuItems(uniqueLocations)}
      </TextField>
    )
  }

  const getCurrentLocations = (): JSX.Element => {
    if (currentLocations.length === 0) {
      return <>
        <Spacer size={1} />
        {translate('anonymous:kpis:location:selector:empty:result')}
      </>
    }

    return (
      <Stack sx={style.locationsBlock}>
        {currentLocations.map((
          location: SelectItemType,
          index: number,
        ) =>
          <Chip
            key={`${index}-${location.name}`}
            variant="outlined"
            label={location.name}
            onDelete={() => removeLocation(location)}
          />
        )}
      </Stack>
    )
  }

  const getLocationBlock = (): JSX.Element => {
    return (
      <Grid
        container
        spacing={2}
        >
        <Grid item xs={12} md={6} >
          {getLocationSelector()}
        </Grid>
        <Grid item xs={12} md={6}>
          {getCurrentLocations()}
        </Grid>
      </Grid>
    )
  }

  const getLocationSelectionBlock = (): JSX.Element => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {getTaxonomiesBlock()}
        </Grid>
        <Grid item xs={12} md={6}>
          {getLocationBlock()}
        </Grid>
      </Grid>
    )
  }

  const getLocationFilterActionBlock = (): JSX.Element => {
    const totalLocations = tagGroup.data?.tags.reduce((total, location) => {
      return total + location.locations.length || 0
    }, 0)
    
    const getLabel = !currentLocations.length ? totalLocations : currentLocations.length
    return (
      <Stack sx={style.filterBlock}>
        <FileFilterButton
          isSelected={false}
          onClick={showLocationsSelectorModal}
        >
          <Stack sx={style.filterButton}>
            <FilterList />
            {`${translate(`anonymous:kpis:location:filter`)}`}
            <Chip
              variant="outlined"
              size="small"
              label={getLabel}
            />
          </Stack>
        </FileFilterButton>
        <DatesBarFilter updatePeriod={updatePeriod} />
      </Stack>
    )
  }

  return (
    <Stack sx={style.container}>
      {getLocationFilterActionBlock()}
      <ContentModal
        isOpen={isLocationsSelectorModalVisible}
        onClickAction={handleUpdateLocation}
        onClickCancel={closeLocationsSelectorModal}
        isPrimaryButtonDisabled={!currentLocations || currentLocations.length === 0}
      >
        <Stack sx={style.locationsContainer}>
          {getHeader()}
          {getLocationSelectionBlock()}
        </Stack>
      </ContentModal>
    </Stack>
  )
}
