import GaugeGraph from '../gaugeGraph/GaugeGraph'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import { useLocales } from '../../locales'
import { GRAPH_CARD_MIN_HEIGHT } from '../../style/components/GridStyle'
import ChartCard from '../card/ChartCard/ChartCard'

export default function AttractionRatioGraph({
  series,
  maxValue = Math.max(...series.map(serie => serie.value)),
  totalLabel,
  variant,
}: {
  series: {
    value: number
    label: string
    color?: string
  }[]
  maxValue?: number
  totalLabel?: string
  variant: number,
}) {
  const { translate } = useLocales()

  const tooltipText = `${translate('anonymous:ratio-attraction:graph:tooltip')}`
  const title = `${translate('anonymous:ratio-attraction:graph:title')}`

  const isSeriesEmpty = series.every(serie => !serie.value)

  if (isSeriesEmpty) {
    return <EmptyCard tooltip={tooltipText} title={title}  height={GRAPH_CARD_MIN_HEIGHT} />
  }

  return (
    <ChartCard
      title={title}
      tooltip={tooltipText}
      height={GRAPH_CARD_MIN_HEIGHT}
      variant={variant}
    >
      <GaugeGraph series={series} maxValue={maxValue} totalLabel={totalLabel} />
    </ChartCard>
  )
}
