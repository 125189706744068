import { useState } from 'react'
import { useNavigate } from 'react-router'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useLocales } from '../../../locales'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../components/button/SecondaryButton/SecondaryButton'
import OrganizationDetailsForm from '../../../components/organization/OrganizationDetailsForm'
import OrganizationContactForm from '../../../components/organization/OrganizationContactForm'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import OrganizationZonesForm from '../../../components/organization/OrganizationZonesForm'
import OrganizationModulesForm from '../../../components/organization/OrganizationModulesForm'
import { PermissionsType } from '../../../services/permissions/types/PermissionType'
import OrganizationImageForm from '../../../components/organization/OrganizationImageForm'

export default function OrganizationForm({
  form,
  editing = false,
  submitButtonLabel,
  modulesPermissions,
} : {
  form: any,
  editing?: boolean,
  submitButtonLabel: string
  modulesPermissions: PermissionsType[],
}) {
  const navigate = useNavigate()
  const { translate } = useLocales()
  const [openAlert, setOpenAlert] = useState(false)

  return (
    <Stack gap={3}>
      <OrganizationDetailsForm form={form} editing={editing} />
      <OrganizationImageForm form={form} />
      <OrganizationContactForm form={form} />
      <OrganizationZonesForm form={form} />
      <OrganizationModulesForm form={form} permissions={modulesPermissions || []} />
      <Grid container>
        <Grid item xs={12} md={11}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <SecondaryButton
              onClick={() => navigate(PATH_ACCOUNTS.organizations)}
            >
              {`${translate('action:cancel')}`}
            </SecondaryButton>
            <PrimaryButton onClick={form.submit}>
              {submitButtonLabel}
            </PrimaryButton>
          </Stack>
        </Grid>
      </Grid>
      <SnackbarAlert
        isOpen={openAlert}
        message={"Campaign creation failed. Please check the dates."}
        onClose={() => setOpenAlert(false)}
        type={SnackBarAlertTypes.ERROR}
      />
    </Stack>
  )
}
