import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useLocation, useParams } from 'react-router'
import useOrganization from '../../../services/organizations/useOrganization'
import { PATH_ACCOUNTS } from '../../../routes/paths'
import { useAuthContext } from '../../../auth/useAuthContext'
import { ROLES } from '../../../utils/rolesAndPermissions'
import useTagGroup from '../../../services/tagGroups/useTagGroup'
import useUser from '../../../services/users/useUser'
import { useLocales } from '../../../locales'
import style from './OrzanizationFluxBreadcrumbsStyle'

export default function OrzanizationFluxBreadcrumbs({
  showCurrentPath = true,
}: {
  showCurrentPath?: boolean
}): JSX.Element {
  const { translate } = useLocales()
  const { pathname } = useLocation()
  const { organizationId, groupId } = useParams()
  const { user: authUser } = useAuthContext() as any
  const { loading: isOrganizationLoading, data: organizationData } =
    useOrganization(organizationId!) as any
  const tagGroup = useTagGroup(organizationId!, groupId!) as any
  const { loading: isUserLoading, user } = useUser(authUser.uuid)

  const getLastURLSegment = (): string => {
    const isUsersURL = pathname.search('/users') !== -1
    if (isUsersURL) {
      return 'users'
    }

    return pathname.substring(pathname.lastIndexOf('/') + 1)
  }

  const lastUrlSegment = getLastURLSegment()

  if (!organizationId || lastUrlSegment === 'edit') {
    return <></>
  }

  const getLink = (linkPath: string, text: string): JSX.Element => {
    return (
      <Link underline="hover" color="inherit" href={linkPath}>
        {text}
      </Link>
    )
  }

  const getLoadText = (): JSX.Element => {
    return (
      <Typography sx={style.loadText}>{`${translate('loading')}`}</Typography>
    )
  }

  const getOrganizationLink = (): JSX.Element | null => {
    if (authUser.role !== ROLES.SUPER_ADMIN) {
      return null
    }

    if (isOrganizationLoading) {
      return getLoadText()
    }

    if (!organizationData) {
      return getLink(
        PATH_ACCOUNTS.organizations,
        `${translate('breadcrumbs:backoffice')}`,
      )
    }

    return getLink(PATH_ACCOUNTS.organizations, organizationData?.name)
  }

  const getTagGroupLink = (): JSX.Element | null => {
    if (!organizationId || !tagGroup?.data?.name) {
      return null
    }

    if (isUserLoading) {
      return getLoadText()
    }

    if (user?.tagGroups && user?.tagGroups?.length <= 1) {
      return null
    }

    return getLink(
      PATH_ACCOUNTS.tagGroups(organizationId),
      tagGroup?.data?.name,
    )
  }

  const getCurrentPath = (): JSX.Element | null => {
    if (!showCurrentPath) {
      return null
    }

    return (
      <Typography sx={style.pathText}>
        {`${translate(`breadcrumbs:${lastUrlSegment}`)}`}
      </Typography>
    )
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {getOrganizationLink()}
      {getTagGroupLink()}
      {getCurrentPath()}
    </Breadcrumbs>
  )
}
