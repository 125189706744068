const CardLoadingSkeletonStyle = {
  container: {
    p: 3,
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTitle: {
    width: '50%',
  }
}

export default CardLoadingSkeletonStyle
