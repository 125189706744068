import { useState } from 'react'
import { MenuItem, Stack } from '@mui/material'
import { useLocales } from '../../locales'
import Image from '../image'
import MenuPopover from '../menu-popover'
import { IconButtonAnimate } from '../animate'
import style from './LanguagePopoverStyle'

export default function LanguagePopover() {
  const { allLangs, currentLang, onChangeLang } = useLocales()

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleChangeLang = newLang => {
    onChangeLang(newLang)
    handleClosePopover()
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: style.iconSize,
          height: style.iconSize,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Image disabledEffect src={currentLang.icon} alt={currentLang.label} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={style.menu}
      >
        <Stack spacing={0.75}>
          {allLangs.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={style.menuIcon}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
