enum campaignsStatusEnum {
  ALL = 'all',
  COMPLETED = 'completed',
  PROGRAMMED = 'programmed',
  LIVE = 'live',
  PAUSED = 'paused',
  UNKNOWN = 'unknown',
}

export default campaignsStatusEnum
