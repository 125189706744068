// @mui
import { Box, Switch, TablePagination, FormControlLabel } from '@mui/material'
import { useLocales } from '../../locales'

// ----------------------------------------------------------------------

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [10, 25, 50, 100],
  sx,
  count,
  onPageChange,
  page,
  rowsPerPage,
  ...other
}: {
  dense?: boolean,
  onChangeDense?: () => void,
  rowsPerPageOptions?: number[],
  sx?: object,
  count: number,
  onPageChange: () => void,
  page: number,
  rowsPerPage: number,
  [key: string]: any
}) {
  const { translate } = useLocales()

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={`${translate('table:rows-per-page')}`}
        component="div"
        count={count}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        {...other}
      />

      {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute',
            },
          }}
        />
      )}
    </Box>
  )
}
