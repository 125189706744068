import { Box, Card, Stack, Typography } from '@mui/material'
import style from './KPI_CARD_STYLE'
import { calculateIncrementPercentage } from '../../../utils/mathHelper'
import { TrendingDown, TrendingUp } from '@mui/icons-material'
import {
  ZoneDataInterface,
} from '../../../services/anonymous/interfaces/AnonymousKPIsInterface'
import { secondsToHoursMinutesSeconds } from '../../../utils/formatTime'
import GridStyle, { SMALL_CARD_MIN_HEIGHT } from '../../../style/components/GridStyle'
import InfoTooltip from '../../infoTooltip/InfoTooltip'
import EmptyCard from '../EmptyCard/EmptyCard'
import AnonymousKPIsModel from '../../../services/anonymous/model/AnonymousKPIsModel'
import { useLocales } from '../../../locales'

export default function KPI_CARD({
  zoneNumber,
  anonymousKPIs,
  tooltip,
}: {
  zoneNumber: number
  anonymousKPIs: AnonymousKPIsModel,
  tooltip?: string
}): JSX.Element {
  const { translate } = useLocales()

  const getIncrementIcon = (zone: ZoneDataInterface): JSX.Element => {
    return zone.current > zone.previous ? (
      <TrendingUp sx={style.upIcon} />
    ) : (
      <TrendingDown sx={style.downIcon} />
    )
  }

  const getNoCurrentData = (text: string) => {
    return (
      <Typography variant="body2" sx={style.textZone}>
        {`${text} ${translate('common:no:data')}`}
      </Typography>
    )
  }

  const getNoPreviousData = (text: string, zone: ZoneDataInterface, isTimeCalc: boolean = false) => {
    return (
      <Stack sx={style.incrementBlock}>
        <Typography variant="body2" sx={style.textZone}>
          {isTimeCalc
            ? secondsToHoursMinutesSeconds(zone.current)
            : zone.current}{' '}
          {text}
        </Typography>
      </Stack>
    )
  }

  const getIncrementData = (text: string, zone: ZoneDataInterface, isTimeCalc: boolean = false) => {
    return (
      <Stack sx={style.incrementBlock}>
        {getIncrementIcon(zone)}
        <Typography variant="body2" sx={style.textZone}>
          {isTimeCalc
            ? secondsToHoursMinutesSeconds(zone.current)
            : zone.current}{' '}
          {text}
        </Typography>
        <Typography variant="subtitle1">
          ({calculateIncrementPercentage(zone.current, zone.previous)})
        </Typography>
      </Stack>
    )
  }

  const getDataBlock = (text: string, zone: ZoneDataInterface, isTimeCalc: boolean = false): JSX.Element => {
    if (!Boolean(zone?.current)) {
      return getNoCurrentData(text)
    }

    if (!Boolean(zone?.previous)) {
      return getNoPreviousData(text, zone, isTimeCalc)
    }

    return getIncrementData(text, zone, isTimeCalc)
  }

  const getTitle = (): string => {
    return anonymousKPIs.detections_sum[zoneNumber]?.name
  }

  const hasData = (): boolean => {
    return anonymousKPIs.unique_detections_sum[zoneNumber] !== undefined
      || anonymousKPIs.detections_sum[zoneNumber] !== undefined
      || anonymousKPIs.average_time_stay[zoneNumber] !== undefined
  }

  const getCardContent = (): JSX.Element => {
    if (!hasData()) {
      return (
        <EmptyCard title={getTitle()} tooltip={tooltip} height={SMALL_CARD_MIN_HEIGHT}/>
      )
    }

    const getTooltip = (): JSX.Element => {
      if (!tooltip) {
        return <></>
      }

      return <InfoTooltip info={tooltip} />
    }

    return (
      <>
        <Card sx={[style.container, GridStyle.smallCard]}>
          <Box sx={style.titleContainer}>
            <Typography variant="subtitle2">{getTitle()}</Typography>
            {getTooltip()}
          </Box>
          <Stack sx={style.incrementContainer}>
            {getDataBlock(`${translate('anonymous:kpis:visitors')}`, anonymousKPIs.unique_detections_sum[zoneNumber])}
            {getDataBlock(`${translate('anonymous:kpis:visits')}`, anonymousKPIs.detections_sum[zoneNumber])}
            {getDataBlock(`${translate('anonymous:kpis:avg:time')}`, anonymousKPIs.average_time_stay[zoneNumber], true)}
          </Stack>
        </Card>
      </>
    )
  }

  return <>{getCardContent()}</>
}
