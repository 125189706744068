import { forwardRef, ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'
import { Badge, Avatar, BadgeProps, SxProps, Theme } from '@mui/material'

type ColorType = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'

interface CustomAvatarProps {
  color?: ColorType
  name?: string
  lastname?: string
  BadgeProps?: Partial<BadgeProps>
  children?: ReactNode
  sx?: SxProps<Theme>
}

const getFirstChar = (name = ''): string => name.charAt(0).toUpperCase()

const getColorByName = (name: string): ColorType => {
  const firstNameChar = getFirstChar(name)

  if (['A', 'N', 'H', 'L', 'Q'].includes(firstNameChar)) return 'primary'
  if (['F', 'G', 'T', 'I', 'J'].includes(firstNameChar)) return 'info'
  if (['K', 'D', 'Y', 'B', 'O'].includes(firstNameChar)) return 'success'
  if (['P', 'E', 'R', 'S', 'U'].includes(firstNameChar)) return 'warning'
  if (['V', 'W', 'X', 'M', 'Z'].includes(firstNameChar)) return 'error'
  return 'default'
}

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  (
    { color, name = '', lastname = '', BadgeProps, children, sx, ...other },
    ref,
  ) => {
    const theme = useTheme()

    const firstNameChar = getFirstChar(name)
    const firstLastnameChar = getFirstChar(
      lastname.substring(lastname.lastIndexOf(' ') + 1),
    )

    const colorByName = getColorByName(name)
    const colr = color || colorByName

    const renderContent = colr === 'default' ? (
      <Avatar ref={ref} sx={sx} {...other}>
        {firstNameChar}
        {firstLastnameChar}
        {children}
      </Avatar>
    ) : (
      <Avatar
        ref={ref}
        sx={{
          color: theme.palette[colr]?.contrastText,
          backgroundColor: theme.palette[colr]?.main,
          fontWeight: theme.typography.fontWeightMedium,
          ...sx,
        }}
        {...other}
      >
        {firstNameChar}
        {firstLastnameChar}
        {children}
      </Avatar>
    )

    return BadgeProps ? (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...BadgeProps}
      >
        {renderContent}
      </Badge>
    ) : (
      renderContent
    )
  },
)

CustomAvatar.displayName = 'CustomAvatar'

export default CustomAvatar
