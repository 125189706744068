import { useCallback, useReducer, useState } from 'react'

import { useLocales } from '../locales'
import { emailValidator, phoneNumberValidator } from '../utils/validators'
import { getDefaultCountry } from '../utils/getDefaultCountry'

const ERRORS = {
  missingField: 'common:form:error-missing-field',
  invalidEmail: 'common:error-invalid-email',
  invalidPhone: 'common:error-invalid-phone'
}
const DEFAULT_PREFIX = getDefaultCountry().prefix

const makeValidations = translate => ({
  name: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  description: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  industry: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  contactName: value => (!Boolean(value) ? translate(ERRORS.missingField) : ''),
  contactSurname: value =>
    !Boolean(value) ? translate(ERRORS.missingField) : '',
  contactPhonePreffix: value =>
    !Boolean(value) ? translate(ERRORS.missingField) : '',
  contactPhoneNumber: value =>
    !phoneNumberValidator(value) ? translate(ERRORS.invalidPhone) : '',
  contactEmail: value =>
  (!Boolean(value) ? translate(ERRORS.missingField) : !emailValidator(value) ? translate(ERRORS.invalidEmail) : ''),
  zones: value => (value.length === 0 ? translate(ERRORS.missingField) : ''),
})

const removeZoneState = (state, action) => {
  return {
    ...state,
    values: {
      ...state.values,
      zones: state.values.zones.filter(
        zone => zone.sort !== action.payload.sort,
      ),
    },
  }
}

const addZoneState = (state, action) => {
  return {
    ...state,
    values: {
      ...state.values,
      zones: state.values.zones.some(zone => zone.sort === action.payload.sort)
        ? state.values.zones.map(zone =>
            zone.sort === action.payload.sort
              ? { ...zone, ...action.payload }
              : zone,
          )
        : [...state.values.zones, action.payload],
    },
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'change-field-value':
      return {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: action.payload,
        },
      }

    case 'change-fields-values':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      }

    case 'add-zone-value':
      if (action.payload.name === '') {
        return removeZoneState(state, action)
      }

      return addZoneState(state, action)

    case 'set-default-permissions':
      return {
        ...state,
        values: {
          ...state.values,
          permissions: action.payload.reduce((permissionsObject, permission) => {
            permissionsObject[permission.value] = permission.checked ?? false
            return permissionsObject
          }, {}),
        }
      }
    case 'update-permission':
      return {
        ...state,
        values: {
          ...state.values,
          permissions: {
            ...state.values.permissions,
            [action.payload.value]: action.payload.checked,
          }
        }
      }
    case 'update-error':
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.payload },
      }

    case 'update-errors':
      return {
        ...state,
        errors: { ...action.payload },
      }

    default:
      return { ...state }
  }
}

function createInitialState() {
  return {
    values: {
      name: '',
      description: '',
      industry: '',
      contactName: '',
      contactSurname: '',
      contactPhonePreffix: DEFAULT_PREFIX,
      contactPhoneNumber: '',
      contactEmail: '',
      zones: [],
      permissions: [],
      logo: '',
    },
    errors: {
      name: '',
      description: '',
      industry: '',
      contactName: '',
      contactSurname: '',
      contactPhonePreffix: '',
      contactPhoneNumber: '',
      contactEmail: '',
      zones: '',
      permissions: '',
      logo: '',
    },
  }
}

function useOrganizationForm({ successCallback, initialState = null }) {
  const { translate } = useLocales()

  const [form, dispatchForm] = useReducer(
    reducer,
    initialState ?? createInitialState(),
  )

  const validations = makeValidations(translate)

  const [shouldValidateForm, setShouldValidateForm] = useState(false)

  const activateFormValidation = () => {
    setShouldValidateForm(true)
  }

  const changeFieldValue = event => {
    dispatchForm({
      type: 'change-field-value',
      fieldName: event.target.name,
      payload: event.target.value,
    })
  }

  const changeFieldsValues = useCallback(values => {
    dispatchForm({
      type: 'change-fields-values',
      payload: values,
    })
  }, [])

  const addZoneValue = zone => {
    dispatchForm({
      type: 'add-zone-value',
      fieldName: 'zones',
      payload: zone,
    })
  }

  const setDefaultPermissions = permissions => {
    dispatchForm({
      type: 'set-default-permissions',
      fieldName: 'permissions',
      payload: permissions,
    })
  }

  const updatePermission = permission => {
    dispatchForm({
      type: 'update-permission',
      fieldName: 'permissions',
      payload: permission,
    })
  }

  const setLogo = file => {
    dispatchForm({
      type: 'change-field-value',
      fieldName: 'logo',
      payload: file,
    })
  }

  const validateFieldValue = useCallback(
    event => {
      if (!shouldValidateForm) return

      const name = event.target.name
      const value = event.target.value
      const result = validations[name](value)

      dispatchForm({
        type: 'update-error',
        fieldName: name,
        payload: result,
      })
    },
    [validations, shouldValidateForm],
  )

  const validate = useCallback(() => {
    const errors = { ...form.errors }

    errors.name = validations.name(form.values.name)

    errors.description = validations.description(form.values.description)

    errors.industry = validations.industry(form.values.industry)

    errors.contactName = validations.contactName(form.values.contactName)

    errors.contactSurname = validations.contactSurname(
      form.values.contactSurname,
    )

    errors.contactPhonePreffix = validations.contactPhonePreffix(
      form.values.contactPhonePreffix,
    )

    errors.contactPhoneNumber = validations.contactPhoneNumber(
      form.values.contactPhoneNumber,
    )

    errors.contactEmail = validations.contactEmail(form.values.contactEmail)

    errors.zones = validations.zones(form.values.zones)

    dispatchForm({
      type: 'update-errors',
      payload: errors,
    })

    return Object.values(errors).every(error => error === '')
  }, [validations, form.values, form.errors])

  const submit = useCallback(
    event => {
      event.preventDefault()

      if (!shouldValidateForm) {
        activateFormValidation()
      }

      const isFormValid = validate()

      if (isFormValid) {
        successCallback(form.values)
      }
    },
    [shouldValidateForm, validate, form.values, successCallback],
  )

  return {
    values: form.values,
    errors: form.errors,
    changeFieldValue,
    changeFieldsValues,
    addZoneValue,
    setDefaultPermissions,
    updatePermission,
    setLogo,
    validateFieldValue,
    validate,
    submit,
  }
}

export default useOrganizationForm
export { createInitialState }
