const SEPARATION = 3

const TagGroupFormStyle = {
  container: {
    gap: SEPARATION,
  },
  card: {
    padding: SEPARATION,
  },
  formContainer: {
    alignItems: "flex-start",
    gap: SEPARATION,
  },
}

export default TagGroupFormStyle
