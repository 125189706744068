import { useLocales } from '../../locales'
import { ZoneDataInterface } from '../../services/anonymous/interfaces/AnonymousKPIsInterface'
import AnonymousKPIsModel from '../../services/anonymous/model/AnonymousKPIsModel'
import { LONG_CARD_MIN_HEIGHT } from '../../style/components/GridStyle'
import EmptyCard from '../card/EmptyCard/EmptyCard'
import HorizontalBarGraph from '../HorizontalBarGraph/HorizontalBarGraph'
import { useTheme } from '@mui/material/styles'
import ChartCard from '../card/ChartCard/ChartCard'

export default function TimeComebackChart({
  anonymousKPIsData,
}: {
  anonymousKPIsData: AnonymousKPIsModel | undefined
}) {
  const { translate } = useLocales()
  const theme = useTheme() as any

  const title = `${translate('anonymous:kpis:chart:average-visits-comeback')}`
  const tooltip = `${translate('anonymous:kpis:chart:average-visits-comeback:tooltip')}`
  const colors = [theme.palette.chart.light.cashRegisters, theme.palette.chart.light.firstZone]

  const formatData = (value: string): string => {
    return `${value} ${translate(
      'anonymous:kpis:chart:average-visits-comeback:yAxisDays',
    )}`
  }

  const zones = Object.keys(anonymousKPIsData?.average_time_comeback || {}).map(
    key => anonymousKPIsData?.average_time_comeback[parseInt(key)],
  )

  const validZones = zones.filter(
    (zone): zone is ZoneDataInterface =>
      zone !== undefined && zone.current !== undefined && zone.current !== null,
  )

  const availableZones = validZones.map((zone, index) => ({
    name: zone.name || '',
    data: zone.current,
    color: colors[index],
  })).reverse()

  if (!availableZones.length) {
    return <EmptyCard title={title} tooltip={tooltip} height={LONG_CARD_MIN_HEIGHT} />
  }

  return (
    <ChartCard
      title={title}
      tooltip={tooltip}
      height={LONG_CARD_MIN_HEIGHT}
    >
      <HorizontalBarGraph
        series={availableZones}
        axisYTooltipFormatter={formatData}
      />
    </ChartCard>
  )
}
