import axios from '../../utils/axios'
import NodeCreatePayloadModel from './model/NodeCreatePayloadModel'
import NodeInfoModel from "./model/NodeInfoModel"
import NodeSingleModel from './model/NodeSingleModel'
import NodeUpdatePayloadModel from './model/NodeUpdatePayloadModel'
import { adaptNodeSingleResponse, adaptNodesResponse } from "./nodeAdapter"
import { FetchNodesError, MissingParamsError, NodeGenericError } from "./nodeError"
import { NodeApiInterface } from './interface/NodeApiInterface'

export async function fetchNodes(): Promise<NodeInfoModel[]> {
  const endpoint = `/api/v1/nodes`

  try {
    const response = await axios.get<NodeApiInterface[]>(endpoint)

    return adaptNodesResponse(response.data)
  } catch (error) {
    // TODO - manage all errors
    throw new FetchNodesError(endpoint)
  }
}

export async function fetchNode(nodeUUID: string): Promise<NodeSingleModel> {
  const endpoint = `/api/v1/nodes/${nodeUUID}`

  try {
    const response = await axios.get<NodeApiInterface>(endpoint)

    return adaptNodeSingleResponse(response.data)
  } catch (error) {
    // TODO - manage all errors
    throw new FetchNodesError(endpoint)
  }
}

export async function createNode(payload: NodeCreatePayloadModel): Promise<void> {
  const endpoint = '/api/v1/nodes'

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {

    throw new NodeGenericError(endpoint)
  }
}

export async function updateNode(nodeUUID: string, payload: NodeUpdatePayloadModel): Promise<void> {
  const endpoint = `/api/v1/nodes/${nodeUUID}`

  try {
    await axios.put(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.data.name === MissingParamsError.name) {
      throw new MissingParamsError(error.response?.data?.message)
    }

    throw new NodeGenericError(endpoint)
  }
}
