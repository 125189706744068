import { PATH_DASHBOARD } from '../../routes/paths'
import { CampaignEndedError } from './Error'
import { adaptFormValuesToUpdatePayload } from './adapters'
import { createCampaign, updateCampaign } from './campaignsService'

const { useState, useCallback } = require('react')
const { useNavigate } = require('react-router')

export default function useCampaignMutation(organizationId, brandId) {
  const navigate = useNavigate()

  const [state, setState] = useState({
    success: undefined,
    loading: false,
    error: null,
  })

  const create = useCallback(
    /**
     * @param {object} campaignData - Datos para la creación de la campaña.
     * @param {string} campaignData.clientId - ID del cliente al que pertenece la campaña.
     * @param {string} campaignData.brandId - ID de la marca a la que pertenece la campaña.
     * @param {string} campaignData.name - Nombre de la campaña.
     * @param {string} campaignData.description - Descripción de la campaña.
     * @param {string} campaignData.start - Fecha de inicio de la campaña.
     * @param {string} campaignData.end - Fecha de fin de la campaña.
     * @param {string} campaignData.text - Mensaje de la campaña.
     * @param {object} campaignData.image - Metadatos de la imagen de la campaña.
     * @param {string} campaignData.image.fileName - Nombre de la imagen que se ha subido.
     * @param {string} campaignData.image.mimeType - Tipo de imagen.
     */
    async campaignData => {
      setState({
        success: undefined,
        loading: true,
        error: null,
      })

      try {
        await createCampaign(campaignData)

        setState(state => ({
          ...state,
          loading: false,
          success: true,
        }))

        navigate(
          `${PATH_DASHBOARD.campaigns(organizationId, brandId)}?created=true`,
        )

        return Promise.resolve()
      } catch (error) {
        setState({
          loading: false,
          success: false,
          error,
        })

        return Promise.reject(error)
      }
    },
    [organizationId, brandId, navigate],
  )

  const update = useCallback(
    campaignId =>
      /**
       * @param {object} config - Configuración relativa al estado de pause de una campaña.
       * @param {boolean} config.paused - Indica el estado que se va a mandar a la petición.
       */
      config =>
      /**
       * @param {object} formValues - Datos para la creación de la campaña.
       * @param {string} campaignData.clientId - ID del cliente al que pertenece la campaña.
       * @param {string} campaignData.brandId - ID de la marca a la que pertenece la campaña.
       * @param {string} campaignData.name - Nombre de la campaña.
       * @param {string} campaignData.description - Descripción de la campaña.
       * @param {Date} campaignData.start - Fecha de inicio de la campaña.
       * @param {Date} campaignData.end - Fecha de fin de la campaña.
       * @param {string} campaignData.text - Mensaje de la campaña.
       * @param {string} campaignData.image - URL de la imagen de la campaña.
       */
      async formValues => {
        setState({
          success: undefined,
          loading: true,
          error: null,
        })

        try {
          await updateCampaign(
            campaignId,
            adaptFormValuesToUpdatePayload(formValues, config.paused),
          )

          setState(state => ({
            ...state,
            loading: false,
            success: true,
          }))

          navigate(
            `${PATH_DASHBOARD.campaigns(organizationId, brandId)}?updated=true`,
          )

          return Promise.resolve()
        } catch (error) {
          if (error instanceof CampaignEndedError) {
            navigate(
              `${PATH_DASHBOARD.campaigns(
                organizationId,
                brandId,
              )}?ended-error=true`,
            )
            return
          }

          setState({
            loading: false,
            success: false,
            error,
          })

          return Promise.reject(error)
        }
      },
    [organizationId, brandId, navigate],
  )

  /**
   * @param {string} campaignId - Id de la campaña.
   * @param {object} campaignData - Datos para la creación de la campaña.
   * @param {string} campaignData.clientId - ID del cliente al que pertenece la campaña.
   * @param {string} campaignData.brandId - ID de la marca a la que pertenece la campaña.
   * @param {string} campaignData.name - Nombre de la campaña.
   * @param {string} campaignData.description - Descripción de la campaña.
   * @param {Date} campaignData.start - Fecha de inicio de la campaña.
   * @param {Date} campaignData.end - Fecha de fin de la campaña.
   * @param {string} campaignData.text - Mensaje de la campaña.
   * @param {string} campaignData.imageUrl - URL de la imagen de la campaña.
   * @param {boolean} campaignData.paused - Indica si la campaña está pausada o no.
   */
  const togglePause = useCallback(
    async (campaignId, campaignData) => {
      setState({
        success: undefined,
        loading: true,
        error: null,
      })

      try {
        delete campaignData._id

        const imageUrl = campaignData.image
        delete campaignData.image

        await updateCampaign(campaignId, { ...campaignData, imageUrl })

        setState(state => ({
          ...state,
          loading: false,
          success: true,
        }))

        navigate(
          `${PATH_DASHBOARD.campaigns(organizationId, brandId)}?updated=true`,
        )

        return Promise.resolve()
      } catch (error) {
        setState({
          loading: false,
          success: false,
          error,
        })

        return Promise.reject(error)
      }
    },
    [organizationId, brandId, navigate],
  )

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    create,
    update,
    togglePause,
    clearError,
  }
}
