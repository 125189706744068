import { UserModelInterface } from '../interfaces/UserModelInterface';
import { UserOrganizationInterface } from '../interfaces/UserOrganizationInterface';

export default class UserModel implements UserModelInterface {
  constructor(
    readonly organizationUUID: string,
    readonly email: string,
    readonly lastname: string,
    readonly name: string,
    readonly organization: UserOrganizationInterface,
    readonly role: string,
    readonly permissions: Record<string, boolean>,
    readonly uuid: string,
  ) {}
}
