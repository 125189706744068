const InfoTooltipStyle = {
  icon: {
    height: '18px'
  },
  button: {
    padding: 0
  }
}

export default InfoTooltipStyle
