const style = {
  cardContainer: {
    width: '75%',
    padding: 3
  },
  infoContainer: {
    padding: 0,
    paddingBottom: 2
  },
  titleContainer: {
    marginBottom: 3
  },
}

export default style;
