import { configureStore } from '@reduxjs/toolkit'
import organizationsReducer from './organizationsSlice'
import brandsReducer from './brandsSlice'
import storesReducer from './storesSlice'

export const store = configureStore({
  reducer: {
    organizations: organizationsReducer,
    brands: brandsReducer,
    stores: storesReducer,
  },
})
