import { Box, Button, Card, FormControl, Grid, IconButton, Typography, styled } from '@mui/material'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import style from './OrganizationSectionFormStyle'
import { CloudUpload, Delete } from '@mui/icons-material'
import { useLocales } from '../../locales'
import { useState } from 'react'

export default function OrganizationImageForm({form}: {form: any}) {
  const { translate } = useLocales()
  
  const [preview, setPreview] = useState<string>()

  const VisuallyHiddenInput = styled('input')({
    ...style.hiddenInput as unknown as TemplateStringsArray
  })

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setPreview(imageUrl)
      form.setLogo(file)
    }
  }

  const handleRemoveImage = (): void => {
    setPreview('')
    form.setLogo('')
  }

  const getFormPreview = (): string => {
    return (process.env.REACT_APP_HOST_API_KEY + form.values.logo).replace(/([^:]\/)\/+/g, "$1")
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('new:organization:logo:title')}`}
          subtitle={`${translate('new:organization:logo:description')}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={style.card}>
          <form>
            <FormControl sx={style.form}>
              <Box sx={style.imageSectionContainer}>
                <Box>
                  <Button
                    component="label"
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                  >
                    {`${translate('new:organization:logo:button')}`}
                    <VisuallyHiddenInput
                      type="file"
                      accept='image/*'
                      onChange={handleImageChange}
                    />
                  </Button>
                </Box>
                <Box sx={style.previewImage}>
                {preview || form.values.logo ? (
                  <>
                    <IconButton
                      color='error'
                      sx={style.removeImageButton}
                      onClick={handleRemoveImage}
                    >
                      <Delete />
                    </IconButton>
                    <img
                      src={preview || getFormPreview()}
                      alt="organization logo"
                    />
                  </>
                  ): (
                <Typography variant="body2">
                  {`${translate('new:organization:logo:empty')}`}
                </Typography>
                )}
                </Box>
              </Box>
            </FormControl>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}
