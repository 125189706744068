import { generateUUID } from '../../utils/uuidGenerator';
import TaxonomyTagUpdateModel from '../tagGroups/model/TaxonomyTagUpdateModel';
import LocationApiModel from './model/LocationApiModel';
import LocationFormModel from './model/LocationFormModel'
import LocationPayloadModel from './model/LocationPayloadModel'
import UpdateLocationPayloadModel from './model/UpdateLocationPayloadModel'

export function adaptCreateLocationPayload(payload: LocationFormModel, organizationUUID: string): LocationPayloadModel {
  return new LocationPayloadModel(
    organizationUUID,
    generateUUID(),
    payload.name,
    payload.address,
    payload.timezone,
    {
      name: payload.contactName,
      lastName: payload.contactLastName,
      phone: `${payload.contactPhonePreffix} ${payload.contactPhoneNumber}`,
      email: payload.contactEmail,
    },
    payload.tags.map((tag) => {
      return new TaxonomyTagUpdateModel(
        tag.tagUUID,
        tag.taxonomyUUID,
      )
    })
  )
}

export function adaptResponseToFormValues(location: LocationApiModel): LocationFormModel {
  const phoneSplited = location.contact.phone.split(' ');
  
  return new LocationFormModel(
    location.uuid,
    location.organizationUUID,
    location.name,
    location.address,
    location.contact.name,
    location.contact.lastName,
    phoneSplited[0],
    phoneSplited[1],
    location.contact.email,
    location.timezone,
    location.taxonomyTags.map((taxonomy) => {
      return {
        tagName: taxonomy.taxonomyTagValue,
        tagUUID: taxonomy.taxonomyTagUUID,
        taxonomyUUID: taxonomy.taxonomyUUID
      }
    })
  )
}

export function adaptUpdateLocationPayload(payload: LocationFormModel): UpdateLocationPayloadModel {
  return new UpdateLocationPayloadModel(
    payload.name,
    payload.address,
    payload.timezone,
    {
      name: payload.contactName,
      lastName: payload.contactLastName,
      phone: `${payload.contactPhonePreffix} ${payload.contactPhoneNumber}`,
      email: payload.contactEmail,
    },
    payload.tags.map((tag) => {
      return new TaxonomyTagUpdateModel(
        tag.tagUUID,
        tag.taxonomyUUID,
      )
    })
  )
}
