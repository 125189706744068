type NodeInfo = {
  uuid: string,
  name: string,
}

export default class NodeSingleModel {
  constructor(
    readonly description: string,
    readonly foreignID: string,
    readonly location: NodeInfo,
    readonly organization: NodeInfo,
    readonly populated: boolean,
    readonly status: boolean,
    readonly uuid: string,
    readonly zone: NodeInfo,
  ) {}
}
