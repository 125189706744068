import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react'
import axios from '../utils/axios'
import canUseLocalStorage from '../utils/canUseLocalStorage'

import { isValidToken, setSession } from './utils'
import LoggedApiInterface from '../services/users/interfaces/LoggedApiUserInterface'

type StateType = {
  isInitialized: boolean,
  isAuthenticated: boolean,
  user: LoggedApiInterface | null,
}

type AuthContextType = {
  isInitialized: boolean
  isAuthenticated: boolean
  user: LoggedApiInterface | null
  method: string
  login: (email: string, password: string) => Promise<void>
  logout: () => void
} | null

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
}

const reducer = (state: StateType, action: any) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    }
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    }
  }

  return state
}

export const AuthContext = createContext<AuthContextType>(null)

export function AuthProvider({ children }: { children: JSX.Element }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const storageAvailable = canUseLocalStorage()

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem('accessToken')
        : ''

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)
        const response = await axios.get('/api/v1/me')

        const user = response.data

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        })
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }, [storageAvailable])

  useEffect(() => {
    initialize()
  }, [initialize])

  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await axios.post('/api/v1/users/login', {
        email,
        password,
      })
      const { accessToken } = response.data

      setSession(accessToken)
      const userSession = await axios.get('/api/v1/me')
      const user = userSession.data

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      })
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }, [])

  const logout = useCallback(() => {
    setSession(null)
    dispatch({
      type: 'LOGOUT',
    })
  }, [])

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout],
  )

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  )
}
