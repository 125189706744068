export const ORGANIZATION_SECTORS = [
  'Arts & Entertainment',
  'Animals & Pets',
  'Apparel / Fashion & Jewelry',
  'Automotive -- For Sale',
  'Automotive -- Repair, Service & Parts',
  'Beauty & Personal Care',
  'Business Services',
  'Dentists & Dental Services',
  'Education & Instruction',
  'Finance & Insurance',
  'Furniture',
  'Health & Fitness',
  'Home & Home Improvement',
  'Industrial & Commercial',
  'Personal Services (Weddings, Cleaners, etc.)',
  'Real Estate',
  'Restaurants & Food',
  'Shopping, Collectibles & Gifts (General)',
  'Sports & Recreation',
  'Travel & Hospitality',
]
