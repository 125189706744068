import countryCodes from 'country-codes-list'

const DEFAULT_COUNTRY = 'ES'

export const getDefaultCountry = () => {
  const defaultCountry = countryCodes.all().find(
    country => country.countryCode === DEFAULT_COUNTRY
  );

  if (!defaultCountry) {
    throw new Error('Default country not found');
  }

  return {
    code: defaultCountry.countryCode,
    name: defaultCountry.countryNameEn,
    prefix: `+${defaultCountry.countryCallingCode}`,
  };
};
