import { alpha, Theme } from "@mui/material/styles"

const FileFilterButtonStyle = (theme: Theme) => ({
  container: {
    textTransform: 'unset',
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
    width: { xs: 1, md: 'auto' },
    justifyContent: 'flex-start',
    fontWeight: 'fontWeightMedium',
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  },
})

export default FileFilterButtonStyle
