export const generateRandomPassword = (): string => {
  const length = 8
  const numbers = '0123456789'
  const lowercaseLatters = 'abcdefghijklmnopqrstuvwxyz'
  const uppercaseLetters = lowercaseLatters.toUpperCase()
  const symbols = '!@#$%^&*()'
  const chars = numbers.concat(lowercaseLatters, uppercaseLetters, symbols)

  let password = ''

  for (let i = 0; i <= length; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length)
    password += chars.substring(randomNumber, randomNumber + 1)
  }

  return password
}
