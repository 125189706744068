import {
  Alert as MuiAlert,
  Snackbar,
} from '@mui/material'
import style from './SnackbarAlertStyle'
import SnackBarAlertTypes from './SnackBarAlertTypes'

export default function SnackbarAlert({
  isOpen = false,
  message,
  onClose,
  autoHideDuration,
  type = SnackBarAlertTypes.SUCCESS,
} : {
  isOpen?: boolean,
  message: string,
  onClose?: () => void,
  autoHideDuration?: number,
  type?: SnackBarAlertTypes
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
    >
      <MuiAlert
        severity={type}
        sx={style.alert}
        onClose={onClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}
