import { Grid, Typography } from '@mui/material'
import ContainerBase from '../../../components/container/ContainerBase/ContainerBase'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'

export default function FormContainer({
  title,
  showError,
  errorMessage,
  onCloseError,
  children,
}:{
  title: string,
  showError: boolean,
  errorMessage: string,
  onCloseError: () => void,
  children: React.ReactNode,
}) {
  return (
    <ContainerBase>
      <Typography variant="h4">
        {title}
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={8}>
          {children}
        </Grid>
      </Grid>
      <SnackbarAlert
        isOpen={showError}
        message={errorMessage}
        onClose={onCloseError}
        type={SnackBarAlertTypes.ERROR}
      />
    </ContainerBase>
  )
}
