import { Stack, Typography } from '@mui/material'

import LoginLayout from '../../layouts/login'
import { useLocales } from '../../locales'

import AuthLoginForm from './AuthLoginForm'

export default function Login() {
  const { translate } = useLocales()

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">{translate('login:title')}</Typography>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  )
}
