import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import { Box, Card, CardHeader } from '@mui/material'

import { fNumber } from '../../../utils/formatNumber'
import { useLocales } from '../../../locales'

import Chart, { useChart } from '../../../components/chart'

VisitsByAge.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

const StyledChart = styled('div')(() => ({
  '& .apexcharts-tooltip': {
    color: 'black',
  },
}))

export function makeGetPercentageFromRange(ranges = []) {
  return ({ min, max }) => {
    return (
      ranges.find(({ range }) => range.min === min && range.max === max)
        ?.percentage ?? 0
    )
  }
}

export default function VisitsByAge({ data }) {
  const { translate } = useLocales()
  const getPercentageFromRange = makeGetPercentageFromRange(data)
  const series = [
    {
      label: translate('graph:visits-by-age-over-65'),
      value: getPercentageFromRange({ min: 65 }),
    },
    {
      label: translate('graph:visits-by-age-55-64'),
      value: getPercentageFromRange({ min: 55, max: 64 }),
    },
    {
      label: translate('graph:visits-by-age-45-54'),
      value: getPercentageFromRange({ min: 45, max: 54 }),
    },
    {
      label: translate('graph:visits-by-age-30-44'),
      value: getPercentageFromRange({ min: 30, max: 44 }),
    },
    {
      label: translate('graph:visits-by-age-18-29'),
      value: getPercentageFromRange({ min: 18, max: 29 }),
    },
    {
      label: translate('graph:visits-by-age-0-17'),
      value: getPercentageFromRange({ min: 0, max: 17 }),
    },
  ]

  // Warn: If serie is 0, apexchart will throw an error. Seems
  // seems to be an ApexCharts bug, so to avoid this, we use 0.1 as minimum value
  // (will be parsed to 0% in presentation layer).
  const allowedSeries = series.filter(serie => serie.value !== 0)

  const chartSeries = allowedSeries.map(({ value }) => value)
  const chartLabels = allowedSeries.map(({ label }) => label)

  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        formatter: value => fNumber(value) + '%',
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
  })

  return (
    <Card>
      <CardHeader title={translate('graph:visits-by-age')} />

      <Box sx={{ mx: 3 }} dir="ltr">
        <StyledChart dir="ltr">
          <Chart
            type="bar"
            series={[{ data: chartSeries }]}
            options={chartOptions}
            height={364}
          />
        </StyledChart>
      </Box>
    </Card>
  )
}
