const MOBILE_CONTAINER = {
  width: '100%',
  height: '100%',
}

const DESKTOP_CONTAINER = {
  width: 'auto',
  height: 'auto',
}

const ContentModalStyle = (isDesktop: boolean) => {
  return ({
    container: [
      isDesktop ? DESKTOP_CONTAINER : MOBILE_CONTAINER,
      {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'grey.100',
      },
    ],
    content: {
      padding: 3,
    },
    actionBlock: {
      padding: 3,
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: 2,
    }
  })
}

export default ContentModalStyle
