import { useEffect, useState, useCallback } from 'react'
import { fetchAnonymousFrequency } from './anonymousService'
import AnonymousFrequencyModel from './model/AnonymousFrequencyModel'

export type UseAnonymousFrequencyStateType = {
  data: AnonymousFrequencyModel | undefined,
  loading: boolean,
  error: Error | null,
}

function useAnonymousFrequency(
  organizationUUID: string,
  tagGroupUUID: string,
  period: string,
): object {
  const [state, setState] = useState<UseAnonymousFrequencyStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchAnonymousFrequency(organizationUUID, tagGroupUUID, period)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID, period, tagGroupUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    refetch: fetchData,
    clearError,
  }
}

export default useAnonymousFrequency
