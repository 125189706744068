const LayoutStyle = {
  container: {
    display: { lg: 'flex' },
    minHeight: { lg: 1 },
  },
  breadcrumbsContainer: {
    pl: 3,
  },
}

export default LayoutStyle
