import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import style from './CardLoadingSkeletonStyle'
import { Skeleton } from '@mui/material'
import Spacer from '../../Spacer/Spacer'

function CardLoadingSkeleton({
  title,
  icon = null,
  height = 200,
  showLoader = false,
} : {
  title?: string,
  icon?: any,
  height?: number,
  showLoader?: boolean,
}) {
  const getTitle = (): JSX.Element => {
    if (Boolean(title)) {
      return (
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
      )
    }

    return (
      <Skeleton variant="rectangular" width={'50%'} height={20} />
    )
  }

  const getIcon = (): JSX.Element => {
    if (Boolean(icon)) {
      return icon
    }

    return (
      <Skeleton variant="circular" width={20} height={20} />
    )
  }

  const getDataBlock = (): JSX.Element => {
    if (showLoader) {
      return (
      <div
        style={style.loaderContainer}
      >
        <CircularProgress />
      </div>
      )
    }

    return (
      <>
        <Spacer size={2} />
        <Skeleton variant="rectangular" width={'100%'} height={'33%'} />
        <Spacer size={2} />
        <Skeleton variant="rectangular" width={'100%'} height={'33%'} />
      </>
    )
  }


  return (
    <Card sx={[style.container, { height: height }]}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        {getTitle()}
        {getIcon()}
      </Stack>
      {getDataBlock()}
    </Card>
  )
}

export default CardLoadingSkeleton
