import { m } from 'framer-motion'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { MotionContainer, varBounce } from '../components/animate'
import { ForbiddenIllustration } from '../assets/illustrations'
import { useLocales } from '../locales'

export default function PermissionDenied() {
  const navigate = useNavigate()
  const { translate } = useLocales()

  return (
    <>
      <Helmet>
        <title> {translate('403:title:page')} | Summer of 69</title>
      </Helmet>

      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {translate('403:title')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {translate('403:description')}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Button
          component={RouterLink}
          onClick={() => navigate(-1)}
          size="large"
          variant="contained"
        >
          {translate('403:go-home')}
        </Button>
      </Container>
    </>
  )
}
