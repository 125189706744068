import axios from '../../utils/axios'
import { SelectItemType } from '../common/types/SelectItemType'
import { adaptAnonymousAverageVisits, adaptAnonymousDwellTimeResponse } from './anonymousAdapter'
import { AnonymousDwellTimeApiInterface } from './interfaces/AnonymousDwellTimeApiInterface'
import AnonymousDwellTimeModel from './model/AnonymousDwellTimeModel'
import {
  adaptAnonymousAttractionRate,
  adaptAnonymousDetectionsResponse,
  adaptAnonymousFrequencyReponse,
  adaptAnonymousKPIsResponse,
  adaptAnonymousTraficZonesResponse,
} from './anonymousAdapter'
import { AnonymousGenericError } from './anonymousError'
import AnonymousAttractionRateApiInterface from './interfaces/AnonymousAttractionRateApiInterface'
import AnonymousAverageVisitsApiInterface from './interfaces/AnonymousAverageVisitsApiInterface'
import { AnonymousDetectionsApiInterface } from './interfaces/AnonymousDetectionsApiInterface'
import { AnonymousTraficZonesApiInterface } from './interfaces/AnonymousTraficZonesApiInterface'
import AnonymousDetectionsModel from './model/AnonymousDetectionsModel'
import AnonymousKPIsModel from './model/AnonymousKPIsModel'
import AnonymousTraficZonesModel from './model/AnonymousTraficZonesModel'
import { NoLocationsError } from './errors/noLocationsFoundError'
import ApiErrorMessages from '../common/apiErrorMessages'

enum EndpointType {
  KPIS = 'kpis',
  TRAFIC = 'zone-traffic',
  DETECTIONS = 'detections',
  HEAT_MAP = 'heatmap',
}

const getLocationsUUIDsParams = (locations: SelectItemType[]): string => {
  return locations.map(location => `&locationUUID=${location.uuid}`).join('')
}

const getEndpoint = (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
  type: string,
): string => {
  return (
    `/api/v1/organizations/${organizationUUID}/tag-groups/${tagGroupUUID}/anonymous/${type}` +
    `?period=${period}` +
    getLocationsUUIDsParams(locations)
  )
}

export async function fetchAnonymousKPIs (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<AnonymousKPIsModel> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.KPIS,
  )

  try {
    const response = await axios.get(endpoint)

    return adaptAnonymousKPIsResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousTraficZones (
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<AnonymousTraficZonesModel[]> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.TRAFIC,
  )

  try {
    const response = await axios.get<AnonymousTraficZonesApiInterface[]>(
      endpoint,
    )

    return adaptAnonymousTraficZonesResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousDwellTime(
  organizationUUID: string,
  tagGroupUUID: string,
  period: string,
): Promise<AnonymousDwellTimeModel> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/tag-groups/${tagGroupUUID}/anonymous/dwell-rate-gauge?period=${period}`

  try {
    const response = await axios.get<AnonymousDwellTimeApiInterface>(endpoint)

    return adaptAnonymousDwellTimeResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousDetections(
  organizationUUID: string,
  tagGroupUUID: string,
  locations: SelectItemType[],
  period: string,
): Promise<AnonymousDetectionsModel[]> {
  const endpoint = getEndpoint(
    organizationUUID,
    tagGroupUUID,
    locations,
    period,
    EndpointType.HEAT_MAP,
  )

  try {
    const response = await axios.get<AnonymousDetectionsApiInterface[]>(
      endpoint,
    )

    return adaptAnonymousDetectionsResponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousFrequency (
  organizationId: string,
  tagGroupId: string,
  period: string,
) {
  const endpoint = `/api/v1/organizations/${organizationId}/tag-groups/${tagGroupId}/anonymous/registered-loyal-clients?period=${period}`
  try {
    const response = await axios.get(endpoint)

    return adaptAnonymousFrequencyReponse(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousAttractionRate (
  organizationId: string,
  tagGroupId: string,
  period: string,
) {
  const endpoint = `/api/v1/organizations/${organizationId}/tag-groups/${tagGroupId}/anonymous/attraction-rate?period=${period}`

  try {
    const response = await axios.get<AnonymousAttractionRateApiInterface>(
      endpoint,
    )

    return adaptAnonymousAttractionRate(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousAverageVisits (
  organizationId: string,
  tagGroupId: string,
  period: string
) {
  const endpoint = `/api/v1/organizations/${organizationId}/tag-groups/${tagGroupId}/anonymous/visit-average?period=${period}`
  try {
    const response = await axios.get<AnonymousAverageVisitsApiInterface>(endpoint)

    return adaptAnonymousAverageVisits(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}

export async function fetchAnonymousAverageFrequencyVisits(
  organizationId: string,
  tagGroupId: string,
  period: string
) {
  const endpoint = `/api/v1/organizations/${organizationId}/tag-groups/${tagGroupId}/anonymous/visit-frequency-average?period=${period}`
  try {
    const response = await axios.get<AnonymousAverageVisitsApiInterface>(endpoint)

    return adaptAnonymousAverageVisits(response.data)
  } catch (error) {
    if (error.response.data.message === ApiErrorMessages.NoLocationsException) {
      throw new NoLocationsError()
    }
    throw new AnonymousGenericError(endpoint)
  }
}
