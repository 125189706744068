export function calculateIncrementPercentage(current: number, previous: number): string {
  const increment = current - previous;
  const percentageIncrement = (increment / previous) * 100;
  const roundPercentIncrement = Math.round(percentageIncrement * 10) / 10

  return roundPercentIncrement > 0 ? `+${roundPercentIncrement}%` : `${roundPercentIncrement}%`
}

export function calculatePercentageVariant(
  currentOne: number,
  previousOne: number,
  currentTwo: number,
  previousTwo: number,
): string | null {
  const incrementOne = currentOne - previousOne;
  const incrementTwo = currentTwo - previousTwo;
  const percentageIncrementOne = (incrementOne / previousOne) * 100;
  const percentageIncrementTwo = (incrementTwo / previousTwo) * 100;
  const percentageIncrement = (percentageIncrementOne - percentageIncrementTwo) * -1;

  if (isNaN(percentageIncrement)) {
    return null
  }

  const roundPercentIncrement = Math.round(percentageIncrement * 10) / 10

  return roundPercentIncrement > 0 ? `+${roundPercentIncrement}%` : `${roundPercentIncrement}%`

}
