import { Box } from '@mui/material'
import Chart from 'react-apexcharts'
import style from './SemiGaugeGraphStyle'

export default function SemiGaugeGraph({
  series,
}: {
  series: {
    value: number
    label: string
    color?: string
  }[]
}) {
  const normalizedSeries = series.map(serie => ({
    ...serie,
    value: serie.value,
  }))

  const chartOptions = {
    legend: {
      show: false,
    },
    chart: {
      height: 30,
      type: 'radialBar',
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          name: {
            fontSize: '12px',
            color: '#adb6c0',
            offsetY: 10,
          },
          value: {
            offsetY: -30,
            fontSize: '22px',
            color: 'black',
            formatter: function (val: string) {
              return val + '%'
            },
          },
        },
      },
      bar: {
        borderRadius: 4,
        columnWidth: '28%',
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'last',
      },
    },
    fill: {
      type: 'solid',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 0.2,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
      lineCap: 'round',
    },
    labels: series.map(serie => serie.label),
  }

  return (
    <Box sx={style.graphContainer}>
      {/* @ts-ignore */}
      <Chart
        type="radialBar"
        options={chartOptions}
        series={normalizedSeries.map(serie => serie.value)}
      />
    </Box>
  )
}
