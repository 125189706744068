import { useCallback, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router'

import { useLocales } from '../locales'

const MAX_NAME_LENGTH = 50
const MAX_DESCRIPTION_LENGTH = 50

const ERRORS = {
  MISSING_FIELD: 'new-campaign:form-error-missing-field',
  MISSING_IMAGE: 'new-campaign:form-error-missing-image',
  IMAGE_FAILED: 'new-campaign:form-error-image-failed',
  INVALID_PAST_DATE: 'new-campaign:form-error-invalid-past-date',
  INVALID_DATE_RANGE: 'new-campaign:form-error-invalid-date-range',
  NAME_TOO_LONG: 'new-campaign:form-error-name-too-long',
  DESCRIPTION_TOO_LONG: 'new-campaign:form-error-description-too-long',
}

const formReducer = (state, action) => {
  switch (action.type) {
    case 'change':
      return { ...state, [action.field]: action.payload }
    case 'changeFields':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const INITIAL_STATE = {
  name: '',
  description: '',
  start: '',
  end: '',
  text: '',
  image: '',
}

export default function useCampaignForm({ successCallback }) {
  const [shouldValidate, setShouldValidate] = useState(false)
  const { brandId, organizationId } = useParams()
  const { translate } = useLocales()

  const [values, valuesDispatch] = useReducer(formReducer, INITIAL_STATE)
  const [errors, errorsDispatch] = useReducer(formReducer, INITIAL_STATE)
  const [openAlert, setOpenAlert] = useState(false)

  const validateForm = useCallback(() => {
    const nameError = !Boolean(values.name)
      ? translate(ERRORS.MISSING_FIELD)
      : values.name.length > MAX_NAME_LENGTH
      ? translate(ERRORS.NAME_TOO_LONG)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'name',
      payload: nameError,
    })

    const descriptionError = !Boolean(values.description)
      ? translate(ERRORS.MISSING_FIELD)
      : values.description.length > MAX_DESCRIPTION_LENGTH
      ? translate(ERRORS.DESCRIPTION_TOO_LONG)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'description',
      payload: descriptionError,
    })

    let startError = ''
    let endError = ''

    if (!Boolean(values.start)) {
      startError = translate(ERRORS.MISSING_FIELD)
    }

    if (!Boolean(values.end)) {
      endError = translate(ERRORS.MISSING_FIELD)
    }

    if (
      Boolean(values.start) &&
      Boolean(values.end) &&
      values.start >= values.end
    ) {
      startError = translate(ERRORS.INVALID_DATE_RANGE)
      endError = translate(ERRORS.INVALID_DATE_RANGE)
    }

    errorsDispatch({
      type: 'change',
      field: 'start',
      payload: startError,
    })

    errorsDispatch({
      type: 'change',
      field: 'end',
      payload: endError,
    })

    const textError = !Boolean(values.text)
      ? translate(ERRORS.MISSING_FIELD)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'text',
      payload: textError,
    })

    const imageError = !Boolean(values.image)
      ? translate(ERRORS.MISSING_IMAGE)
      : ''

    errorsDispatch({
      type: 'change',
      field: 'image',
      payload: imageError,
    })

    return (
      nameError === '' &&
      descriptionError === '' &&
      startError === '' &&
      endError === '' &&
      textError === '' &&
      imageError === ''
    )
  }, [
    translate,
    values.name,
    values.description,
    values.start,
    values.end,
    values.text,
    values.image,
  ])

  useEffect(() => {
    if (shouldValidate) {
      validateForm()
    }
  }, [shouldValidate, validateForm])

  const handleFieldChange = e => {
    valuesDispatch({
      type: 'change',
      field: e.target.name,
      payload: e.target.value,
    })
  }

  const changeFields = useCallback(values => {
    valuesDispatch({
      type: 'changeFields',
      payload: values,
    })
  }, [])

  const handleDateChange = (field, value) => {
    valuesDispatch({
      type: 'change',
      field,
      payload: value,
    })
  }

  const handleImageChange = file => {
    if (file === null) {
      valuesDispatch({
        type: 'change',
        field: 'image',
        payload: null,
      })

      return
    }

    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      // NOTE: eliminamos el inicio de la cadena que incluye "data:{{mimeType}};base64,"
      const base64 = fileReader.result.split(';base64,')[1]

      valuesDispatch({
        type: 'change',
        field: 'image',
        payload: {
          fileName: file.name,
          mimeType: file.type,
          base64,
        },
      })

      errorsDispatch({
        type: 'change',
        field: 'image',
        payload: '',
      })
    }

    fileReader.onerror = error => {
      errorsDispatch({
        type: 'change',
        field: 'image',
        payload: translate(ERRORS.IMAGE_FAILED),
      })
    }
  }

  const handleImageError = () => {
    errorsDispatch({
      type: 'change',
      field: 'image',
      payload: translate(ERRORS.IMAGE_FAILED),
    })
  }

  const handleSubmit = async () => {
    if (!shouldValidate) {
      setShouldValidate(true)
    }

    const isFormValid = validateForm()

    if (isFormValid) {
      successCallback({
        ...values,
        clientId: organizationId,
        brandId,
      })

      // await createCampaign(campaignData)
      // navigate(PATH_DASHBOARD.campaigns(organizationId, brandId))
    }
  }

  return {
    values,
    errors,
    handleFieldChange,
    changeFields,
    handleDateChange,
    handleImageChange,
    handleImageError,
    handleSubmit,
    openAlert,
    setOpenAlert,
  }
}
