import { useEffect, useState } from 'react'
import PermissionsCard from '../permissions-card/PermissionsCard'
import { useLocales } from '../../locales'
import { PermissionsType } from '../../services/permissions/types/PermissionType'
import OrganizationModel from '../../services/organizations/model/OrganizationModel'
import PermissionsValue from '../../services/common/enum/PermissionsValues'
import PermissionType from '../../services/common/types/PermissionType'

export default function CreateUserPermissions({
  organizations,
  form,
  error,
  onBlur,
  handleChangePermissions,
}: {
  organizations: OrganizationModel[] | OrganizationModel,
  form: any
  error?: string,
  onBlur: () => void,
  handleChangePermissions: (permission: PermissionType) => void
}) {
  const [permissions, setPermissions] = useState<PermissionsType[]>([])
  const { translate } = useLocales()
  const selectedOrganization = Array.isArray(organizations)
    ? organizations.find((organization) => organization.uuid === form.organizationId)
    : organizations;


  const getUserPermissions = (): void => {
    
    const permissions: PermissionsType[] = [
      {
        label: `${translate('new-user:form:permission:anonymous-module')}`,
        value: PermissionsValue.ANONYMOUS_PERMISSION,
        checked: form?.permissions?.[PermissionsValue.ANONYMOUS_PERMISSION] ?? false,
      }
    ]
    
    if(selectedOrganization?.permissions[PermissionsValue.REGISTERED_PERMISSION]) {
      permissions.push(
        {
          label: `${translate('new-user:form:permission:captation-permission')}`,
          value: PermissionsValue.CAPTATION_PERMISSION,
          checked: form?.permissions?.[PermissionsValue.CAPTATION_PERMISSION] ?? false,
        },
        {
          label: `${translate('new-user:form:permission:registered-module')}`,
          value: PermissionsValue.REGISTERED_PERMISSION,
          checked: form?.permissions?.[PermissionsValue.REGISTERED_PERMISSION] ?? false,
        }
      )
    }

    setPermissions(permissions);
  }
  
  useEffect(() => {
    getUserPermissions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.organizationId, form, selectedOrganization])
  

  return (
    <PermissionsCard
      cardTitle={`${translate('users:table:heading:permissions')}`}
      permissions={permissions}
      error={error}
      onChangePermissions={(value: string, checked: boolean) => {
        handleChangePermissions({value, checked})
      }
    }
    onBlur={onBlur}
    />
  )
}
