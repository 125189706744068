import { useAuthContext } from '../../auth/useAuthContext'
import { useNavLinksDashboard } from './config-navigation'
import ContentLayout from '../ContentLayout/ContentLayout'
import { NavigationSectionType } from '../../services/common/types/NavigationSectionType'

export default function DashboardLayout() {
  const { user } = useAuthContext()
  const navLinks: NavigationSectionType[] = useNavLinksDashboard(user) as any

  return <ContentLayout navLinks={navLinks} />
}
