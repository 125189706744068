const icon = {
  padding: .5,
  borderRadius: '50%'
}

const KPI_CARD_STYLE = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    p: 3,
    gap: 2,
  },
  totalBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  previousTotalText: {
    color: 'grey.600'
  },
  incrementContainer: {
    gap: 1,
  },
  incrementBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  upIcon: {
    ...icon,
    color: 'primary.main',
    backgroundColor: 'primary.clearest'
  },
  downIcon: {
    ...icon,
    color: 'error.main',
    backgroundColor: 'error.lighter'
  },
  textZone: {
    color: 'grey.600'
  },
  titleContainer: {
    display: 'flex',
  }
}

export default KPI_CARD_STYLE
