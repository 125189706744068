import { useTheme } from '@mui/material/styles'
import { Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import useOffSetTop from '../../../hooks/useOffSetTop'
import useResponsive from '../../../hooks/useResponsive'
import { HEADER } from '../../../config-global'
import Logo from '../../../components/logo'
import Iconify from '../../../components/iconify'
import { useSettingsContext } from '../../../components/settings'
import AccountPopover from '../../../components/account-popover'
import LanguagePopover from '../../../components/language-popover/LanguagePopover'
import { useLocales } from '../../../locales'
import { useAuthContext } from '../../../auth/useAuthContext'
import ContentLayoutHeaderStyle from './ContentLayoutHeaderStyle'
import { isLayoutHorizontal, isLayoutMini } from '../../../utils/layoutHelper'

export default function ContentLayoutHeader({
  onOpenNav
} : {
  onOpenNav: () => void
}) {
  const { translate } = useLocales()
  const theme = useTheme()
  const { themeLayout } = useSettingsContext()
  const { user } = useAuthContext() as any

  const isNavHorizontal = isLayoutHorizontal(themeLayout)
  const isDesktop = useResponsive('up', 'lg')
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal

  const LogoComponent = Logo as any;
  const IconifyComponent = Iconify as any;
  const AppBarComponent = AppBar as any;

  const style = ContentLayoutHeaderStyle(theme, isDesktop, isOffset, isNavHorizontal, isLayoutMini(themeLayout))

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <LogoComponent sx={style.logoComponent} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={style.iconButton}>
          <IconifyComponent icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Typography variant="h6" color="text.primary">
        {`${translate('navigation-welcome', { user: user.name })}`}
      </Typography>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <LanguagePopover />
        <AccountPopover />
      </Stack>
    </>
  )

  return (
    <AppBarComponent
      sx={style.container}
    >
      <Toolbar
        sx={style.toolbar}
      >
        {renderContent}
      </Toolbar>
    </AppBarComponent>
  )
}
