import { HEADER, NAV } from "../../../config-global"

const SPACING = 8

const MainContainerStyle = (isNavMini: boolean, isDesktop: boolean) => ({
  container: {
    flexGrow: 1,
    py: `${HEADER.H_MOBILE + SPACING}px`,
    ...(isDesktop && {
      px: 2,
      py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
      width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
      ...(isNavMini && {
        width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
      }),
    }),
  }
})

export default MainContainerStyle
