import { Stack, Typography } from '@mui/material'
import style from './ViewHeaderStyle'
import PrimaryButton from '../button/PrimaryButton/PrimaryButton'

export default function ViewHeader({
  title,
  buttonLabel,
  isButtonShown,
  onButtonClick,
}: {
  title: string
  buttonLabel: string
  isButtonShown?: boolean
  onButtonClick: () => void
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      alignItems="center"
      sx={style.container}
    >
      <Typography variant="h4">{title}</Typography>
      {isButtonShown && (
        <PrimaryButton icon="eva:plus-fill" onClick={onButtonClick}>
          {buttonLabel}
        </PrimaryButton>
      )}
    </Stack>
  )
}
