import { useCallback, useEffect, useState } from "react"
import NodeInfoModel from "./model/NodeInfoModel"
import { fetchNodes } from "./nodeService"

type OrderType = 'asc' | 'desc'
type NodeStateType = {
  data: NodeInfoModel[] | undefined,
  loading: boolean,
  error: Error | null,
}

function useNodes(order: OrderType, orderBy: string) {
  const [state, setState] = useState<NodeStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      const response = await fetchNodes()

      setState({
        data: response,
        loading: false,
        error: null,
      })


    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    fetchData()
  }, [fetchData])

  const getDataFromNodoToSort = (node: NodeInfoModel, orderBy: string) => {
    switch (orderBy) {
      case 'organization':
        return node.organization?.name ?? ''
      case 'foreignID':
        return node.foreignID
      case 'status':
        return node.status
      case 'location':
        return node.location?.name ?? ''
      case 'zone':
        return node.zone?.name ?? ''
      }

    return ''
  }

  state.data?.sort((nodeA, nodeB) => {
    const fieldNodeA = getDataFromNodoToSort(nodeA, orderBy)
    const fieldNodeB = getDataFromNodoToSort(nodeB, orderBy)
    if (order === 'asc') {
      return fieldNodeA > fieldNodeB ? 1 : -1
    }

    return fieldNodeA < fieldNodeB ? 1 : -1
  })

  return {
    ...state,
    refetch: fetchData,
  }
}

export default useNodes
