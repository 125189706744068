const averageCardStyle = {
  card: {
    width: '100%',
    p: {
      xs: 4,
      lg: 2,
      xl: 4,
    },
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
  textContainer: {
    textAlign: 'center',
    width: '60%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

export default averageCardStyle
