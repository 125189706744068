import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import { useLocales } from '../../locales'
import FormInfoSection from '../form/FormInfoSection/FormInfoSection'
import style from './OrganizationSectionFormStyle'
import { PermissionsType } from '../../services/permissions/types/PermissionType'
import PermissionsCard from '../permissions-card/PermissionsCard'

export default function OrganizationModulesForm({
  form,
  permissions,
}: {
  form: any
  permissions: PermissionsType[]
}) {
  const { translate } = useLocales()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <FormInfoSection
          title={`${translate('new:organization:modules:title')}`}
          subtitle={`${translate('new:organization:modules:description')}`}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={style.card}>
          <form>
            <FormControl sx={style.form}>
              <PermissionsCard
                permissions={permissions}
                onChangePermissions={(value, checked) => form.updatePermission({value, checked})}
                loading={false}
              />
            </FormControl>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}
