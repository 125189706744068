import './locales/i18n'
import 'simplebar-react/dist/simplebar.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import Router from './routes'
import ThemeProvider from './theme'
import ThemeLocalization from './locales'
import SnackbarProvider from './components/snackbar'
import { ThemeSettings, SettingsProvider } from './components/settings'
import { MotionLazyContainer } from './components/animate'
import ScrollToTop from './components/scroll-to-top'
import { AuthProvider } from './auth/JwtContext'
import { store } from './redux/store'

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <Provider store={store}>
                        <Router />
                      </Provider>
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  )
}
