import { useNavigate, useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { useLocales } from "../../../locales";
import CardGridContainer from "../../../components/grid/CardGridContainer/CardGridContainer";
import TagCard from "../../../components/card/TagCard/TagCard";
import ViewContainer from "../../../components/container/ViewContainer/ViewContainer";
import ViewHeader from "../../../components/ViewHeader/ViewHeader";
import GridContainer from "../../../components/grid/GridContainer/GridContainer";
import useTaxonomies from "../../../services/taxonomies/useTaxonomies";
import TaxonomyModel from "../../../services/taxonomies/model/TaxonomyModel";
import { PATH_ACCOUNTS } from "../../../routes/paths";
import useRemoveStatus from "../../../hooks/useRemoveStatus";
import useSnackbarMessageByQueryParams from "../../../hooks/useSnackbarMessageByQueryParams";
import NoItemsCard from "../../../components/no-items-card/NoItemsCard";
import CardSkeleton from "../../../components/skeleton/CardSkeleton/CardSkeleton";
import DashboardError from "../../../components/dashboard-error/DashboardError";
import ViewInfoActions from "../../../components/ViewInfoActions/ViewInfoActions";
import SnackBarAlertTypes from "../../../components/alert/SnackbarAlert/SnackBarAlertTypes";

export default function Taxonomies() {
  const { translate } = useLocales()
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const removeStatus = useRemoveStatus()
  const { data, error, loading, remove, refetch } = useTaxonomies(organizationId || '') as any

  const [snackbarMessage, closeSnackbarMessage] =
    useSnackbarMessageByQueryParams({
      created: 'taxonomy:created',
      updated: 'taxonomy:updated',
    }) as any

  const navigateToNewTaxonomyView = () => {
    navigate(PATH_ACCOUNTS.newTaxonomy(organizationId))
  }

  const navigateToEditTaxonomyView = (taxonomyId: string) => {
    navigate(PATH_ACCOUNTS.editTaxonomy(organizationId, taxonomyId))
  }

  const getRequestMessage = (): string | null => {
    if (loading) {
      return `${translate('loading')}`
    }

    return null
  }

  async function handleDeleteTaxonomy() {
    try {
      await remove(organizationId, removeStatus.id)
      await refetch()
      removeStatus.activateFeedback(
        SnackBarAlertTypes.SUCCESS,
        translate('taxonomy:remove:success'),
      )
    } catch (error) {
      removeStatus.activateFeedback(SnackBarAlertTypes.ERROR, translate('taxonomy:remove:error'))
    }
  }

  const getTaxonomies = (): JSX.Element => {
    if (loading) {
      return <CardSkeleton />
    }

    if (!data || data.length === 0) {
      return (
        <NoItemsCard
          ctaLabel={`${translate('view:taxonomies:add:button')}`}
          handleClick={navigateToNewTaxonomyView}
          title={`${translate('taxonomies:empty-content:title')}`}
          description={`${translate('taxonomies:empty-content:description')}`}
        />
      )
    }

    return (
      <CardGridContainer>
        {data.map((taxonomy: TaxonomyModel, index: number) => (
          <TagCard
            key={`${index}-${taxonomy.name}`}
            name={taxonomy.name}
            tags={taxonomy.tags}
            onClickEdit={() => navigateToEditTaxonomyView(taxonomy.uuid)}
            onClickDelete={() => removeStatus.activateConfirmation(taxonomy.uuid)}
          />
        ))}
      </CardGridContainer>
    )
  }

  if (error) {
    return <DashboardError title={translate('view:taxonomies:title')} />
  }

  return (
    <>
      <Helmet>
        <title>{`${translate('taxonomies')}`} | So69</title>
      </Helmet>
      <ViewContainer>
        <GridContainer>
          <ViewHeader
            title={`${translate('view:taxonomies:title')}`}
            buttonLabel={`${translate('view:taxonomies:add:button')}`}
            onButtonClick={navigateToNewTaxonomyView}
            isButtonShown={true}
          />
          {getTaxonomies()}
        </GridContainer>
      </ViewContainer>
      <ViewInfoActions
        confirmTitle={`${translate('taxonomy:remove:confirm:title')}`}
        removeStatus={removeStatus}
        snackbarMessage={snackbarMessage}
        handleConfirm={handleDeleteTaxonomy}
        closeSnackbarMessage={closeSnackbarMessage}
        requestMessage={getRequestMessage()}
      />
    </>
  )
}
