import { useEffect, useState } from 'react'
import { useLocales } from '../../../locales'
import { FormControl, Stack, Typography, useTheme } from '@mui/material'
import UnsuscribeCapaingStyle from './UnsuscribeOrganizationStyle'
import PrimaryButton from '../../../components/button/PrimaryButton/PrimaryButton'
import Logo from '../../../components/logo'
import { fetchPublicOrganization, unsuscribeOrganization } from '../../../services/organizations/service'
import useQueryParams from '../../../hooks/useQueryParams'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'

export default function UnsuscribeOrganization() {
  const { translate } = useLocales()
  const queryParams = useQueryParams()

  const organizationId = queryParams.get('organizationId');
  const customerId = queryParams.get('customerId');
  const [organizationName, setOrganizationName] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const theme = useTheme()
  const style = UnsuscribeCapaingStyle(theme)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    async function fetchData() {
      try {
        const organizationResponse =  await fetchPublicOrganization(organizationId!)

        setOrganizationName(organizationResponse)
      } catch (error) {
        setError(`${translate('organization:fetch:generic:error')}`)
      }
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const submit = async (): Promise<void> => {
    try {
      await unsuscribeOrganization(organizationId!, customerId!)

      setIsSuccess(true)
    } catch (error) {
      setError(`${translate('organization:unsuscribe:generic:error')}`)
    }
  }

  const getTitle = () => {
    return isSuccess ? `${translate('unsuscribe:success:title')}` : `${translate('unsuscribe:title')} ${organizationName}`
  }

  const getText = () => {
    return isSuccess ? `${translate('unsuscribe:success:text')}` : `${translate('unsuscribe:text')}`
  }

  const getButton = () => {
    if (isSuccess) {
      return <></>
    }

    return (
      <PrimaryButton onClick={submit} fullWidth>
        {`${translate('unsuscribe:button')}`}
      </PrimaryButton>
    )
  }

  return (
    <Stack sx={style.container}>
      <FormControl sx={style.form}>
        <Logo />
        <Typography variant="h3">
          {getTitle()}
        </Typography>
        <Typography variant="body2" align='center'>
          {getText()}
        </Typography>
        {getButton()}
      </FormControl>
      <SnackbarAlert
        isOpen={error !== ''}
        message={error}
        onClose={() => setError('')}
        type={SnackBarAlertTypes.ERROR}
      />
    </Stack>
  )
}
