import {
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'
import PrimaryButton from '../button/PrimaryButton/PrimaryButton'
import style from './NoItemsCardStyle'

export default function NoItemsCard({
  title,
  description,
  ctaLabel,
  handleClick,
}: {
  title: string
  description: string
  ctaLabel?: string
  handleClick?: () => void
}) {

  return (
    <Card sx={style.cardContainer}>
      <CardContent sx={style.infoContainer}>
        <Typography variant="h5" sx={style.titleContainer}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        {handleClick && ctaLabel ? (
          <PrimaryButton
          icon={'eva:plus-fill'}
          onClick={handleClick}
          >
            {ctaLabel}
          </PrimaryButton>
        ) : null}
      </CardActions>
    </Card>
  )
}
