import NodeUpdatePayloadModel from "./model/NodeUpdatePayloadModel";
import NodeInfoModel from "./model/NodeInfoModel";
import { NodeApiInterface } from "./interface/NodeApiInterface";
import NodeSingleModel from "./model/NodeSingleModel";

export function adaptNodeFormValuesToCreatePayload(payload: any): NodeUpdatePayloadModel {
  return new NodeUpdatePayloadModel(
    payload.description,
    payload.organization.uuid,
    payload.location.uuid,
    payload.zone.uuid,
  )
}

export function adaptNodeFormValuesToUpdatePayload(payload: any): NodeUpdatePayloadModel {
  return new NodeUpdatePayloadModel(
    payload.description,
    payload.organization.uuid,
    payload.location.uuid,
    payload.zone.uuid,
  )
}

export function adaptNodesResponse(response: NodeApiInterface[]): NodeInfoModel[] {
  return response.map((node) => {
    return new NodeInfoModel(
      node.uuid,
      node.status,
      node.foreignID,
      node.populated,
      node.organization,
      node.location,
      node.zone,
    )
  })
}

export function adaptNodeSingleResponse(response: NodeApiInterface): NodeSingleModel {
  return new NodeSingleModel(
    response.description,
    response.foreignID,
    response.location,
    response.organization,
    response.populated,
    response.status,
    response.uuid,
    response.zone,
  )
}
