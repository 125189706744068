enum LAYOUT_TYPE {
  HORIZONTAL = 'horizontal',
  MINI = 'mini',
}

export function isLayoutHorizontal(layout: string): boolean {
  return layout === LAYOUT_TYPE.HORIZONTAL
}

export function isLayoutMini(layout: string): boolean {
  return layout === LAYOUT_TYPE.MINI
}
