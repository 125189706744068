import { format, getTime, formatDistanceToNow, isSameDay } from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy'

  return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p'

  return date ? format(new Date(date), fm) : ''
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : ''
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : ''
}

export const isToday = date => {
  return isSameDay(new Date(), new Date(date))
}

export function secondsToHoursMinutesSeconds(seconds) {
  const formattedSeconds = Math.round(seconds, 1)
  const hours = Math.floor(formattedSeconds / 3600)
  const minutes = Math.floor((formattedSeconds % 3600) / 60)
  const secs = formattedSeconds % 60

  const hoursFormatted = String(hours).padStart(2, '0')
  const minutesFormatted = String(minutes).padStart(2, '0')
  const secondsFormatted = String(secs).padStart(2, '0')

  return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`
}

export function fDayOfWeek(date) {
  return date ? format(new Date(date), 'EEEE') : '';
}
