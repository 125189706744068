import { Card, Grid, Typography } from '@mui/material'
import AnonymousKPIsModel from '../../../services/anonymous/model/AnonymousKPIsModel'
import KPI_CARD from '../../card/KPI_CARD/KPI_CARD'
import { ZONES } from '../../../utils/zones';
import GridStyle from '../../../style/components/GridStyle';
import style from './AnonymousKPIsStyle'
import { useLocales } from '../../../locales';
import { calculatePercentageVariant } from '../../../utils/mathHelper';

export default function AnonymousKPIs({
  anonymousKPIs,
} : {
  anonymousKPIs: AnonymousKPIsModel,
}): JSX.Element {
  const { translate } = useLocales()

  const getKpiCard = (zoneNumber: number, anonymousKPIs: AnonymousKPIsModel): JSX.Element => {
    return (
      <Grid item xs={12} md={3.4}>
        <KPI_CARD
          zoneNumber={zoneNumber}
          anonymousKPIs={anonymousKPIs}
        />
      </Grid>
    )
  }

  const getVariant = (title: string, value: string): JSX.Element => {
    return (
      <>
        <Typography variant="subtitle1"
          sx={style.variant}
        >
          {title}
        </Typography>
        <Typography variant="body2">
          ({value})
        </Typography>
      </>
    )
  }

  const getVariantBlock = (title: string, variant: string | null) => {
    if (!variant) {
      return <></>
    }

    return getVariant(title, variant)
  }

  const getPercentBlock = (
    visitorsVariant: string | null,
    visitVariant: string | null,
    avgTimeVariant: string | null,
  ): JSX.Element => {
    return (
      <Grid item xs={12} md={0.9}>
        <Card sx={[style.percentCard, GridStyle.smallCard]}>
          {getVariantBlock(`${translate('anonymous:kpis:visitors')}`, visitorsVariant)}
          {getVariantBlock(`${translate('anonymous:kpis:visits')}`, visitVariant)}
          {getVariantBlock(`${translate('anonymous:kpis:avg:time')}`, avgTimeVariant)}
        </Card>
      </Grid>
    )
  }

  if (!anonymousKPIs) {
    return <></>
  }

  return (
      <>
        {getKpiCard(
          ZONES.ZONE_3.value,
          anonymousKPIs,
        )}
        {getPercentBlock(
          calculatePercentageVariant(
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_3.value]?.current,
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_3.value]?.previous,
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_2.value]?.current,
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_2.value]?.previous,
          ),
          calculatePercentageVariant(
            anonymousKPIs.detections_sum[ZONES.ZONE_3.value]?.current,
            anonymousKPIs.detections_sum[ZONES.ZONE_3.value]?.previous,
            anonymousKPIs.detections_sum[ZONES.ZONE_2.value]?.current,
            anonymousKPIs.detections_sum[ZONES.ZONE_2.value]?.previous,
          ),
          calculatePercentageVariant(
            anonymousKPIs.average_time_stay[ZONES.ZONE_3.value]?.current,
            anonymousKPIs.average_time_stay[ZONES.ZONE_3.value]?.previous,
            anonymousKPIs.average_time_stay[ZONES.ZONE_2.value]?.current,
            anonymousKPIs.average_time_stay[ZONES.ZONE_2.value]?.previous,
          ),
        )}
        {getKpiCard(
          ZONES.ZONE_2.value,
          anonymousKPIs,
        )}
        {getPercentBlock(
          calculatePercentageVariant(
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_2.value]?.current,
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_2.value]?.previous,
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_CHECKOUT.value]?.current,
            anonymousKPIs.unique_detections_sum[ZONES.ZONE_CHECKOUT.value]?.previous,
          ),
          calculatePercentageVariant(
            anonymousKPIs.detections_sum[ZONES.ZONE_2.value]?.current,
            anonymousKPIs.detections_sum[ZONES.ZONE_2.value]?.previous,
            anonymousKPIs.detections_sum[ZONES.ZONE_CHECKOUT.value]?.current,
            anonymousKPIs.detections_sum[ZONES.ZONE_CHECKOUT.value]?.previous,
          ),
          calculatePercentageVariant(
            anonymousKPIs.average_time_stay[ZONES.ZONE_2.value]?.current,
            anonymousKPIs.average_time_stay[ZONES.ZONE_2.value]?.previous,
            anonymousKPIs.average_time_stay[ZONES.ZONE_CHECKOUT.value]?.current,
            anonymousKPIs.average_time_stay[ZONES.ZONE_CHECKOUT.value]?.previous,
          ),
        )}
        {getKpiCard(
          ZONES.ZONE_CHECKOUT.value,
          anonymousKPIs,
        )}
      </>
  )
}
