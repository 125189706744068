const TagCardStyle = {
  container: {
    padding: 3,
    width: '100%'
  },
  clickable: {
    cursor: 'pointer',
  },
  headerContainer: {
    gap: 3
  },
  cardHeader: {
    padding: 0,
    flexGrow: 1,
    textDecoration: 'none',
    color: 'text.primary',
  }
}

export default TagCardStyle
