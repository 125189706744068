import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocales } from '../../../locales'
import LocationForm from '../../../sections/dashboard/locations/LocationForm'
import useLocationMutation from '../../../services/location/useLocationMutation'
import useLocationForm from '../../../hooks/useLocationForm'
import { useLocation } from '../../../services/location/useLocations'
import { adaptResponseToFormValues } from '../../../services/location/locationAdapters'
import SnackbarAlert from '../../../components/alert/SnackbarAlert/SnackbarAlert'
import SnackBarAlertTypes from '../../../components/alert/SnackbarAlert/SnackBarAlertTypes'
import { TaxonomyNotExistsError } from '../../../services/location/Error'

export default function EditLocation() {
  const { translate } = useLocales()
  const { organizationId, locationId } = useParams()
  const location = useLocation(organizationId ?? '', locationId ?? '')
  const mutation = useLocationMutation(organizationId ?? '') as any
  const { changeFieldsValues, ...form } = useLocationForm({
    successCallback: (payload: any) => mutation.update(locationId ?? '')(payload, organizationId ?? ''),
  })

  useEffect(() => {
    if (location.data !== undefined) {
      changeFieldsValues(adaptResponseToFormValues(location.data))
    }
  }, [location.data, changeFieldsValues])

  const getErrorMessage = (): string => {
    if (!mutation.error) {
      return ''
    }

    if (mutation.error instanceof TaxonomyNotExistsError) {
      return `${translate('edit-location:taxonomy-not-exist-message')}`
    }

    return `${translate('edit-location:submit-error-message')}`
  }

  return (
    <>
      <LocationForm form={form} organizationId={organizationId ?? ''} editing />
      <SnackbarAlert
        isOpen={mutation.error !== null}
        onClose={mutation.clearError}
        type={SnackBarAlertTypes.ERROR}
        message={getErrorMessage()}
      />
      <SnackbarAlert
        isOpen={mutation.loading}
        message={`${translate('new-location:submitting-form-info-message')}`}
        type={SnackBarAlertTypes.INFO}
      />
    </>
  )
}
