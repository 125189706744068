import { AnonymousKPIsInterface, ZonesInterface, ZonesThreePointsInterface } from "../interfaces/AnonymousKPIsInterface";

export default class AnonymousKPIsModel implements AnonymousKPIsInterface {
  constructor(
    readonly unique_detections_sum: ZonesInterface,
    readonly detections_sum: ZonesInterface,
    readonly detections_average: ZonesInterface,
    readonly average_time_stay: ZonesInterface,
    readonly new_vs_loyal: ZonesThreePointsInterface,
    readonly average_time_comeback: ZonesInterface,
  ) {}
}
