import { useState } from 'react'

import { createNewUser } from './service'
import { adaptUserFormValuesToRequestPayload } from './adapters'
import UserFormModel from './model/UserFormModel'
import { generateUUID } from '../../utils/uuidGenerator';

interface UserRegistrationState {
  loading: boolean;
  error: string | null;
  success: boolean;
}

export default function useUserRegistration() {
  const [loading, setLoading] = useState<UserRegistrationState>(({
    loading: false,
    error: null,
    success: false,
  }))

  const createUser = async (formValues: UserFormModel) => {
    setLoading({
      loading: true,
      error: null,
      success: false,
    })

    try {
      //TODO: Remove any
      await createNewUser(adaptUserFormValuesToRequestPayload(formValues, generateUUID()) as any)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading({
        ...loading,
        loading: false,
      })
    }
  }

  return {
    createUser,
    loading,
  }
}
