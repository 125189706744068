import axios from '../../utils/axios'
import { FetchTaxonomyError, InvalidCreateTaxonomyPayloadError, TaxonomyGenericError } from './taxonomyError'
import { adaptTaxonomiesResponse, adaptTaxonomyResponse } from './taxonomyAdapter'
import TaxonomyModel from './model/TaxonomyModel'
import TaxonomyCreatePayloadModel from './model/TaxonomyCreatePayloadModel'
import TaxonomyUpdatePayloadModel from './model/TaxonomyUpdatePayloadModel'

const TAXONOMY_ALREADY_EXIST_ERROR = 'TaxonomyAlreadyExists';

export async function createTaxonomy(organizationUUID: string, payload: TaxonomyCreatePayloadModel): Promise<void> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/taxonomies`

  try {
    await axios.post(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    if (error.response.data.name === TAXONOMY_ALREADY_EXIST_ERROR) {
      throw new InvalidCreateTaxonomyPayloadError(endpoint)
    }

    throw new TaxonomyGenericError(endpoint)
  }
}

export async function updateTaxonomy(
  organizationUUID: string,
  taxonomyUUID: string,
  payload: TaxonomyUpdatePayloadModel,
): Promise<void> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/taxonomies/${taxonomyUUID}`

  try {
    await axios.put(endpoint, payload)
    return Promise.resolve()
  } catch (error) {
    throw new TaxonomyGenericError(endpoint)
  }
}

export async function fetchTaxonomies(organizationUUID: string | null): Promise<TaxonomyModel[]> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/taxonomies`

  try {
    const response = await axios.get(endpoint)

    return adaptTaxonomiesResponse(response.data)
  } catch (error) {
    // TODO - manage all errors
    throw new FetchTaxonomyError(endpoint)
  }
}

export async function fetchTaxonomy(organizationUUID: string, taxonomyUUID: string): Promise<TaxonomyModel> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/taxonomies/${taxonomyUUID}`

  try {
    const response = await axios.get(endpoint)

    return adaptTaxonomyResponse(response.data)
  } catch (error) {
    // TODO - manage all errors
    throw new FetchTaxonomyError(endpoint)
  }
}

export async function removeTaxonomy(organizationUUID: string, taxonomyUUID: string): Promise<void> {
  const endpoint = `/api/v1/organizations/${organizationUUID}/taxonomies/${taxonomyUUID}`

  try {
    await axios.delete(endpoint)
  } catch (error) {
    throw new TaxonomyGenericError(endpoint)
  }
}
