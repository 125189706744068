import { useEffect, useState, useCallback } from 'react'
import { fetchTagGroups, removeTagGroup } from './tagGroupService'
import TagGroupModel from './model/TagGroupModel'

export type TagGroupStateType = {
  data: TagGroupModel[] | undefined,
  loading: boolean,
  error: Error | null,
}

function useTagGroups(organizationUUID: string): object {
  const [state, setState] = useState<TagGroupStateType>({
    data: undefined,
    loading: true,
    error: null,
  })

  const fetchData = useCallback(async () => {
    setState((state: any) => ({
      ...state,
      loading: true,
      error: null,
    }))

    try {
      if(!organizationUUID) return

      const response = await fetchTagGroups(organizationUUID)

      setState({
        data: response,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: undefined,
        loading: false,
        error,
      })
    }
  }, [organizationUUID])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  async function remove(organizationUUID: string, tagGroupUUID: string): Promise<void> {
    await removeTagGroup(organizationUUID, tagGroupUUID)
  }

  return {
    ...state,
    refetch: fetchData,
    remove
  }
}

export default useTagGroups
