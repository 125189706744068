import { useLocales } from '../../../locales'
import useNodeForm from '../../../hooks/useNodeForm'
import NodeForm from '../../../sections/dashboard/nodes/NodeForm/NodeForm'
import FormContainer from '../../../components/form/FormContainer/FormContainer'
import useNodeMutation from '../../../services/Nodes/useNodeMutation'

export default function NewNode() {
  const { translate } = useLocales()
  const mutation = useNodeMutation() as any
  const form = useNodeForm({ successCallback: mutation.create() })

  return (
    <FormContainer
      title={`${translate('create:node:title')}`}
      showError={mutation.error !== null}
      errorMessage={`${translate('create:node:error:generic')}`}
      onCloseError={() => mutation.clearError()}
    >
      <NodeForm form={form} submitButtonLabel={`${translate('create:node:button')}`} />
    </FormContainer>
  )
}
