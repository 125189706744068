import { useState } from 'react'
import { useNavigate } from 'react-router'
import { PATH_ACCOUNTS } from '../../routes/paths'
import { createOrganization, updateOrganization } from './service'
import { adaptOrganizationFormValuesToPayload } from './adapters'

function useOrganizationMutation() {
  const navigate = useNavigate()

  const [state, setState] = useState({
    success: false,
    loading: false,
    error: null,
  })

  const create = async (formValues: any) => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {
      await createOrganization(await adaptOrganizationFormValuesToPayload(formValues, true))

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.organizations}?created=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const update = (id: any) => async (formValues: any) => {
    setState({
      success: false,
      loading: true,
      error: null,
    })

    try {      
      await updateOrganization(
        id,
        await adaptOrganizationFormValuesToPayload(formValues, false),
      )

      setState(state => ({
        ...state,
        loading: false,
        success: true,
      }))

      navigate(`${PATH_ACCOUNTS.organizations}?updated=true`)

      return Promise.resolve()
    } catch (error) {
      setState({
        loading: false,
        success: false,
        error,
      })

      return Promise.reject(error)
    }
  }

  const clearError = () => {
    setState(state => ({
      ...state,
      error: null,
    }))
  }

  return {
    ...state,
    create,
    update,
    clearError,
  }
}

export default useOrganizationMutation
