const TotalVisitsGraphStyle = {
  container: {
    width: '100%',
    p: 3,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  }
}

export default TotalVisitsGraphStyle
