import PropTypes from 'prop-types'
import { TableRow, TableCell } from '@mui/material'
import ActionPopover from '../../users/table/ActionsPopover'
import { ROLES } from '../../../../utils/rolesAndPermissions'

LocationsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
}

export default function LocationsTableRow({
  row,
  role,
  selected,
  handleDelete,
  handleEdit,
}) {
  const { name, address } = row

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left">{address}</TableCell>
        <TableCell align="right">
          <ActionPopover
            handleDelete={role === ROLES.SUPER_ADMIN ? handleDelete : undefined}
            handleEdit={handleEdit}
          />
        </TableCell>
      </TableRow>
    </>
  )
}
