import { Stack, Box, useTheme } from '@mui/material'
import Logo from '../../../components/logo'
import NavSectionCollapsed from '../../../components/nav-section/NavSectionCollapsed/NavSectionCollapsed'
import NavToggleButton from '../NavToggleButton/NavToggleButton'
import NavMiniStyle from './NavMiniStyle'
import { NavigationSectionType } from '../../../services/common/types/NavigationSectionType'

export default function NavMini({
  navLinks,
} : {
  navLinks: NavigationSectionType[],
}) {
  const theme = useTheme()
  const style = NavMiniStyle(theme)

  return (
    <Box
      component="nav"
      sx={style.container}
    >
      <NavToggleButton isCollapsed />
      <Stack sx={style.blockContainer}>
        <Box sx={style.logoContainer}>
          <Logo />
        </Box>

        <NavSectionCollapsed data={navLinks} />
      </Stack>
    </Box>
  )
}
