import Typography from '@mui/material/Typography'
import style from './FormInfoSectionStyle'

export default function FormInfoSection({
  title,
  subtitle,
} : {
  title: string,
  subtitle: string,
}) {
  return (
    <>
      <Typography variant="h5">
        {title}
      </Typography>
      <Typography sx={style.subtitle}>
        {subtitle}
      </Typography>
    </>
  )
}
