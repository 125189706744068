export class FetchTaxonomyError extends Error {
  constructor(readonly endpoint: string) {
  super(`Error fetching users from endpoint ${endpoint}`)
    this.name = 'FetchUsersError'
  }
}

export class InvalidCreateTaxonomyPayloadError extends Error {
  constructor(endpoint: string) {
    super(`Error creating new taxonomy from endpoint ${endpoint}`)
    this.name = 'InvalidNewTaxonomyPayloadError'
  }
}

export class TaxonomyGenericError extends Error {
  constructor(readonly endpoint: string) {
    super(`Error in taxonomy from endpoint ${endpoint}`)
    this.name = 'TaxonomyGenericError'
  }
}
