import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchUserInfo } from './service'
import { updateAll as updateOrganization } from '../../redux/organizationsSlice'
import {
  updateLoading as updateLoadingBrands,
  updateError as updateErrorBrands,
} from '../../redux/brandsSlice'
import {
  updateError as updateErrorStores,
  updateLoading as updateLoadingStores,
} from '../../redux/storesSlice'

export default function useUserInfo() {
  const dispatch = useDispatch()

  const fetchData = useCallback(async () => {
    try {
      dispatch(updateLoadingStores(true))
      dispatch(updateLoadingBrands(true))

      const userInfo = await fetchUserInfo()
      dispatch(updateOrganization(userInfo.organizations))

      dispatch(updateLoadingStores(false))
      dispatch(updateLoadingBrands(false))
    } catch (error) {
      dispatch(updateErrorStores(true))
      dispatch(updateLoadingStores(false))

      dispatch(updateErrorBrands(true))
      dispatch(updateLoadingBrands(false))
    }
  }, [dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData])
}
