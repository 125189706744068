export default class UserPayloadModel {
  constructor(
    private readonly email: string,
    private readonly name: string,
    private readonly lastname: string,
    private readonly password: string,
    private readonly role: string,
    private readonly organizationUUID: string | null,
    private readonly tagGroups: string[],
    private readonly permissions?: Record<string, boolean>,
    private readonly uuid?: string,
  ) {}
}
